import { createSlice, createSelector } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { IArtist, TArtistsState } from '../../types';
import { getFeaturedArtists, getFullArtistList, getFinderArtists } from './artistsActions';
import type { RootState } from '..';
import { HOME_FEATURED_CARDS } from '../../config';

export interface ArtistsState {
  artists: TArtistsState;
  categories: string[];
  featured: IArtist[];
  isLoading: boolean;
  useMockData: boolean;
  finderArtists: IArtist[] | null;
}

const initialState: ArtistsState = {
  artists: { byId: {}, allIds: [], allIdsByCategory: {} },
  categories: [],
  featured: [],
  isLoading: false,
  finderArtists: null,
  useMockData: false,
};

const artistsEntity = new schema.Entity('artist', {}, { idAttribute: 'id' });

export const artistsSlice = createSlice({
  name: 'artists',
  initialState,
  reducers: {
    setUseMockData: (state, action) => {
      state.useMockData = action.payload;
    },
    resetFinder: state => {
      state.finderArtists = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getFeaturedArtists.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getFeaturedArtists.fulfilled, (state, { payload }) => {
      const normalized = normalize<IArtist>(payload, [artistsEntity]);
      const featured = normalized?.entities?.artist || {};
      state.isLoading = false;
      state.featured = Object.values(featured).slice(0, HOME_FEATURED_CARDS) || [];
    });
    builder.addCase(getFullArtistList.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getFullArtistList.fulfilled, (state, action) => {
      state.artists.allIds = action.payload.allIds;
      state.artists.allIdsByCategory = action.payload.allIdsByCategory;
      state.artists.byId = action.payload.byId;
    });
    builder.addCase(getFinderArtists.fulfilled, (state, action) => {
      state.finderArtists = action.payload;
    });
  },
});

const artistState = (state: RootState) => state.artists;
export const artistSelector = createSelector(artistState, state => state.artists);
export const artistFinderSelector = createSelector(artistState, state => state.finderArtists);
export const featuredArtistSelector = createSelector(artistState, state => state.featured);
export const isLoadingSelector = createSelector(artistState, state => state.isLoading);
export const useMockDataSelector = createSelector(artistState, state => state.useMockData);

export const { setUseMockData, resetFinder } = artistsSlice.actions;
