import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { currentConfig } from '../../config';

export const storePaypalReceive = createAsyncThunk('/paypal/receive', async (paypalReceive: { receive: string }) => {
  const { receive } = paypalReceive;
  const vipCode = get(receive, 'vipCode');
  const body = JSON.stringify(receive);
  const response = await fetch(`${currentConfig.API_URL}/paypal/paypal/receive`, {
    headers: { vipCode },
    method: 'POST',
    body,
  });
  const result = await response.json();
  if (result.success) {
    return result.message;
  }
  throw new Error('');
});
