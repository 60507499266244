import { Link, useParams } from 'react-router-dom';

import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faVideoSlash, faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import { VonageContainer } from './VonageContainer';

export const Vonage = () => {
  const { display, roomToken } = useParams() as { display: string; roomToken: string };
  const [publishVideo, setPublishVideo] = useState(true);
  const [publishAudio, setPublishAudio] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [singleView, setSingleView] = useState(true);

  const toggleVideo = () => {
    setPublishVideo(!publishVideo);
  };

  const toggleAudio = () => {
    setPublishAudio(!publishAudio);
  };

  useEffect(() => {
    const shouldDisplaySingle = display ? display === 'c' : true;
    setSingleView(shouldDisplaySingle);
  }, [display]);

  return (
    <>
      {singleView ? (
        <div className="bg-gradient-to-r from-gray-400 to-gray-900">
          <div className="flex justify-between">
            <Link to="/">
              <h1 className="text-2xl font-bold select-none">
                PEN <span className="font-medium">PERFORMANCES</span>
              </h1>
            </Link>
          </div>
        </div>
      ) : (
        <div className="p-8 bg-gradient-to-r from-gray-400 to-gray-900">
          <div className="flex justify-between">
            <Link to="/">
              <h1 className="text-2xl font-bold select-none">
                PEN <span className="font-medium">PERFORMANCES</span>
              </h1>
            </Link>
            <div className="grid gap-5 grid-cols-2">
              <button id="videoButton" onClick={toggleVideo} type="button">
                {publishVideo ? (
                  <FontAwesomeIcon icon={faVideo} size="3x" />
                ) : (
                  <FontAwesomeIcon className="text-red-600" icon={faVideoSlash} size="3x" />
                )}
              </button>
              <button id="audioButton" onClick={toggleAudio} type="button">
                {publishAudio ? (
                  <FontAwesomeIcon icon={faMicrophone} size="3x" />
                ) : (
                  <FontAwesomeIcon className="text-red-600" icon={faMicrophoneSlash} size="3x" />
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <VonageContainer
        roomToken={roomToken}
        publishVideo={publishVideo}
        publishAudio={publishAudio}
        singleView={singleView}
      />
    </>
  );
};
