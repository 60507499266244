import { FormInput } from '../../components/Form/FormInput';
import FormSelect from '../../components/Form/FormSelect';

export default function ProfileBasicInfo() {
  return (
    <div className="flex flex-col text-white p-10">
      <h2 className="text-3xl text-center mb-4">Basic Info</h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="mt-5">
          <FormInput label="First Name" name="firstName" id="firstName" maxLength={24} required />
        </div>
        <div className="mt-5">
          <FormInput label="Last Name" name="lastName" id="lastName" maxLength={24} required />
        </div>
        <div className="mt-5">
          <FormInput label="Paypal Email" name="paypalEmail" id="paypalEmail" maxLength={244} type="email" required />
        </div>
        <div className="mt-5">
          <FormSelect
            label="Public or Private profile?"
            id="profileAccess"
            name="profileAccess"
            options={[
              { id: 'public', label: 'Public' },
              { id: 'private', label: 'Private' },
            ]}
            required
          />
        </div>
      </div>
    </div>
  );
}
