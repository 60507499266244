import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../hooks';
import { setUseMockData } from '../store/artists/artistsSlice';

export function ScrollToTop() {
  const dispatch = useAppDispatch();
  const { pathname, search, hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  useEffect(() => {
    if (search === '?useMockData=true') {
      dispatch(setUseMockData(true));
    }
    if (search === '?useMockData=false') {
      dispatch(setUseMockData(false));
    }
  }, [search, dispatch]);

  return null;
}
