import { createSlice, nanoid, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { DaysOfWeek, HoursOfDay, IProfile, TSelectedHours, TSelectedWeekdays, IAvailabilityRow } from '../../types';
import type { IRootState } from '../../types';
import { getAvailability } from './profileActions';

const initialSelectedHours = Object.values(HoursOfDay).reduce(
  (selectedHours, hour) => ({ ...selectedHours, [hour]: false }),
  {},
) as TSelectedHours;

const initialSelectedWeekdays = Object.values(DaysOfWeek).reduce(
  (selectedWeekdays, weekday) => ({ ...selectedWeekdays, [weekday]: false }),
  {},
) as TSelectedWeekdays;

const initialAvailabilityRow: IAvailabilityRow = {
  startDate: '',
  endDate: '',
  selectedHours: initialSelectedHours,
  selectedWeekDays: initialSelectedWeekdays,
  showHourPicker: false,
  showWeekdayPicker: false,
  oldStartDate: '',
  oldEndDate: '',
};

const initialState: IProfile = {
  availability: { byId: {}, allIds: [] },
};

export const profileSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addAvailabilityRow: state => {
      const newId = nanoid();
      state.availability.byId[newId] = { ...initialAvailabilityRow };
      state.availability.allIds = [...state.availability.allIds, newId];
    },
    removeAvailabilityRow: (state, { payload }) => {
      const rowId = payload;
      state.availability.allIds = state.availability.allIds.filter(id => id !== rowId);
      delete state.availability.byId[rowId];
    },
    updateAvailabilityRowById: (state, action: PayloadAction<{ id: string; update: Partial<IAvailabilityRow> }>) => {
      const { id, update } = action.payload;
      state.availability.byId[id] = { ...state.availability.byId[id], ...update };
    },
  },
  extraReducers: builder => {
    builder.addCase(getAvailability.fulfilled, (state, { payload }) => {
      const data = payload as IProfile;
      const { availability } = data;
      state.availability = availability;
    });
  },
});

const profileState = (state: IRootState) => state.profile;
export const availabilitySelector = createSelector(profileState, state => state.availability);
export const availabilityRowSelectorById = (id: string) =>
  createSelector(profileState, state => state.availability.byId?.[id]);

export const { addAvailabilityRow, removeAvailabilityRow, updateAvailabilityRowById } = profileSlice.actions;
