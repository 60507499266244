import dev from './dev';
import qa from './qa';
import prod from './prod';

export enum TEnvironment {
  PROD = 'prod',
  QA = 'qa',
  DEV = 'dev',
}

type TEnvironmentProps = typeof dev & typeof qa & typeof prod;

type IEnvConfig = {
  [env in TEnvironment]: TEnvironmentProps;
};

const config: IEnvConfig = {
  dev,
  qa,
  prod,
};

export default config;
