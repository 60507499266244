import { combineReducers } from '@reduxjs/toolkit';
import { artistsSlice } from './artists/artistsSlice';
import { userSlice } from './user/userSlice';
import { paypalSlice } from './paypal/paypalSlice';
import { profileSlice } from './profile/profileSlice';
import { vonageSlice } from './vonage/vonageSlice';

const rootReducer = combineReducers({
  artists: artistsSlice.reducer,
  user: userSlice.reducer,
  profile: profileSlice.reducer,
  paypal: paypalSlice.reducer,
  vonage: vonageSlice.reducer,
});

export default rootReducer;
