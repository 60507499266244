import FacebookLogin, { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
import { useHistory } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { useAppDispatch } from '../../../hooks';
import { currentConfig } from '../../../config';
import { IArtist, UserRoles } from '../../../types';
import { setAuthenticatedUser } from '../../../store/user/userActions';
import './facebookLogin.css';

// eslint-disable-next-line import/order
interface IFacebookLoginButton {
  label: string;
}
interface IResponse {
  accessToken: string;
  data_access_expiration_time: number;
  email: string;
  expiresIn: number;
  id: string;
  name: string;
  signedRequest: string;
  userID: string;
  picture: {
    data: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  };
}

const FacebookLoginButton = ({ label }: IFacebookLoginButton) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [hasError, setHasError] = useState<string>('');

  const responseFacebook = async (response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    const castResponse = response as IResponse;
    const { accessToken, email, name } = castResponse;

    try {
      const registerResponse = await axios.post(`${currentConfig.API_URL}/auth/register`, { accessToken });
      if (accessToken) {
        const userData = {
          isLogged: true,
          email,
          username: name,
          role: UserRoles.artist,
          isSignInProgress: false,
          artist: registerResponse.data.data as IArtist,
          accessToken: registerResponse.data.data.accessToken,
        };
        await dispatch(setAuthenticatedUser(userData));
        history.replace('/');
      }
    } catch (error) {
      const errorResponse = (error as AxiosError).response?.data.message;
      setHasError(errorResponse);
    }
  };

  return (
    <div className="hover:text-blue-600 relative button-facebook">
      <div className="label">{label}</div>
      <div className="buttonContainer">
        <FacebookLogin
          appId="131626409075667"
          scope="public_profile,email"
          fields="name,email,picture"
          version="13.0"
          callback={responseFacebook}
        />
      </div>
      {hasError !== '' && (
        <ReactModal
          isOpen
          overlayClassName="fixed inset-0 flex items-center justify-center z-30"
          className="bg-red-400 bg-opacity-90 max-w-lg p-6 text-white outline-none"
        >
          <div className="relative">
            <button
              className="absolute -top-6 -right-4 text-xl font-thin hover:bg-black hover:bg-opacity-10 w-6"
              type="button"
              onClick={() => setHasError('')}
            >
              X
            </button>
            <div className="mx-4 my-2 text-center">{hasError}</div>
          </div>
        </ReactModal>
      )}
    </div>
  );
};

export { FacebookLoginButton };
