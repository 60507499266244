import { CardGrid } from '../../components/CardGrid';
import { HOME_FEATURED_CARDS } from '../../config';
import { useAppSelector } from '../../hooks';
import { isLoadingSelector, featuredArtistSelector } from '../../store/artists/artistsSlice';
import { ArtistCardType } from '../../types';

export default function HomeFeatured() {
  const featuredArtists = useAppSelector(featuredArtistSelector);
  const isLoading = useAppSelector(isLoadingSelector);

  if (isLoading) {
    // TODO: replace for an spinner like component or make an skeleton to show loading state
    return <div>Content is loading ...</div>;
  }

  return (
    <div className="mt-10">
      <p className="pl-5 font-medium text-3xl text-default text-shadow-default">FEATURED ARTISTS</p>
      <CardGrid
        columns={HOME_FEATURED_CARDS}
        displayFooter={false}
        artists={featuredArtists}
        cardType={ArtistCardType.featured}
        cardColorCategoryId="musicians"
      />
    </div>
  );
}
