import { ListItem } from './ListItem';
import { ICategory } from '../types';

interface IList {
  list: ICategory[];
  id: string;
  onChange(idList: string, idItem: string, checked: boolean): void;
}

const List = ({ list, id, onChange }: IList) => {
  const changeHandler = (idItem: string, checked: boolean) => {
    onChange(id, idItem, checked);
  };

  const items = list.map(item => {
    return (
      <div key={item.id} className="pl-36">
        <ListItem id={item.id} value={item.id} label={item.id} onChange={changeHandler} />
      </div>
    );
  });

  return (
    <div className="flex flex-col">
      <div className="text-white text-2xl mb-4">What type of artist are you looking for?</div>
      <div>{items}</div>
    </div>
  );
};

export { List };
