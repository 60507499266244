export const getHeadshot = (number: number) => `/assets/headshots/Headshot ${number}.png`;

// DATES

export const DATE_TIMES = {
  ZERO: new Date('1995-01-01T00:00:00'),
  ONE: new Date('1995-01-01T01:00:00'),
  TWO: new Date('1995-01-01T02:00:00'),
  THREE: new Date('1995-01-01T03:00:00'),
  FOUR: new Date('1995-01-01T04:00:00'),
  FIVE: new Date('1995-01-01T05:00:00'),
  SIX: new Date('1995-01-01T06:00:00'),
  SEVEN: new Date('1995-01-01T07:00:00'),
  EIGHT: new Date('1995-01-01T08:00:00'),
  NINE: new Date('1995-01-01T09:00:00'),
  TEN: new Date('1995-01-01T10:00:00'),
  ELEVEN: new Date('1995-01-01T11:00:00'),
  TWELVE: new Date('1995-01-01T12:00:00'),
  THIRTEEN: new Date('1995-01-01T13:00:00'),
  FOURTEEN: new Date('1995-01-01T14:00:00'),
  FIFTEEN: new Date('1995-01-01T15:00:00'),
  SIXTEEN: new Date('1995-01-01T16:00:00'),
  SEVENTEEN: new Date('1995-01-01T17:00:00'),
  EIGHTEEN: new Date('1995-01-01T18:00:00'),
  NINETEEN: new Date('1995-01-01T19:00:00'),
  TWENTY: new Date('1995-01-01T20:00:00'),
  TWENTY_ONE: new Date('1995-01-01T21:00:00'),
  TWENTY_TWO: new Date('1995-01-01T22:00:00'),
  TWENTY_THREE: new Date('1995-01-01T23:00:00'),
};

const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};

const processDates = (startDate: Date, stopDate: Date) => {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};

export const getDates = (startDate: Date, stopDate: Date) => {
  if (!startDate || !stopDate) {
    return [];
  }
  return processDates(startDate, stopDate);
};

export const nextMonth = (date?: Date) => {
  if (date === undefined) {
    // eslint-disable-next-line no-param-reassign
    date = new Date();
  }
  if (date.getMonth() === 11) {
    return new Date(date.getFullYear() + 1, 0, date.getDate());
  }
  return new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
};
