import { ArtistCardType, IArtist } from '../../types';
import { ArtistCard } from '../../components/ArtistCard';

interface IArtistRoosterCategory {
  category: {
    categoryId: string;
    categoryName: string;
    tags: string[];
    artists: IArtist[];
  };
}

export function RosterCategorySection({ category }: IArtistRoosterCategory) {
  return (
    <div id={category.categoryId} className="py-9">
      <h1
        className={`inline-flex items-baseline leading-none text-${
          category.categoryName.toLowerCase() ?? 'default'
        } w-full`}
      >
        <span className="uppercase font-bold text-3xl">{category.categoryName}</span>
        <div className="px-2 leading-none uppercase whitespace-nowrap w-full overflow-ellipsis overflow-hidden">
          {category.tags.map(tag => (
            <span key={tag} className="px-2">
              {tag}
            </span>
          ))}
        </div>
      </h1>
      <div className="grid grid-cols-5 gap-y-5">
        {category.artists.map(artist => (
          <ArtistCard key={artist.id} artist={artist} displayFooter type={ArtistCardType.roster} />
        ))}
      </div>
    </div>
  );
}
