import { useState } from 'react';
import ModalPicker from '../../../components/ModalPicker';
import { TSelectedWeekdays, DaysOfWeek, PickerProps } from '../../../types';

export default function WeekDaysPicker({ isOpen, initialState, handleClose }: PickerProps<TSelectedWeekdays>) {
  const [selectedWeekDays, setSelectedWeekdays] = useState(initialState);
  const handleSelectWeekday = (weekday: keyof TSelectedWeekdays) => {
    setSelectedWeekdays(prev => ({ ...prev, [weekday]: !prev?.[weekday] }));
  };
  const handleCloseWithCallbackData = () => {
    handleClose(selectedWeekDays);
  };

  return (
    <ModalPicker isOpen={isOpen} handleClose={handleCloseWithCallbackData}>
      <div className="grid grid-rows-7 grid-flow-row gap-4">
        {Object.values(DaysOfWeek).map(weekday => (
          <button
            key={weekday}
            type="button"
            className={`w-32 outline-none border ${
              selectedWeekDays?.[weekday] ? 'border-white' : 'border-transparent'
            }`}
            onClick={() => handleSelectWeekday(weekday)}
          >
            {weekday}
          </button>
        ))}
      </div>
    </ModalPicker>
  );
}
