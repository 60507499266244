/* eslint-disable react/jsx-props-no-spreading */
import { useFormContext } from 'react-hook-form';

type OptionType = { id: string; label: string };
interface FormSelectProps extends React.HTMLProps<HTMLSelectElement> {
  label: string;
  name: string;
  id: string;
  options: OptionType[] | null;
}

export default function FormSelect({ label, name, id, options, ...rest }: FormSelectProps) {
  const { register } = useFormContext();

  const field = register(name, { required: rest?.required });

  return (
    <>
      <label htmlFor={id}>{label}</label>
      <div className="text-black mt-3">
        <select
          id={id}
          className="w-36 text-black border-gray-300 bg-white rounded w-full"
          {...rest}
          {...field}
          onChange={e => {
            field.onChange(e);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            rest?.onChange && rest?.onChange(e);
          }}
        >
          {options?.map((option: OptionType) => {
            const optionId: string = option?.id;
            return (
              <option key={optionId} value={optionId}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
}
