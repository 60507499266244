import { createSlice, createSelector } from '@reduxjs/toolkit';
import { IArtist, UserRoles } from '../../types';
import type { RootState } from '..';
import {
  userArtistFacebookSignIn,
  userGetCurrentAuthenticatedUser,
  userSignout,
  setVipCode,
  setAuthenticatedUser,
} from './userActions';

export interface IUser {
  isLogged: boolean;
  email: string | undefined;
  username: string | undefined;
  isSignInProgress: boolean;
  role: UserRoles | undefined;
  artist?: IArtist | undefined;
  vipCode: string;
  vipCodeStatus: number;
  vipCodeLoading: boolean;
  vipIsValidCode: boolean;
  vipIsInvalidCode: boolean;
  accessToken: string | null;
}

const initialState: IUser = {
  isLogged: false,
  email: '',
  username: '',
  isSignInProgress: false,
  role: undefined,
  vipCode: '',
  vipCodeStatus: 0,
  vipCodeLoading: false,
  vipIsValidCode: false,
  vipIsInvalidCode: false,
  accessToken: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(userArtistFacebookSignIn.pending, state => {
      state.isSignInProgress = true;
    });
    builder.addCase(userArtistFacebookSignIn.rejected, state => {
      state.isLogged = false;
      state.isSignInProgress = false;
    });
    builder.addCase(userArtistFacebookSignIn.fulfilled, (state, { payload }) => {
      if (payload) {
        state.isSignInProgress = true;
        state.role = UserRoles.artist;
      }
    });
    builder.addCase(userGetCurrentAuthenticatedUser.fulfilled, (state, { payload }) => {
      if (payload) {
        state.isLogged = true;
        state.isSignInProgress = false;
        state.email = payload.attributes.email;
        state.username = payload.username;
        state.role = payload.attributes['custom:role'] as UserRoles;
      }
    });
    builder.addCase(userGetCurrentAuthenticatedUser.rejected, () => {
      return initialState;
    });
    builder.addCase(userSignout.pending, state => {
      state.isSignInProgress = true;
    });
    builder.addCase(userSignout.fulfilled, (state, payload) => {
      if (payload) {
        return initialState;
      }
      return state;
    });
    builder.addCase(setAuthenticatedUser.fulfilled, (state, { payload }) => {
      if (payload) {
        state.isLogged = payload.isLogged;
        state.email = payload.email;
        state.username = payload.username;
        state.isSignInProgress = payload.isSignInProgress;
        state.role = payload.role;
        state.artist = payload.artist;
        state.accessToken = payload.accessToken;
      }
    });
    builder.addCase(setVipCode.pending, state => {
      state.vipCodeLoading = true;
      state.vipIsValidCode = false;
      state.vipIsInvalidCode = false;
    });
    builder.addCase(setVipCode.fulfilled, (state, { payload }) => {
      const status = payload.vipCodeStatus;
      if (status === 200) {
        state.vipCode = payload.vipCode;
        state.vipCodeStatus = payload.vipCodeStatus;
        state.vipCodeLoading = false;
        state.vipIsValidCode = true;
        state.vipIsInvalidCode = false;
      } else {
        state.vipCode = '';
        state.vipCodeStatus = payload.vipCodeStatus;
        state.vipCodeLoading = false;
        state.vipIsValidCode = false;
        state.vipIsInvalidCode = true;
      }
    });
  },
});

const userState = (state: RootState) => state.user;
export const userSelector = createSelector(userState, state => state);
export const userRoleSelector = createSelector(userState, state => state.role);
export const userLoginSelector = createSelector(userState, state => state.isLogged);
export const userVipSelector = createSelector(userState, state => state.vipCode);
