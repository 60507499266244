import { createAsyncThunk } from '@reduxjs/toolkit';
import { currentConfig } from '../../config';

export const createRoomToken = createAsyncThunk('/room-token', async () => {
  const response = await fetch(`${currentConfig.API_URL}/room-token`, {
    method: 'GET',
  });
  const result = await response.json();
  if (result.success) {
    return result;
  }
  throw new Error(result.message);
});

export const createToken = createAsyncThunk('/token', async (roomToken: string) => {
  const response = await fetch(`${currentConfig.API_URL}/token?roomToken=${roomToken}`, {
    method: 'GET',
  });
  const result = await response.json();
  if (result.success) {
    return result;
  }
  throw new Error(result.message);
});
