import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import { currentConfig } from '../../config';
import data from '../../mock/data';
import type { RootState } from '..';
import { IArtist, TArtistsState } from '../../types';

interface IGetFeaturedArtistResponse {
  data: IArtist[];
}

interface IGetFullArtistResponse {
  data: { [category: string]: IArtist[] };
}

interface IGetFinderArtists {
  data: IArtist;
}

const artistsEntity = new schema.Entity('artist');
const artistByCategorySchema = new schema.Values([artistsEntity]);

export const getFeaturedArtists = createAsyncThunk('artists/fetchFeatured', async (_, { getState }) => {
  try {
    const {
      artists: { useMockData = false },
      user: { vipCode },
    } = getState() as RootState;
    if (useMockData) {
      return data.getFeaturedArtists();
    }
    const response = await fetch(`${currentConfig.API_URL}/performers/artist/featured`, {
      headers: { vipCode },
    });
    const responseData = (await response.json()) as IGetFeaturedArtistResponse;
    return responseData?.data || [];
  } catch (error) {
    return error;
  }
});

export const getFullArtistList = createAsyncThunk<TArtistsState>(
  'artists/getFullArtistList',
  async (_, { getState }) => {
    try {
      const {
        artists: { useMockData = false },
        user: { vipCode },
      } = getState() as RootState;
      if (useMockData) {
        const normalizedData = normalize(data.artistsByCategory, artistByCategorySchema);

        return {
          byId: normalizedData.entities.artist,
          allIds: Object.keys(normalizedData.entities.artist || {}),
          allIdsByCategory: normalizedData.result,
        };
      }
      const response = await fetch(`${currentConfig.API_URL}/performers/artist/all`, {
        headers: { vipCode },
      });
      const responseData = (await response.json()) as IGetFullArtistResponse;
      let sortedData = responseData.data;
      if (vipCode) {
        sortedData = Object.keys(responseData.data)?.reduce((acc, category: string) => {
          if (!category || category === 'undefined') return acc;
          const artistByCategorySorted = responseData.data[category].sort(a => (a.privateProfile ? -1 : 1));
          return { ...acc, [category]: artistByCategorySorted };
        }, {});
      }

      const normalizedData = normalize(sortedData, artistByCategorySchema);

      return {
        byId: normalizedData.entities.artist,
        allIds: Object.keys(normalizedData.entities.artist || {}),
        allIdsByCategory: normalizedData.result,
      };
    } catch (error) {
      return error;
    }
  },
);

export const getFinderArtists = createAsyncThunk('artists/getFinderArtists', async (filter: string) => {
  try {
    const response = await fetch(`${currentConfig.API_URL}/performers/artist${filter}`);
    const responseData = (await response.json()) as IGetFinderArtists;
    return responseData?.data || [];
  } catch (error) {
    return error;
  }
});
