import moment, { Moment } from 'moment';
import { useState } from 'react';
import { TimeSelect } from './TimeSelect';

const separateDate = (dates: string[]) => {
  return dates.map(date => date.split('T')[0]);
};

const getAvailableDatesObj = (dates: string[]) => {
  const datesWithoutTimes = separateDate(dates);
  const parsedDates: { [key: string]: boolean } = {};
  datesWithoutTimes.forEach(date => {
    parsedDates[date] = true;
  });
  return parsedDates;
};

const DayContent = ({
  date,
  className,
  availableDatesObj,
  onChange,
  selectedDate,
}: {
  date: Moment;
  className: string;
  availableDatesObj: string[];
  onChange(date: string): void;
  selectedDate: string;
}): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const [position, setPosition] = useState<DOMRect>({} as DOMRect);
  const [hours, setHours] = useState<Moment[]>([]);
  const [selectedDay, setSelectedDay] = useState<string>('');
  const availableDatesObjParsed = getAvailableDatesObj(availableDatesObj);

  const dateKey = date.format('YYYY-MM-DD');
  const hasAvailableTimes = !!availableDatesObjParsed[dateKey];

  const getAvailableHours = (day: string) => {
    setSelectedDay(day);
    const dateString = date.format('YYYY-MM-DD');
    const hoursFromSelectedDate: Moment[] = [];
    availableDatesObj.forEach(dateObj => {
      const dateWithoutHours = dateObj.split('T')[0];
      if (dateWithoutHours === dateString) {
        const momentDate = moment(dateObj);
        hoursFromSelectedDate.push(momentDate);
      }
    });
    setHours(hoursFromSelectedDate);
  };

  const changeHandler = (hour: string) => {
    const clickedDate = availableDatesObj.find(dateFromArray => {
      const momentDate = moment(dateFromArray);
      return momentDate.date().toString() === selectedDay && momentDate.format('hh:mm A') === hour;
    });
    onChange(clickedDate || '');
  };

  return (
    <div className="relative">
      <button
        type="button"
        className={`w-full h-full text-xl p-5 ${hasAvailableTimes && className}`}
        onClick={event => {
          setPosition(event.currentTarget.getBoundingClientRect());
          setIsActive(prev => !prev);
          const button = event.target as HTMLButtonElement;
          const buttonDate = button.innerHTML;
          getAvailableHours(buttonDate);
        }}
      >
        {date?.date()}
      </button>
      {hasAvailableTimes && (
        <TimeSelect
          onChange={changeHandler}
          times={hours}
          isActive={isActive}
          handleClose={() => setIsActive(false)}
          position={position}
          selectedDate={selectedDate}
        />
      )}
    </div>
  );
};

export { DayContent };
