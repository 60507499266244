import ProfileInfo from './Profile';
import { PageHeader } from '../../components';

export default function Profile() {
  return (
    <div className="h-full min-h-screen min-w-md text-white px-0 sm:px-5">
      <div id="container" className="container mx-auto max-w-screen-xl text-xl">
        <PageHeader white color="text-white" />
        <ProfileInfo />
      </div>
    </div>
  );
}
