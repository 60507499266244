import { Transition } from '@headlessui/react';
import { HashLink } from 'react-router-hash-link';
import { categories } from '../../config';

interface HomeBannerCardProps {
  category: typeof categories[0];
  className: string;
}

function HomeBannerCard({ category, className }: HomeBannerCardProps) {
  const { imageSrc, displayName } = category;
  return (
    <HashLink smooth key={category.id} to={`/roster#${category.id}`} className={className}>
      <div className="relative aspect-w-9 aspect-h-16">
        <div className="bg-top bg-cover" style={{ backgroundImage: `url("${imageSrc}")` }} />
        <div className="flex justify-center items-center">
          <div className="w-full text-2xl text-center uppercase font-medium text-white filter drop-shadow">
            {displayName}
          </div>
        </div>
      </div>
    </HashLink>
  );
}

export default function HomeCategoryBanner() {
  const bannerCategoriesIds = ['musicians', 'actors', 'comedians', 'magicians'];
  const transitionDelay = [300, 700, 1100, 1500];
  return (
    <div className="flex justify-center overflow-hidden py-5">
      <div className="flex flex-1">
        {categories
          .filter(c => bannerCategoriesIds.includes(c.id))
          .map((category, index) => (
            <Transition
              key={category.id}
              className="flex-1"
              appear
              show
              enter={`transition-opacity delay-${transitionDelay[index]} duration-1000`}
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <HomeBannerCard category={category} className="flex-1" />
            </Transition>
          ))}
      </div>
    </div>
  );
}
