import React from 'react';
import { Link } from 'react-router-dom';
import { ArtistCardType, IArtist } from '../types';

const CARD_SIZE_BY_TYPE: { [key in ArtistCardType]: React.CSSProperties } = {
  [ArtistCardType.featured]: { width: 175, height: 300 },
  [ArtistCardType.roster]: { width: 175, height: 300 },
  [ArtistCardType.otherSingers]: { width: 156, height: 240 },
};

interface IArtistCard {
  artist: Partial<IArtist>;
  displayFooter?: boolean;
  displayInfo?: boolean;
  type: ArtistCardType;
  colorCategoryId?: string;
}

export function ArtistCard({ artist, displayFooter = false, displayInfo = false, type, colorCategoryId }: IArtistCard) {
  const { categories, shortTitle, profileImageSrc, firstName, lastName, headline, privateProfile } = artist;
  const visibleInfo = displayInfo ? 'visible' : 'invisible';

  const cardSize = CARD_SIZE_BY_TYPE[type];

  const bgColor = colorCategoryId ? `bg-${colorCategoryId}` : `bg-${artist?.categoryId || 'default'}`;
  const textColor = colorCategoryId ? `text-${colorCategoryId}` : `text-${artist.categoryId || 'default'}`;

  return (
    <div className="hover:z-10" style={cardSize}>
      <div className="transform group hover:scale-115 relative overflow-hidden">
        {privateProfile && (
          <div className="absolute text-white bg-red-700 w-24 h-10 text-center leading-10 pt-1 transform -rotate-45 -top-1 -left-8">
            - VIP -
          </div>
        )}
        <div className={`bg-gray-700 w-full absolute inset-0 z-10 opacity-50 ${visibleInfo} group-hover:visible`} />
        <img alt={artist.firstName} src={profileImageSrc} className="object-cover" style={cardSize} />
        <div
          className={`${
            type === ArtistCardType.otherSingers ? 'h-full' : 'h-5/6'
          } absolute bottom-0 px-2 text-white  text-center w-full z-20 ${visibleInfo} group-hover:visible select-none`}
        >
          <div className={`${type === ArtistCardType.otherSingers && 'mt-4'}`}>
            <h1 className="font-bold">
              {`${firstName}`} <br /> {`${lastName}`}
            </h1>
            <div className="font-medium text-xs tracking-wide">
              <h3 className="mt-1">{shortTitle}</h3>
              {headline && <h3 className="mt-1 font-semibold">{headline}</h3>}
              <div className="mx-auto px-4 container text-left">
                <ul className="custom-dots mt-2 whitespace-nowrap">
                  {categories?.map(category => (
                    <li key={category}>{category}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={`absolute h-5 font-medium text-xs mb-10 inset-x-8 bottom-0 ${bgColor} p-0.5`}>
            <Link to={`/artist/${artist.urlFriendlyId}`}>Find out more</Link>
          </div>

          <div
            className={`absolute text-base inset-x-6 ${
              type !== ArtistCardType.otherSingers ? '-bottom-1' : '-bottom-0'
            } bg-white ${textColor} font-bold uppercase pt-1`}
          >
            <Link to={`/artist/${artist.urlFriendlyId}#book-now`}>Book Now</Link>
          </div>
        </div>
      </div>
      {displayFooter && <div className="bg-gray-200 h-2 w-full" />}
    </div>
  );
}
