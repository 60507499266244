/* eslint-disable react/jsx-props-no-spreading */
import { Control, useFormContext, useWatch } from 'react-hook-form';

interface RemainingCharactersProps {
  control: Control;
  name: string;
  maxLength?: number;
}

function RemainingCharacters({ control, name, maxLength }: RemainingCharactersProps) {
  const characterField = useWatch({
    control,
    name,
    defaultValue: '',
  });

  if (!maxLength) return null;

  const remainingChars = maxLength - characterField?.length;
  return <div className="w-full text-sm text-right">Remaining characters: {remainingChars}</div>; // only re-render at the component level, when firstName changes
}

interface FormTextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  label: string;
  name: string;
  id: string;
}

export function FormTextArea({ label, name, id, ...rest }: FormTextAreaProps) {
  const { control, register } = useFormContext();
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <div className="mt-3">
        <textarea
          id={id}
          className="text-black w-96 px-3 py-2 border rounded w-full focus:outline-none "
          {...rest}
          {...register(name, {
            required: rest?.required,
            maxLength: rest?.maxLength,
          })}
        />
        <RemainingCharacters maxLength={rest?.maxLength} name={name} control={control} />
      </div>
    </>
  );
}
