/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { Calendar } from './calendarDatePicker/Calendar';
import { PayPalButton } from './PaypalButton';

interface IModal {
  title?: string;
  subTitle?: string;
  showModal: boolean;
  setShowModal: (flag: boolean) => void;
}

export const Modal = ({ title = 'Guest Signout', subTitle = 'reserving your', showModal, setShowModal }: IModal) => {
  const [formStep, setFromStep] = useState(1);
  const [paidFor, setPaidFor] = useState(false);

  const now = new Date(new Date().setHours(new Date().getHours(), 0));
  const [startDate, setStartDate] = useState(now);

  const nextStep = () => {
    setFromStep(formStep + 1);
  };

  const closeModal = () => {
    setShowModal(false);
    setFromStep(1);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none select-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl max-h-xl">
              {/* content */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-musicians opacity-90 outline-none focus:outline-none">
                {/* header */}
                <div className="pt-2 px-5 text-white text-right" onClick={() => closeModal()}>
                  {' '}
                  &#10005;{' '}
                </div>
                <div className="p-5 rounded-t text-center">
                  <h3 className="text-2xl font-medium text-white uppercase"> ー {title} ー </h3>
                  <h4 className="text-xl mt-4 font-medium text-white"> {subTitle} </h4>
                  <h1 className="text-3xl  mt-4  font-medium text-white">
                    {' '}
                    <strong>PEN</strong> PERFORMANCE
                  </h1>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto min-w-sm text-white">
                  <div className="w-full max-w-xl mx-auto opacity-50">
                    <form className="mt-10">
                      <div className="mb-4 grid grid-cols-2 gap-5">
                        {formStep === 1 && (
                          <>
                            <h1 className="col-span-2 font-extrabold text-xl"> Personal Information </h1>
                            <div>
                              <label className="block font-bold"> First Name </label>
                              <input
                                className="bg-transparent shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="first name"
                              />
                            </div>
                            <div>
                              <label className="block font-bold"> Last Name </label>
                              <input
                                className="bg-transparent shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="last name"
                              />
                            </div>
                          </>
                        )}
                        {formStep === 2 && (
                          <>
                            <h1 className="col-span-2 font-extrabold text-xl"> Booking Information </h1>
                            <div className="col-span-2 mb-5">
                              <label className="block font-bold"> Select Date </label>
                              <Calendar date={startDate} setDate={setStartDate} showHours label="" />
                            </div>
                            <div className="col-span-2">
                              <label className="block font-bold"> Additional Details </label>
                              <textarea
                                className="bg-transparent shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Special requests..."
                              />
                            </div>
                          </>
                        )}
                        {formStep === 3 && (
                          <>
                            <h1 className="col-span-2 font-extrabold text-xl"> Terms and Conditions </h1>
                            <div className="col-span-2">
                              <p className="text-center">
                                <input className="bg-blue-600" type="checkbox" /> Policy acceptance: Lorem ipsum dolor
                                sit amet, consectetur adipiscing elit. Ut sagittis non arcu vel maximus. Pellentesque id
                                tortor urna. Nunc interdum mauris lorem, nec semper lorem pretium vitae.
                              </p>
                            </div>
                          </>
                        )}
                        {formStep === 4 && (
                          <>
                            <h1 className="col-span-2 font-extrabold text-xl"> Billing </h1>
                            <div className="col-span-2">
                              <label className="block font-bold"> Payment Information</label>
                              <PayPalButton paidFor={paidFor} setPaidFor={setPaidFor} />
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                {/* footer */}
                <div className="flex items-center justify-between p-6 min-w-md">
                  <button
                    className="text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border border-color-white disabled:opacity-25"
                    type="button"
                    disabled={formStep === 4 && paidFor}
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>
                  {formStep < 4 && (
                    <button
                      className="text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border border-color-white disabled:opacity-25"
                      type="button"
                      onClick={() => nextStep()}
                    >
                      Next
                    </button>
                  )}
                  {formStep === 4 && (
                    <button
                      className="text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border border-color-white disabled:opacity-25"
                      type="button"
                      disabled={!paidFor}
                      onClick={() => closeModal()}
                    >
                      Complete
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
    </>
  );
};
