/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { currentConfig } from '../../config';
import type { RootState } from '..';

export const getAvailability = createAsyncThunk('profile/availability', async (_, { getState }) => {
  try {
    const {
      user: { accessToken, artist },
    } = getState() as RootState;
    const response = await fetch(`${currentConfig.API_URL}/performers/artist/availability`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken || artist?.token}`,
      },
    });
    const { data } = await response.json();
    if (data.cardsAvailability !== '') {
      const cardsObject = JSON.parse(data.cardsAvailability);
      const { allIds, byId } = cardsObject;
      const availability = { allIds, byId };
      return { availability };
    }
    return { availability: { byId: {}, allIds: [] } };
  } catch (error) {
    console.log(error);
    return error;
  }
});
