import configEnv, { TEnvironment } from './env';

const getCurrentEnv = () => {
  const { location } = window;
  const hostname = location.hostname.toLowerCase();

  if (hostname === 'localhost') return TEnvironment.DEV;
  if (hostname.startsWith('dev')) return TEnvironment.DEV;
  if (hostname.startsWith('qa')) return TEnvironment.QA;
  if (hostname.startsWith('prod')) return TEnvironment.PROD;
  if (hostname.startsWith('www')) return TEnvironment.PROD;
  if (hostname.startsWith('penperformances')) return TEnvironment.PROD;

  return TEnvironment.DEV;
};
const currentEnv = getCurrentEnv();

export const currentConfig = configEnv[currentEnv];

export { categories } from './categories';

export const HOME_FEATURED_CARDS = 6;

export const footerSocialUrls = {
  twitter: 'https://twitter.com/PenPerformances',
  facebook: 'https://www.facebook.com/PENPerformances',
  instagram: 'https://www.instagram.com/penperformances/',
};
