import { ReactComponent as OpenSquareSVG } from './open-square.svg';
import { ReactComponent as CloseSquareSVG } from './close-square.svg';

interface SquareButtonProps {
  title?: string;
}

export default function SquareButton({ title = 'Book Now' }: SquareButtonProps) {
  return (
    <div className="uppercase relative w-full flex flex-row justify-center items-center text-white">
      <OpenSquareSVG width={40} className="absolute -top-3 -left-7 fill-current" />
      <div className="text-3xl font-medium">{title}</div>
      <CloseSquareSVG width={40} className="absolute -bottom-3  -right-7 fill-current" />
    </div>
  );
}
