import moment, { Moment } from 'moment';
import ReactModal from 'react-modal';

const TimeSelect = ({
  isActive,
  handleClose,
  position,
  times,
  onChange,
  selectedDate,
}: {
  isActive: boolean;
  position: DOMRect;
  handleClose(): void;
  times: Moment[];
  onChange(hour: string): void;
  selectedDate: string;
}) => {
  if (!isActive) return null;
  const styles: React.CSSProperties = {
    position: 'absolute',
    top: position.top + position.height - 10,
    left: position.left - 15,
  };

  const momentSelectedDate = moment(selectedDate);

  const clickHandler = (hour: string) => {
    onChange(hour);
    handleClose();
  };

  const getHours = () => {
    return times.map(momentDate => {
      const hour = moment.tz(momentDate, moment.tz.guess()).format('hh:mm A');
      const background = momentSelectedDate.isSame(momentDate) ? 'bg-gray-300' : 'bg-transparent';
      return (
        <button
          onClick={() => clickHandler(hour)}
          type="button"
          key={hour}
          className={`uppercase whitespace-nowrap my-1 text-sm w-full hover:text-blue-300 ${background}`}
        >
          {hour}
        </button>
      );
    });
  };
  return (
    <ReactModal
      overlayClassName="fixed inset-0 flex items-center justify-center z-30"
      className="bg-opacity-90 max-w-lg text-white outline-none"
      isOpen={isActive}
      onRequestClose={handleClose}
    >
      <div className="w-28 bg-white text-black px-1 py-2" style={styles}>
        <div className="text-sm whitespace-nowrap font-medium text-center">SELECT A TIME</div>
        <div className="flex flex-col justify-center items-center">{getHours()}</div>
      </div>
    </ReactModal>
  );
};

export { TimeSelect };
