import { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { PageHeader } from '../../components';
import { CardGrid } from '../../components/CardGrid';

import { Modal } from '../../components/Modal';
import { currentConfig } from '../../config';
import { ArtistCardType, IArtist, IProduct } from '../../types';
import HowPenWorks from '../../components/buttons/HowPenWorks/HowPenWorks';
import ReturnToRoster from '../../components/buttons/ReturnRoster/ReturnToRoster';
import { Footer } from '../../components/Footer';
import ModalCalendar from '../../components/Calendar/Calendar';
import SquareButton from '../../components/buttons/SquareButton/SquareButton';
import { PenLoader } from '../../components/PenLoader';
import { useAppSelector } from '../../hooks';
import { userVipSelector } from '../../store/user/userSlice';

const otherSingersWidth = 156 * 5;

export default function ArtistScreen() {
  const { hash } = useLocation();
  const { artistId } = useParams() as { artistId: string };
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [artist, setArtist] = useState({} as IArtist);
  const [similar, setSimilar] = useState([] as IArtist[]);
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState({ price: 1, description: '', duration: '', img: '' } as IProduct);
  const [availability, setAvailability] = useState<[{ date: string; booked: boolean }] | []>([]);
  const [isDisabledArtist, setIsDisabledArtist] = useState<string>('');

  const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);
  const vipCode = useAppSelector(userVipSelector);

  const fetchAvailability = async () => {
    const response = await fetch(
      `${currentConfig.API_URL}/performers/artist/availabilityByMonth?artistId=${artist.id}`,
    );
    const artistAvailability = await response.json();
    const localTime = artistAvailability.data.map((entry: { date: string; booked: boolean }) => {
      return { date: `${moment(entry.date).local(true).format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`, booked: entry.booked };
    });
    setAvailability(localTime);
  };

  useEffect(() => {
    const getAvailability = async () => {
      const response = await fetch(
        `${currentConfig.API_URL}/performers/artist/availabilityByMonth?artistId=${artist.id}`,
      );
      const artistAvailability = await response.json();
      const localTime = artistAvailability.data.map((entry: { date: string; booked: boolean }) => {
        return { date: `${moment(entry.date).local(true).format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`, booked: entry.booked };
      });
      setAvailability(localTime);
    };
    getAvailability();
  }, [artist]);

  useEffect(() => {
    setIsLoading(true);
    if (hash === '#book-now') {
      setShowCalendar(true);
      const { origin, pathname } = window.location;
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({}, '', `${origin}${pathname}`);
    }
  }, [hash, artistId]);

  useEffect(() => {
    const fetchArtist = async () => {
      const response = await fetch(`${currentConfig.API_URL}/performers/artist/detail/${artistId}`, {
        headers: { vipCode },
      });
      const responseData = await response.json();
      const artistData = responseData.data.artist;
      if (artistData) {
        const { performancePrice, productDescription, performanceDuration, profileImageSrc } = responseData.data.artist;
        setArtist(responseData.data.artist);
        setSimilar(responseData.data.similar);
        setIsLoading(false);
        setProduct({
          price: performancePrice,
          description: productDescription,
          duration: performanceDuration,
          img: profileImageSrc,
        });
      }
      if (response.status === 500) {
        setIsDisabledArtist(responseData.message);
      }
      setIsLoading(false);
    };
    fetchArtist();
  }, [artistId, vipCode]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!artist.categoryId) return () => null;
    document.body.classList.add(`bg-${artist?.categoryId}`);
    return () => {
      document.body.classList.remove(`bg-${artist?.categoryId}`);
    };
  }, [artist.categoryId]);

  if (!artist.categoryId || isLoading) {
    const text =
      isDisabledArtist ||
      `Looking for ${artistId
        .split('-')
        .map(value => capitalize(value))
        .join(' ')}'s information...`;
    return <PenLoader loadingText={text} />;
  }

  const modalChangeHandler = (date: string) => {
    setSelectedDate(date);
  };

  const getAvailableDates = () => {
    const dates = availability.map(item => item.date.slice(0, -5));
    return dates;
  };

  const handleCalendarClosing = () => {
    fetchAvailability();
    setShowCalendar(false);
  };

  return (
    <>
      <ModalCalendar
        isOpen={showCalendar}
        artistId={artist.id}
        artistName={`${capitalize(artist.firstName)} ${capitalize(artist.lastName)}`}
        artistEmail={artist.email}
        artistTimezone={artist.timezone}
        handleClose={() => handleCalendarClosing()}
        categoryId={artist.categoryId}
        product={product}
        availableDates={getAvailableDates()}
        onChange={modalChangeHandler}
        selectedDate={selectedDate}
      />
      <div className="container max-w-lg min-w-lg mx-auto">
        <div id="container" className="container mx-auto max-w-screen-xl">
          <PageHeader color="text-white" white />
          <div id="header-with-video" className="pt-5 pb-8">
            <h1 className="text-3vw xl:text-4xl xl:mb-1 text-white my-0 uppercase whitespace-nowrap overflow-hidden overflow-ellipsis">
              <span className="font-bold">{`${artist.firstName} ${artist.lastName} `}</span>
              <span className="uppercase font-thin mx-2 mb-3 xl:text-2xl">{artist?.categories?.join(' | ')}</span>
            </h1>
            <div className="flex">
              <div className="flex-1 bg-top bg-cover" style={{ backgroundImage: `url("${artist.profileImageSrc}")` }} />
              <div className="w-9/12">
                <video
                  width="100%"
                  height="100%"
                  src={artist.profileVideoUrl}
                  autoPlay
                  controls
                  muted
                  loop
                  playsInline
                />
              </div>
            </div>
            <div className="relative">
              <div className="absolute right-0 mt-10">
                <HowPenWorks width="110px" type="white" overlayClassColor={`bg-overlay-${artist.categoryId}`} />
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-lg text-white text-center text-base my-10">
            <h2
              id="title"
              className="uppercase text-lg sm:text-2vw xl:text-2xl leading-tight text-center text-white mt-4"
            >
              <span className="italic">{artist.headline}</span>
            </h2>
            <p className=" my-5">{artist.artistBio}</p>
            <p>
              <span className="font-semibold">SHOW DESCRIPTION</span>: {product.description} The performance lasts{' '}
              <span className="font-semibold">{product.duration}</span> and it&apos;s priced at{' '}
              <span className="font-semibold">${product.price}</span>
            </p>
          </div>
          <div className="flex items-center justify-center">
            <button
              type="button"
              onClick={() => {
                setShowCalendar(true);
                return null;
              }}
            >
              <SquareButton title={`BOOK ${artist.firstName}`} />
            </button>
          </div>
          <Modal showModal={showModal} setShowModal={setShowModal} />
          <div className="mt-10 mb-5 mx-auto" style={{ width: otherSingersWidth }}>
            <h2 className="text-white text-lg font-medium">OTHER {artist.categoryId.toUpperCase()} YOU MAY ENJOY </h2>
            <CardGrid
              artists={similar}
              cardType={ArtistCardType.otherSingers}
              cardColorCategoryId={artist.categoryId}
            />
            <Link to="/roster">
              <ReturnToRoster width={240} className="mt-3 mr-0 ml-auto fill-current hover:text-yellow-300" />
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-gray-200">
        <div className="container max-w-lg min-w-lg mx-auto">
          <Footer />
        </div>
      </div>
    </>
  );
}
