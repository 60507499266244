import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getDates } from '../../mock/utils';

interface ICalendar {
  includeDatesArray?: Date[];
  date: Date;
  setDate(date: Date): void;
  showHours: boolean;
  label: string;
  required?: boolean;
  startDate?: Date;
}

const oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
const dates = getDates(new Date(), oneYearFromNow);

export const Calendar = ({
  includeDatesArray = dates,
  setDate,
  date,
  showHours,
  label,
  required,
  startDate = new Date(new Date().setHours(new Date().getHours(), 0)),
}: ICalendar) => {
  const format = showHours ? 'MMMM d yyyy, h:mm a' : 'MMMM d yyyy';

  const changeHandler = (newDate: Date) => {
    setDate(newDate);
  };

  return (
    <div className="text-black">
      <div className="text-white mb-3">{label}</div>
      <DatePicker
        customInput={<input className="px-4 py-2 w-full rounded" />}
        wrapperClassName="w-full"
        isClearable
        selected={date}
        showTimeSelect={showHours}
        timeIntervals={60}
        minDate={startDate}
        dateFormat={format}
        includeDates={includeDatesArray}
        onChange={changeHandler}
        startOpen={false}
        required={required}
      />
    </div>
  );
};
