import React from 'react';
import { PageHeader } from '../../components';
import HomeVideo from './HomeVideo';
import HomeFooter from './HomeFooter';
import HomeBanner from './HomeBanner';
import HomeFeatured from './HomeFeatured';
import { Footer } from '../../components/Footer';

export const Home: React.FC = () => {
  return (
    <>
      <div className="container max-w-lg min-w-lg mx-auto">
        <PageHeader color="text-black" showVipAccessButton position="absolute" />
        <HomeVideo />
        <HomeBanner />
        <HomeFeatured />
        <HomeFooter />
      </div>
      <div className="container max-w-lg min-w-lg mx-auto bg-gray-200">
        <Footer />
      </div>
    </>
  );
};
