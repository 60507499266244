import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../hooks';
import { userSelector } from '../../store/user/userSlice';

export default function ProfilePreview() {
  const { artist: { profileImageSrc: currentProfileImage = '', profileVideoUrl: currentProfileVideo = '' } = {} } =
    useAppSelector(userSelector);
  const { watch } = useFormContext();
  const {
    firstName,
    lastName,
    subcategories,
    headline,
    artistBio,
    productDescription,
    performancePrice,
    performanceDuration,
    profileImageSrc,
    profileVideoUrl,
  } = watch();

  const hasImageOnInput = !!profileImageSrc?.[0];
  const renderImage = () => {
    if (hasImageOnInput) {
      return (
        <div className="flex-1 bg-top bg-cover bg-gray-200">
          <img className="w-full h-full" src={URL.createObjectURL(profileImageSrc[0])} alt="preview" />
        </div>
      );
    }
    const style = currentProfileImage ? { backgroundImage: `url("${currentProfileImage}")` } : {};
    return <div className="flex-1 bg-top bg-cover bg-gray-200" style={style} />;
  };

  const hasVideoOnInput = !!profileVideoUrl?.[0];
  const renderVideo = () => {
    if (hasVideoOnInput) {
      return (
        <div className="w-9/12 bg-gray-400">
          <video
            width="100%"
            height="100%"
            src={URL.createObjectURL(profileVideoUrl[0])}
            autoPlay
            controls
            muted
            loop
            playsInline
          />
        </div>
      );
    }
    return (
      <div className="w-9/12 bg-gray-400">
        <video width="100%" height="100%" src={currentProfileVideo} autoPlay controls muted loop playsInline />
      </div>
    );
  };

  return (
    <div>
      <h2 className="text-4xl text-center mb-4">PROFILE PREVIEW</h2>
      <div id="header-with-video" className="pb-8">
        <h1 className="text-3vw xl:text-4xl xl:mb-1 font-bold text-white my-0 uppercase">
          {`${firstName} ${lastName}`}
          <span className="uppercase text-2vw font-thin mx-2 mb-3 xl:text-2xl">
            {subcategories && subcategories?.filter(Boolean)?.join(' | ')}
          </span>
        </h1>
        <div className="flex">
          {renderImage()}
          {renderVideo()}
        </div>
      </div>
      <div className="mx-auto max-w-lg text-white text-center text-base my-10">
        <h2 id="title" className="uppercase text-lg sm:text-2vw xl:text-2xl leading-tight text-center text-white mt-4">
          <span className="italic">{headline}</span>
        </h2>
        <p className=" my-5">{artistBio}</p>
        <p>
          <span className="font-semibold">SHOW DESCRIPTION</span>: {productDescription} The performance lasts{' '}
          <span className="font-semibold">{performanceDuration}</span> and it&apos;s priced at{' '}
          <span className="font-semibold">${performancePrice}</span>
        </p>
      </div>
    </div>
  );
}
