interface IModalPicker {
  children: JSX.Element;
  isOpen: boolean;
  handleClose(): void;
  className?: string;
  showCloseBtn: boolean;
}

export default function ModalPicker({
  children,
  isOpen = false,
  handleClose,
  className = 'inline-block bg-actors px-8 py-8',
  showCloseBtn = true,
}: Partial<IModalPicker>) {
  return (
    <div
      className={`fixed w-screen inset-0 overflow-hidden ${!isOpen && 'hidden'} select-none z-20`}
      onClick={() => handleClose && handleClose()}
      aria-hidden="true"
    >
      <div className="flex justify-center items-center h-full">
        <div className={className} onClick={(e: React.SyntheticEvent) => e.stopPropagation()} aria-hidden="true">
          {showCloseBtn && (
            <div className="relative">
              <button
                type="button"
                className="px-2 text-3xl absolute -right-6 -top-7"
                onClick={() => handleClose && handleClose()}
              >
                x
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
