import { PageHeader } from '../../components';
import { Footer } from '../../components/Footer';

/* eslint-disable jsx-a11y/anchor-is-valid */
const Faqs = () => {
  return (
    <div className="container max-w-lg min-w-lg mx-auto text-lg">
      <PageHeader color="text-black" />
      <table width="694" cellSpacing="0" cellPadding="0">
        <colgroup>
          <col width="694" />
        </colgroup>
        <tbody>
          <tr>
            <td width="694" height="21">
              <strong>PEN Frequently Asked Questions</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <a href="#customer" className="hover:text-blue-600">
                Customer FAQs
              </a>
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <a href="#artist" className="hover:text-blue-600">
                Artist FAQs
              </a>
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <a href="#associate" className="hover:text-blue-600">
                Associates FAQs
              </a>
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <a href="/" className="hover:text-blue-600">
                Go Home
              </a>
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>
                <a id="customer" href="">
                  CUSTOMER FAQs
                </a>
              </strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>What is PEN?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="63">
              PEN is a unique, new service that provides virtual performances by top entertainers to private customers
              in their homes. Customers can search our website for a favorite artist or check out performers based on
              any combination of style, availability and cost.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>What type of artists can I book on PEN?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="42">
              PEN currently is building a roster of diverse artists that includes musicians, actors, comedians &amp;
              magicians. More categories of performers and presenters will be added in the future.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>What setup do I need in my home to take advantage of the PEN service?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="134">
              The uniqueness of PEN allows for two-way audio and video interaction between the Artist and Customer.
              Applause, cheering, conversations, song requests, etc. all create a much more &quot;live&quot; interaction
              than livestreaming could ever do. With this in mind, the Customer will need to add a simple
              webconferencing setup to their usual place that they watch TV and movies so that the Artist can see and
              engage with them. PEN offers more detailed guidelines in setting up for a PEN Performance, including
              utilizing our network of qualified technology integrators to perform a one-time setup of the right
              technology.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>Can I watch a PEN Performance on my laptop or iPad/iPhone?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="63">
              You certainly can, but given the special nature of our events and the interaction with the Artists, we
              would expect that most of our Customers would want to experience a PEN Performance on the largest and best
              quality screen and audio system that they own in their home theaters or media rooms.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>Can I invite my friends to watch?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="63">
              PEN is meant to primarily be a one-to-one connection between Artist and Customer. Having said that, the
              Customer certainly is free to invite as many people as they want to their location to experience the PEN
              Performance live.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>How long are PEN Performances?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="73">
              Our introductory offering is a single length performance of 60 minutes and the Artist will set a cost
              value for that performance duration. For longer concerts, a Customer may book back-to-back 60 minute
              events with the Artist, assuming they are available. Future offerings will include shorter duration
              events.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>How does PEN allow me to search for a specific performance need?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="63">
              One of the strengths of PEN is our &quot;Find the Perfect Performance&quot; feature that searches our
              Artist Roster based on performance style, availability and cost range. This allows Customers to tailor
              their special PEN Performance to exactly what they are looking for.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>How do I pay for an event?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="63">
              Once you have found your Artist and chosen a performance date and time, PEN allows you to securely book
              the event using Paypal and then receive both confirmation of the booking and a secure link to the PEN
              Performance event.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table width="694" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td width="694" height="21">
              <strong>
                <a id="artist">ARTIST FAQs</a>
              </strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>What is PEN?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="105">
              PEN is a unique, new service that provides virtual performances by top entertainers to private customers
              in their homes. Artists can establish their performance categories and subcategories (e.g.
              Musician:Country), general availability, and set a price for their performance. Customers can then search
              our website for performers based on any combination of these parameters to find their &quot;Perfect
              Performance&quot;.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>Is this another livestreaming site?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="84">
              No, PEN was created to overcome the limitations of the one-to-many livestream model where the only
              feedback artists get to their performance are some &quot;likes&quot; and &quot;emojis&quot; flashing
              across a screen. PEN provides true interaction to allow the Artist to hear the applause and see reactions
              of the audiences in real time.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>What types of Artists are featured on PEN?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="42">
              PEN currently is building a roster of diverse artists that includes musicians, actors, comedians &amp;
              magicians. More categories of performers and presenters will be added in the future.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>How do I sign up as an Artist?</strong>
            </td>
          </tr>
          <tr>
            <td width="694">
              Artists can sign up right on our site and establish an account to enter, save and update their background
              information, including uploading a headshot photo and optionally a promotional video. Artists will need to
              have a Facebook account, as this will be the primary way to connect with their PEN account.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>What is a Private PEN Artist?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="84">
              While we hope that most Artists will choose to be available on our public site for any of our global
              customers to book them, we are also offering an option for certain high-profile Artists to utilize our
              service and technology to offer their PEN Performances on a select basis to VIP customers that they
              arrange directly or through an authorized PEN Associate.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>What kind of technical setup do I need to have to give a PEN Performance?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="147">
              Most artists who have been livestreaming over the past couple of years are used to being able to deliver
              quality performances through streaming. The audio equipment will generally consist of a quality microphone
              and instruments ran through a mixer connected to a USB-Audio interface. The video will require any device
              that can record/broadcast video at least at a 1080p resolution. It will be important that the Artist have
              the provision to both see and hear the audience on the customer side. PEN offers more detailed guidelines
              in setting up for a PEN Performance, including utilizing our network of qualified technology integrators
              to perform a one-time setup of the right technology.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>Who are PEN Customers?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="84">
              We are planning for PEN Customers to represent an economically and culturally broad range of individuals
              who appreciate great live entertainment, have audio-visual systems in their homes capable of experiencing
              a PEN Performance in a powerful way and will be willing to book events with artists of all calibers and
              price points - from great local/regional performers to Grammy award winners.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>How can I make my artist profile more visible to potential customers?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="63">
              Our introductory Artist setup will have the fundamental elements to establish a quality profile to attract
              customers. Future offerings will be established to allow our Artists to implement expanded marketing and
              promotional opportunities through our site.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table width="694" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td width="694" height="21">
              <strong>
                <a id="associate">ASSOCIATE FAQs</a>
              </strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>What is a PEN Associate?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="42">
              A PEN Associate is an individual or corporate entity that has interest in bringing Customers to the PEN
              service on a referral basis.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>How does it work?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="84">
              Someone interested in becoming a PEN Associate would need to contact us to discuss the opportunities and
              the terms in more detail. Once we establish you as a qualified Associate, we will provide an account that
              will allow you to register and track the booking activity of your referral Customers through a VIP Access
              link.
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <br />
            </td>
          </tr>
          <tr>
            <td width="694" height="21">
              <strong>What other benefits does becoming a PEN Associate offer?</strong>
            </td>
          </tr>
          <tr>
            <td width="694" height="63">
              The other key benefit to becoming an Associate of PEN will be allowing you and your Customers VIP access
              to Artists that are not on our public site, but have given permission to be available to select Customers.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export { Faqs };
