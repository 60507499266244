import { Link } from 'react-router-dom';
import FindPerfectPerformanceButton from '../../components/buttons/FindPerfectPerformance';
import HowPenWorks from '../../components/buttons/HowPenWorks/HowPenWorks';

export default function HomeFooter() {
  return (
    <div className="flex flex-col">
      <div className="flex justify-around">
        <div className="p-5">
          <HowPenWorks type="color" width={65} />
        </div>
        <div className="py-6 ml-20">
          <Link
            to={{
              pathname: '/roster',
              hash: '#finder',
            }}
            className="mt-3 font-semibold text-center text-2xl text-default text-shadow-default"
          >
            <FindPerfectPerformanceButton width={300} />
          </Link>
        </div>
      </div>
    </div>
  );
}
