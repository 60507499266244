import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { VolumeHigh, VolumeOff } from '../../components/Icons';

export default function HomeVideo() {
  const url = 'https://home-video-1.s3.amazonaws.com/home-video/PEN+Video+Loop+REV+8+16+21.mp4';
  const [muted, setMuted] = useState(true);
  const VolumeIcon = muted ? VolumeOff : VolumeHigh;
  const player = useRef();

  const handleVolume = () => {
    setMuted(prev => !prev);
  };
  return (
    <div className="aspect-w-14 aspect-h-6 bg-white overflow-hidden relative w-full">
      <ReactPlayer
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ref={player}
        className="react-player"
        width="100%"
        height="100%"
        playsinline
        url={url}
        playing
        muted={muted}
        config={{ file: { attributes: { autoPlay: true, muted: true } } }}
        onEnded={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          player?.current?.seekTo(1.5);
        }}
      />
      <div className="block">
        <div className="absolute bottom-0 right-0">
          <button type="button" className="bg-gray-300 rounded-full p-3 m-4" onClick={handleVolume}>
            <VolumeIcon size={25} />
          </button>
        </div>
      </div>
    </div>
  );
}
