import { ReactComponent as SearchAgainSVG } from './searchAgain.svg';

const DEFAULT_COLOR = '#D4596A';
const DEFAULT_WIDTH = 500;

interface SearchAgainButtonProps {
  color?: string;
  width?: number;
}

// TODO: change color on hover or click

function SearchAgain({ color = DEFAULT_COLOR, width = DEFAULT_WIDTH }: SearchAgainButtonProps) {
  return <SearchAgainSVG color={color} width={width} />;
}

export { SearchAgain };
