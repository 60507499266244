interface IPenLoader {
  marginTop?: string;
  loadingText?: string;
}

export const PenLoader = ({ loadingText = 'Loading...', marginTop = ' mt-20' }: IPenLoader) => {
  return (
    <div className={`container mx-auto ${marginTop}`}>
      <div className="w-2/5 mx-auto">
        <h1 className="text-5xl font-bold text-center">
          PEN <span className="font-medium">PERFORMANCES</span>
        </h1>
        <img className="w-full" src="/assets/icons/Diamond Animation.gif" alt="Loading animation" />
        <h1 className="text-5xl text-center"> {loadingText} </h1>
      </div>
    </div>
  );
};
