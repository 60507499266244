import { createSlice, createSelector } from '@reduxjs/toolkit';
import { IPenResponse, IVonageInfo, IRootState } from '../../types';
import { createRoomToken, createToken } from './vonageActions';

const initialState: IVonageInfo = {
  sessionId: undefined,
  apiKey: undefined,
  token: undefined,
  isLoading: true,
  message: 'Waiting for response...',
};

export const vonageSlice = createSlice({
  name: 'vonage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createRoomToken.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createRoomToken.fulfilled, (state, { payload }: { payload: IPenResponse }) => {
      state.message = payload.message;
      state.sessionId = payload.data.sessionId;
      state.isLoading = false;
    });
    builder.addCase(createRoomToken.rejected, (state, { error }) => {
      state.message = error.message;
      state.isLoading = false;
    });
    builder.addCase(createToken.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createToken.fulfilled, (state, { payload }: { payload: IPenResponse }) => {
      state.message = payload.message;
      state.sessionId = payload.data.sessionId;
      state.apiKey = payload.data.apiKey;
      state.token = payload.data.token;
      state.isLoading = false;
    });
    builder.addCase(createToken.rejected, (state, { error }) => {
      state.message = error.message;
      state.isLoading = false;
    });
  },
});

const vonageState = (state: IRootState) => state.vonage;

export const roomSelector = createSelector(vonageState, state => state.sessionId);
export const apiKeySelector = createSelector(vonageState, state => state.apiKey);
export const tokenSelector = createSelector(vonageState, state => state.token);
export const isLoadingSelector = createSelector(vonageState, state => state.isLoading);
export const messageSelector = createSelector(vonageState, state => state.message);
