import { IArtist, ICategory, ICategoryBanner } from '../types';
import { categories } from '../config';
import ArtistsJSON from './artist.json';
import { getHeadshot } from './utils';

const artistsByCategory = categories.reduce((acc, category: ICategory) => {
  return { ...acc, [category.id]: ArtistsJSON.filter(artist => artist.categoryId === category.id) };
}, {});

const getFeaturedArtists = () => ArtistsJSON.filter(artist => artist.featured);

const getArtistById = (id: string) => ArtistsJSON.filter(artist => artist.urlFriendlyId === id)[0];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getRelatedArtists = (artist: IArtist) => {
  const randomRange = new Set();
  while (randomRange.size < 5) {
    const random = Math.floor(Math.random() * ArtistsJSON.length);
    randomRange.add(random);
  }
  return (Array.from(randomRange) as number[]).map(i => ArtistsJSON[i]);
};

const getArtists = (headShotList: number[]) => {
  return headShotList.map(
    (headshot, i) =>
      ({
        id: `${headshot}`,
        firstName: `First name ${i + 1}`,
        lastName: `Last name ${i + 1}`,
        profileImageSrc: getHeadshot(headshot),
        artistBio: getHeadshot(headshot),
        email: '',
        performancePrice: 1,
        categories: [],
      } as IArtist),
  );
};

const musiciansHeadshotsBanner = [5, 10, 7];
const actorsHeadshotsBanner = [28, 25, 13];
const comediansHeadshotsBanner = [17, 26, 24];
const dancersHeadshotsBanner = [2, 16, 21];
const chefsHeadshotsBanner = [6, 31, 25];
const luminariesHeadshotsBanner = [27, 23, 20];

export const bannerCategory: ICategoryBanner[] = [
  {
    name: 'musicians',
    description: 'a few words about our various musicians go here',
    artists: getArtists(musiciansHeadshotsBanner),
  },
  {
    name: 'actors',
    description: 'a few words about our various actors go here',
    artists: getArtists(actorsHeadshotsBanner),
  },
  {
    name: 'comedians',
    description: 'a few words about our various comedians go here',
    artists: getArtists(comediansHeadshotsBanner),
  },
  {
    name: 'dancers',
    description: 'a few words about our various dancers go here',
    artists: getArtists(dancersHeadshotsBanner),
  },
  {
    name: 'chefs',
    description: 'a few words about our various chefs go here',
    artists: getArtists(chefsHeadshotsBanner),
  },
  {
    name: 'luminaries',
    description: 'a few words about our various luminaries go here',
    artists: getArtists(luminariesHeadshotsBanner),
  },
];

export default {
  artistsByCategory,
  getFeaturedArtists,
  getArtistById,
  getRelatedArtists,
};
