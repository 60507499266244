import { Dispatch, SetStateAction, useEffect } from 'react';
import { flattenDeep, sortBy, uniqBy } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getDates } from '../../../mock/utils';
import { addAvailabilityRow, availabilitySelector, removeAvailabilityRow } from '../../../store/profile/profileSlice';
import AvailabilityRow from './AvailabilityRow';

interface AvailabilityI {
  setAvailability: Dispatch<SetStateAction<Date[]>>;
  categoryId: string;
}

interface DaysOfWeekI {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

interface RowI {
  endDate: string;
  startDate: string;
  selectedHours: {
    [key: string]: boolean;
    '00:00': boolean;
    '01:00': boolean;
    '02:00': boolean;
    '03:00': boolean;
    '04:00': boolean;
    '05:00': boolean;
    '06:00': boolean;
    '07:00': boolean;
    '08:00': boolean;
    '09:00': boolean;
    '10:00': boolean;
    '11:00': boolean;
    '12:00': boolean;
    '13:00': boolean;
    '14:00': boolean;
    '15:00': boolean;
    '16:00': boolean;
    '17:00': boolean;
    '18:00': boolean;
    '19:00': boolean;
    '20:00': boolean;
    '21:00': boolean;
    '22:00': boolean;
    '23:00': boolean;
  };
  selectedWeekDays: DaysOfWeekI;
}

export default function Availability({ setAvailability, categoryId }: AvailabilityI) {
  const availabilityState = useAppSelector(availabilitySelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const isAvailabilityEmpty = availabilityState.allIds.length === 0;

    if (isAvailabilityEmpty) {
      dispatch(addAvailabilityRow());
    }

    const getAllHours = (date: string, selectedHours: number[], selectedWeekdays: number[]): Date[] => {
      if (selectedWeekdays.includes(new Date(date).getDay())) {
        return selectedHours.map(selectedHour => {
          const dayOf = new Date(date);
          dayOf.setHours(selectedHour);
          return dayOf;
        });
      }
      return [];
    };

    const processDaysOfWeek = ({ monday, tuesday, wednesday, thursday, friday, saturday, sunday }: DaysOfWeekI) => {
      const selection = [];
      if (sunday) selection.push(0);
      if (monday) selection.push(1);
      if (tuesday) selection.push(2);
      if (wednesday) selection.push(3);
      if (thursday) selection.push(4);
      if (friday) selection.push(5);
      if (saturday) selection.push(6);
      return selection;
    };

    const processAvailabilityRow = (row: unknown) => {
      const { startDate, endDate, selectedHours, selectedWeekDays } = row as RowI;
      const dates = getDates(new Date(`${startDate} 00:00`), new Date(`${endDate} 00:00`));
      const daysOfTheWeekSelected = processDaysOfWeek(selectedWeekDays);
      const hours = Object.keys(selectedHours)
        .filter(key => selectedHours[key])
        .map(hour => parseInt(hour.substring(0, 2), 10));
      return flattenDeep(dates.map(date => getAllHours(date.toDateString(), hours, daysOfTheWeekSelected)));
    };

    const { allIds } = availabilityState;

    const datesWithHours = sortBy(
      uniqBy(flattenDeep(allIds.map(id => availabilityState.byId[id]).map(row => processAvailabilityRow(row))), date =>
        date.getTime(),
      ),
      date => date.getTime(),
    );
    setAvailability(datesWithHours);
  }, [availabilityState, dispatch, setAvailability]);

  const handleAddRow = () => {
    dispatch(addAvailabilityRow());
  };

  const handleRemoveRow = (id: string) => {
    dispatch(removeAvailabilityRow(id));
  };

  return (
    <div className="my-5">
      <h2 className="text-2xl w-full text-center uppercase">Set your availability</h2>
      <div className="flex flex-wrap justify-around">
        {availabilityState.allIds.map(rowId => {
          return (
            <AvailabilityRow
              rowId={rowId}
              key={rowId}
              categoryId={categoryId}
              handleRemove={() => handleRemoveRow(rowId)}
            />
          );
        })}
      </div>
      <div className="w-full pt-10 flex justify-center">
        <button
          className="text-center px-2 py-2 border border-white text-3xl uppercase mr-8 w-16"
          type="button"
          onClick={handleAddRow}
        >
          +
        </button>
      </div>
      <hr className="my-10 opacity-0" />
    </div>
  );
}
