const GET_PRESIGNED_URL_API = 'https://api-dev.penperformances.com/upload/upload-public-file';
const BUCKET_URL = 'https://pen-profile-media.s3.us-east-1.amazonaws.com';

interface IUpload {
  file: File;
  userId: string;
  tag: string;
}

export default async function s3upload({ file, userId, tag = 'profile-image' }: IUpload) {
  try {
    if (!file) return null;
    if (!userId) {
      throw new Error('Missing userId');
    }
    const exts = file.name.split('.').slice(-1)[0];

    const fileName = `user-${userId}-${tag}.${exts}`;
    const fileType = file.type;

    const response = await fetch(GET_PRESIGNED_URL_API, {
      method: 'post',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({ fileName, fileType }),
    });

    const responseJson = await response.json();
    const { uploadURL } = responseJson;

    await fetch(uploadURL, {
      method: 'PUT',
      body: file,
    });
    const cacheBreaker = Math.floor(Math.random() * 10000);
    return `${BUCKET_URL}/${fileName}?seed=${cacheBreaker}`;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed to upload ${file.name} - ${tag}`);
    return null;
  }
}
