import { PageHeader } from '../../components';
import { Footer } from '../../components/Footer';

const TermsOfService = () => {
  return (
    <div className="container max-w-lg min-w-lg mx-auto text-gray-700 space-y-6">
      <PageHeader color="text-black" />
      <br />
      <p className="text-3xl">Site Terms of Service</p>
      <br />
      <p>Last Updated: August 17, 2021</p>
      <p>
        This Site Terms of Service Agreement (“Terms”) governs your use of the PEN Performances® offered by LIVENET Inc.
        doing business as LIVENET INC/PEN Performances (“we”, “us”, or “LIVENET INC/PEN Performances”), including our
        website (LIVENET INC/PEN Performances.com), mobile application (“App”), and services we provide through them
        (collectively, the website, App, and services referred to as our “Site”). “You” refers to you as a user of the
        Site.
      </p>
      <p>
        These Terms apply to users of, including visitors to, our Site. Use of our Site is also subject to our current{' '}
        <u>Acceptable Use Policy</u>. Your use of our Site as a Talent User (defined below) is governed by the{' '}
        <u>Talent Terms of Service.</u> (“Talent Terms”).
      </p>
      <p>
        <strong>
          PLEASE READ THESE TERMS CAREFULLY. By using our Site or otherwise indicating your acceptance (for example, by
          agreeing when creating or logging into your account, clicking “I Agree,” etc.), you represent and warrant that
          you have read, understand, and agree to be bound by these Terms. If you do not agree, do not access or use our
          Site.
        </strong>
      </p>
      <p>
        <strong>ARBITRATION NOTICE: </strong>
        <strong>
          <u>Section 17</u>
        </strong>
        <strong>
          of these Terms contains provisions governing how claims that you and LIVENET INC/PEN Performances have against
          each other are resolved, including any claims that arose or were asserted prior to the “Last Updated” date at
          the top of these Terms. It contains an arbitration agreement that will, with limited exceptions, require
          disputes between you and LIVENET INC/PEN Performances to be submitted to binding and final arbitration. Unless
          you opt out of the arbitration agreement as described in
        </strong>
        <strong>
          <u>Section 17</u>
        </strong>
        <strong>
          : (1) you may pursue claims and seek relief against us only on an individual basis, not as a plaintiff or
          class member in any class or representative action or proceeding; and (2) you waive your right to seek relief
          in a court of law and to have a jury trial on your claims.
        </strong>
      </p>
      <p>
        1. <strong>LIVENET INC/PEN PERFORMANCES Marketplace</strong> <strong />
      </p>
      <p>
        By creating an account on our Site, you agree to provide true, accurate, current, and complete information. You
        agree not to create a Site account using a false identity or providing false information or if you have
        previously been removed or banned from our Site. You are responsible for maintaining the confidentiality of your
        Site account information, including your username and password. You are responsible for all activities that
        occur on or in connection with your Site account and you agree to notify us immediately of any unauthorized
        access or use of your Site account. You acknowledge and agree that we are not responsible or liable for any
        damages, losses, costs, expenses, or liabilities related to any unauthorized access to or use of your Site
        account.
      </p>
      <p>
        2. <strong>LIVENET INC/PEN PERFORMANCES Videos</strong>
        <strong />
      </p>
      <p>
        a. Through our Site, you may obtain personalized videos (“LIVENET INC/PEN PERFORMANCES Videos”) from
        celebrities, including athletes, actors, performers, artists, influencers, and others (each, a “Talent User”).
        You may submit a request to a Talent User for a LIVENET INC/PEN PERFORMANCES Video that is personalized for you
        or a third party that you identify as a recipient (“Recipient”).
      </p>
      <p>
        b. You acknowledge and agree that the Talent User has sole discretion to determine how to fulfill your request
        and the content of the LIVENET INC/PEN PERFORMANCES Video created, and may not follow your request exactly. We
        reserve the right to reject any request in our sole discretion. The Talent User has up to seven days (at our
        sole discretion) to fulfill or decline your request. Once your request is fulfilled, your payment method will be
        charged the amount specified on the Talent User’s booking page on our Site at the time you submitted the
        request.
      </p>
      <p>
        c. LIVENET INC/PEN PERFORMANCES Videos are licensed, not sold. You are buying the right (or license) to use it,
        not the actual LIVENET INC/PEN PERFORMANCES Video itself.
      </p>
      <p>
        d. Subject to your payment in full, the Talent User hereby grants to you the following limited rights to use the
        LIVENET INC/PEN PERFORMANCES Video (other than a Business LIVENET INC/PEN PERFORMANCES Video)
        <strong>solely for your own personal, non-commercial, and non-promotional purposes</strong>, subject to these
        Terms: a non-exclusive, royalty-free, fully paid, worldwide, sublicensable, revocable license to use, reproduce,
        distribute, and publicly display that LIVENET INC/PEN PERFORMANCES Video, in any and all media (for example, on
        social media platforms), whether now known or hereafter invented or devised.
      </p>
      <p>
        e. You may not sell, resell, commercialize, or encumber your rights in any LIVENET INC/PEN Performances
        Offering, including creating a non-fungible token (“NFT”) from any LIVENET INC/PEN Performances Offering except
        as agreed by LIVENET INC/PEN Performances in writing. You may sublicense your rights in a LIVENET INC/PEN
        PERFORMANCES Video only to the extent necessary for you to use the LIVENET INC/PEN PERFORMANCES Video as
        permitted under these Terms (for example, sharing it (if it is not a Business LIVENET INC/PEN PERFORMANCES
        Video) with friends on a social media platform or sending it to a Recipient for personal, non-commercial, and
        non-promotional purposes as set forth above).
      </p>
      <p>
        f. You may use a LIVENET INC/PEN PERFORMANCES Video only in accordance with these Terms, which includes our{' '}
        <u>Acceptable Use Policy</u>. We may terminate all or part of the foregoing licenses at any time for any reason.
        We reserve the right to remove a LIVENET INC/PEN PERFORMANCES Video from our Site at any time for any reason
        without any notice to you.
      </p>
      <p>
        3. <strong>Business LIVENET INC/PEN PERFORMANCES Videos</strong> <strong />
      </p>
      <p>
        a. Some Talent Users in the United States may offer LIVENET INC/PEN PERFORMANCES Videos for the promotion of a
        Recipient that is a single U.S. commercial entity, brand, or business (&quot;Business&quot;) through our Site
        (each, a &quot;Business LIVENET INC/PEN PERFORMANCES Video&quot;). Except as noted, each Business LIVENET
        INC/PEN PERFORMANCES Video is a LIVENET INC/PEN PERFORMANCES Video under these Terms. When you submit a request
        for a Business LIVENET INC/PEN PERFORMANCES Video, you must specifically identify the Business, the types of
        goods or services that it offers, as well as the specific product, service, or brand that you request the Talent
        User to mention or refer to, and any other requested information.
      </p>
      <p>
        b. Subject to your payment in full, the Talent User hereby grants to you the following limited rights to use the
        Business LIVENET INC/PEN PERFORMANCES Video
        <strong>
          solely for the reasonable promotional purposes of the Business for 30 days from the date the Business LIVENET
          INC/PEN PERFORMANCES Video is sent by LIVENET INC/PEN Performances to you
        </strong>
        (and for any additional periods that have been agreed), subject to these Terms:
      </p>
      <p>
        i. a non-exclusive, royalty-free, fully paid, worldwide, sublicensable, revocable license to use, reproduce,
        distribute, and publicly display that Business LIVENET INC/PEN PERFORMANCES Video only on or in one of the
        following (as designated at the time of the request): (A) one website wholly-owned, operated, and controlled by
        the Business; (B) one social media account, such as Facebook, Instagram, LinkedIn, SnapChat, TikTok, or Twitter;
        in each case, where the website and social media account is wholly-owned, operated, and controlled by the
        Business, along with the right to advertise and promote the display of the Business LIVENET INC/PEN PERFORMANCES
        Video on the social media account through advertising only on the applicable social media platform; (C) one
        communication sent through a Business-operated and controlled channel of communication (such as an email blast
        or text message); (D) if the Business has fewer than 1,000 employees, one event conducted and operated by the
        Business (for example, a company-wide meeting).
      </p>
      <p>
        You may sublicense your rights in a Business LIVENET INC/PEN PERFORMANCES Video only to the extent necessary for
        you to use the Business LIVENET INC/PEN PERFORMANCES Video as permitted under these Terms (for example, posting
        it on a website or social media account as set forth in this section 3.b.).
      </p>
      <p>
        c. <strong>No other use is authorized under these Terms.</strong>To request other uses (for example, additional
        time; use on additional websites, social media accounts, or communications; the ability to add a Business name
        or logo as a watermark, etc.), contact us at <u>business@PENPerformances.com</u>.
      </p>
      <p>
        d.
        <u>Business LIVENET INC/PEN PERFORMANCES Video Representations and Warranties</u>: You represent and warrant
        that:
      </p>
      <p>
        i. the Business is located, and operates, in the U.S. and the Business LIVENET INC/PEN PERFORMANCES Video will
        be directed to a U.S. audience;
      </p>
      <p>
        ii. any information provided to Talent is factually correct and not misleading and is not disparaging or
        defamatory;
      </p>
      <p>
        iii. you and the Business will comply with all applicable laws, rules, and regulations, including the Federal
        Trade Commission “Guides Concerning the Use of Endorsements and Testimonials in Advertising,” which may require
        adding an appropriate hashtag (e.g. #ad, #sponsored) or other disclosure to the Business LIVENET INC/PEN
        PERFORMANCES Video; and
      </p>
      <p>
        iv. you have all rights necessary (including from the Business) to request a Business LIVENET INC/PEN
        PERFORMANCES Video on behalf of the Business, to agree to these Terms on behalf of the Business, and to request
        and use the Business LIVENET INC/PEN PERFORMANCES Video as authorized in these Terms, including all rights
        necessary to use any information, Business name, trademark, trade name, trade dress, or logos provided in
        connection with your Submission.
      </p>
      <p>
        4. <strong>Acknowledgement</strong>
        <strong />
      </p>
      <p>You acknowledge and agree that:</p>
      <p>
        a. LIVENET INC/PEN Performances will not be liable or responsible for any LIVENET INC/PEN PERFORMANCES Video or
        other offering requested by you or any Submission (defined below) you make;
      </p>
      <p>
        b. you have no expectation of privacy with respect to any LIVENET INC/PEN PERFORMANCES Video requested by you or
        any Submission (defined below) you make, and that you will not make any request or Submission that infringes on
        the privacy or other rights of a third party;
      </p>
      <p>
        c. the LIVENET INC/PEN PERFORMANCES watermark on each LIVENET INC/PEN PERFORMANCES Video must remain intact and
        you agree not to edit, change, modify, cover, or remove the watermark from any LIVENET INC/PEN PERFORMANCES
        Video or assist or encourage any third party to do so; you further agree not to edit, change, modify, or create
        any derivative work of a LIVENET INC/PEN PERFORMANCES Video or assist or encourage any third party to do so;
      </p>
      <p>
        d. if you breach any provisions of these Terms, we terminate your access to our Site, or we remove or ban you
        (or any Site account you created or control), your license to use any LIVENET INC/PEN PERFORMANCES Video, or
        other offering under these Terms terminates and you must: promptly remove all copies of any LIVENET INC/PEN
        PERFORMANCES Video, or other offering in your possession or control, including from any social media platform;
        notify any Recipient of the termination and instruct them to do the same, and take any other action we
        reasonably request, including identifying each Recipient; and
      </p>
      <p>
        e. without limiting any of our rights, any request you submit through our Site may be rejected by us or by a
        Talent User; if that happens more than once, we may terminate your access to our Site, remove or ban you (and
        any Site account you created or control), or take other appropriate action in LIVENET INC/PEN Performances’s
        sole discretion, including terminating your license to use any LIVENET INC/PEN PERFORMANCES Video or other
        offering under these Terms and requiring you to take the actions outlined in Section 4.d.
      </p>
      <p>
        5. <strong>Additional Terms</strong>
        <strong />
      </p>
      <p>
        Some products or services offered through the Site may have additional terms and conditions (“Additional
        Terms”). If Additional Terms apply, we will make them available to you in connection with that product or
        service. By using that product or service, you agree to the Additional Terms. To the extent that the Additional
        Terms conflict with any of these Terms, these Terms will govern unless the Additional Terms say that some or all
        of these Terms don’t apply.
      </p>
      <p>
        6. <strong>Eligibility</strong>
        <strong />
      </p>
      <p>
        a. <u>Age</u>: You must be at least 13 years old (or, outside of the United States, the applicable legal age to
        enter into a contract) to use our Site.
      </p>
      <p>
        b. <u>Eligibility Representations and Warranties</u>: You represent and warrant that:
      </p>
      <p>
        i. you have not been prohibited from using or accessing any aspect of our Site by us or pursuant to any
        applicable law or regulation;
      </p>
      <p>
        ii. you will comply with all applicable terms of any third party payment provider we select, and you are not on
        a prohibited list of that payment provider;
      </p>
      <p>
        iii. you (and any Site account that you created or control) have not been previously banned or removed from our
        Site for any reason; and
      </p>
      <p>iv. you are not a convicted sex offender.</p>
      <p>
        c. <u>Export Control</u>: You may not use, export, import, or transfer any part of our Site except as authorized
        by U.S. law, the laws of the jurisdiction in which you use or access our Site, or any other applicable laws. In
        particular, but without limitation, no part of our Site may be exported or re-exported: (i) into any country
        embargoed by the U.S.; or (ii) to anyone on the U.S. Treasury Department’s list of Specially Designated
        Nationals or the U.S. Department of Commerce’s Denied Persons List or Entity List. By using our Site, you
        represent and warrant that: (x) you are not located in a country that is subject to a U.S. Government embargo or
        that has been designated by the U.S. Government as a “terrorist supporting” country; and (y) you are not listed
        on any U.S. Government list of prohibited or restricted parties. You also will not use our Site for any purpose
        prohibited by law. You acknowledge and agree that products, services, and technology provided by LIVENET INC/PEN
        Performances are subject to the export control laws and regulations of the U.S. You will comply with those laws
        and regulations and will not, without prior U.S. government authorization, export, re-export, or transfer
        LIVENET INC/PEN Performances products, services, or technology, either directly or indirectly, to any country in
        violation of those laws and regulations.
      </p>
      <p>
        7. <strong>Fees and Payment</strong>
        <strong />
      </p>
      <p>
        a. <u>Fees</u>: The fee for a LIVENET INC/PEN PERFORMANCES Video or other offering is specified on the Talent
        User’s booking page on our Site when you make your request. You agree to pay all amounts due in accordance with
        the payment terms in effect when you submit your request or purchase merchandise, including any applicable
        service, transaction, or processing fees.
      </p>
      <p>
        b. <u>Currency</u>: All transactions are in U.S. dollars (“USD”) unless otherwise specified at point of
        purchase.
      </p>
      <p>
        c. <u>Payment</u>: You may request a LIVENET INC/PEN PERFORMANCES Video by using a valid payment card through
        the applicable third party payment provider (for App for iOS, Apple’s in-app payment mechanism; for our website
        and App for Android, the payment provider we select). You must provide the third party payment provider with
        valid payment information (Visa, MasterCard, or other issuer accepted by the payment provider). You acknowledge
        and agree that LIVENET INC/PEN Performances does not operate, own, or control the payment provider. Your use of
        your payment card is governed by your agreement with and the privacy policy of the payment provider, not these
        Terms. You agree to immediately notify the payment provider of any change in your billing address (or other
        information) for your payment card. You may not return or exchange a LIVENET INC/PEN PERFORMANCES Video and no
        refunds will be issued.
      </p>
      <p>
        i. <u>App for iOS</u>: If a Talent User rejects your request for a LIVENET INC/PEN PERFORMANCES Video or the
        LIVENET INC/PEN PERFORMANCES Video is not provided, your LIVENET INC/PEN Performances account will be issued a
        credit (in USD only) for the value of your purchase. The credit will be maintained in your account and may be
        redeemed only for purchases on the App for iOS. If, when you make a purchase while logged into your LIVENET
        INC/PEN PERFORMANCES account on the App for iOS, your account has a credit balance, the balance will be redeemed
        for that purchase (until fully redeemed) and you will be charged for any remaining portion of the price. Credit
        balances are not refundable, cannot be transferred, cannot be used outside of the App for iOS, and expire or
        extinguish immediately when redeemed. By making a purchase on the App for iOS, you represent that you are a
        resident of a country or territory in which payment in the local currency is supported by the App (as listed{' '}
        <u>here</u>). If you are a resident of a country or territory in which payment in the local currency is not
        supported by the App for iOS (or located in a country or territory in which payment in the local currency is not
        supported by the App for iOS), your purchase will not be permitted; however, if such purchase is permitted,
        LIVENET INC/PEN Performances reserves the right to cancel your request and no refund will be issued. The
        countries and territories and respective local currencies supported by the App for iOS are determined by Apple
        and not by LIVENET INC/PEN Performances and are subject to change at any time.
      </p>
      <p>
        ii. <u>Website and App for Android</u>: By providing your payment information, you agree that LIVENET INC/PEN
        Performances may place a pre-authorization hold and, after your request has been fulfilled, authorize the
        payment provider to immediately charge you for all amounts due and payable with no additional notice to or
        consent from you.
      </p>
      <p>
        iii. In addition, if the offering permits (e.g., LIVENET INC/PEN PERFORMANCES Videos on LIVENET INC/PEN
        Performances.com), you may choose to designate an additional amount as a “tip”. You acknowledge that LIVENET
        INC/PEN Performances does not mandate any such tip or gratuity; however, if you do choose to do so, the amount
        must be at least US $5.00. You agree to pay any amount you authorize as a tip; a tip is not refundable.
      </p>
      <p>
        d. LIVENET INC/PEN Performances reserves the right (but is under no obligation) to cancel your LIVENET INC/PEN
        PERFORMANCES Video request if: (i) your payment method is declined; or (ii) you have previously been banned or
        removed from our Site for any reason. LIVENET INC/PEN Performances also reserves the right at any time to change
        its fees and payment procedures, including its payment options and terms, either immediately upon posting on our
        Site or by other notice to you.
      </p>
      <p>
        e. <u>Portion of Payment to Talent</u>: Any payment for a LIVENET INC/PEN PERFORMANCES Video or any other
        offering, feature, or service on our Site, such as a tip or a sticker, will be divided between LIVENET INC/PEN
        Performances and Talent as provided for in <u>Section 4.b</u> of the <u>Talent Terms of Service</u>.
      </p>
      <p>
        f. <u>Payment Questions</u>: If you have a question about a purchase made on the App or a charge to your payment
        card, please contact us at <u>hello@ PENPerformances.com</u>. We have the sole discretion to determine how
        billing disputes between us will be resolved.
      </p>
      <p>
        8. <strong>Ownership</strong>
        <strong />
      </p>
      <p>
        a. You acknowledge and agree that each LIVENET INC/PEN PERFORMANCES Video or other offering from a Talent User
        is owned by the Talent User who created it.
      </p>
      <p>
        b. We or our licensors own all right, title, and interest in and to: (i) our Site and the “look and feel” of our
        Site, including all software, ideas, processes, data, text, media, and other content available on our Site
        (individually, and collectively, “LIVENET INC/PEN Performances Content”); and (ii) our trademarks, logos, and
        brand elements (“Marks”). Our Site, LIVENET INC/PEN Performances Content, and Marks are each protected under
        U.S. and international laws. You may not duplicate, copy, or reuse any portion of the HTML/CSS, JavaScript,
        visual design elements, or concepts without our prior express written consent.
      </p>
      <p>
        c. You hereby grant to us a non-exclusive, royalty-free, fully paid, unlimited, universal, sublicensable
        (through multiple tiers of sublicenses), perpetual, and irrevocable license, in any and all manner and media,
        whether now known or hereinafter invented or devised (including social media platforms), to use, reproduce,
        license, distribute, modify, adapt, reformat, publicly perform, publicly display, create derivative works of,
        and otherwise use the following for the purposes of operating and providing our Site, developing and improving
        our products and services, and advertising, marketing, and promoting our Site and our products and services: (i)
        any request (video, text, or otherwise) that you make or send to any Talent User, including information
        concerning any Recipient; and (ii) any submission that you make to LIVENET INC/PEN Performances, whether through
        our Site, a social media platform, third party website, or otherwise, including a reaction video, idea,
        intellectual property, publicity rights, Feedback (defined below), review, photo, video, email, text, post, or
        other communication, whether relating to you, or a third party (i) and (ii) each, individually, and
        collectively, a “Submission”). You represent and warrant that you either: (x) own all rights to any Submission;
        or (y) have all rights necessary, including with respect to any third party that contributed to, is included in,
        or is referred to, in any Submission, to grant to us the foregoing rights. You will not make any Submission that
        is confidential or proprietary or that contains or includes any information that you do not have the right to
        disclose or that you or any Recipient do not wish to be disclosed. LIVENET INC/PEN Performances will not be
        responsible or liable for any use or disclosure of a Submission, including any personal information belonging to
        you, a Recipient, or a third party.
      </p>
      <p>
        d. We may, for any reason, refuse to accept or transmit a Submission or refuse to remove a Submission from our
        Site. Further, we reserve the right to decide whether a Submission violates these Terms and may, at any time,
        without notice to you and in our sole discretion, remove your Submission, terminate your access to our Site,
        remove or ban you (and any Site account you created or control), or take other appropriate action in our sole
        discretion for violation of these Terms.
      </p>
      <p>
        e. LIVENET INC/PEN Performances desires to avoid the possibility of future misunderstandings if a project
        developed by any LIVENET INC/PEN Performances Party (as defined below) may seem similar to your Submission. If
        your Submission consists of any idea, suggestion, proposal, plan, or other material related to our business
        (individually, and collectively, “Feedback”), you acknowledge and agree that you are submitting that Feedback at
        your own risk and that LIVENET INC/PEN Performances has no obligation (including no obligation of
        confidentiality or privacy) with respect to that Feedback, and you grant to LIVENET INC/PEN Performances a
        non-exclusive, royalty-free, fully paid, unlimited, universal, sublicensable (through multiple tiers of
        sublicenses), perpetual, and irrevocable license, in any and all manner and media, whether now known or
        hereinafter invented or devised, to reproduce, license, distribute, modify, adapt, publicly perform, publicly
        display, create derivative works of (for example, translations, adaptations, or other changes), and otherwise
        use and exploit in any manner (including commercially), any and all Feedback.
      </p>
      <p>
        f. You hereby waive any and all moral rights or “droit moral” that you may have in any Submission, including
        Feedback, and you represent and warrant that no third party has any moral, “droit moral,” or other rights in
        Submission, including Feedback.
      </p>
      <p>
        9. <strong>Copyright and Intellectual Property Policy</strong> <strong />
      </p>
      <p>
        a. <u>Digital Millennium Copyright Act Notice</u>: We respond to notices of alleged copyright infringement and
        terminate access to our Site for repeat infringers. If you believe that materials on our site infringe
        copyright, please send the following information to the Copyright Agent named below:
      </p>
      <p>i. your address, telephone number, and email address;</p>
      <p>ii. a description of the work that you claim is being infringed;</p>
      <p>
        iii. a description of the material that you claim is infringing and are requesting be removed along with
        information about where it is located;
      </p>
      <p>
        iv. a statement that you have “a good faith belief that use of the material in the manner complained of is not
        authorized by the copyright owner, its agent, or the law.”;
      </p>
      <p>
        v. an electronic or physical signature of the copyright owner (or a person authorized to act for the copyright
        owner); and
      </p>
      <p>
        vi. a statement by you, made under penalty of perjury, that the information you are providing is accurate and
        that you are the copyright owner or authorized to act on behalf of the copyright owner.
      </p>
      <p>Copyright Agent:</p>
      <p>
        Baron App, Inc. d/b/a LIVENET INC/PEN Performances
        <br />
        2045 West Grand Avenue, Suite B
        <br />
        PMB 71534
        <br />
        Chicago, IL 60612-1577
        <br />
        <u>agent@PENPerformances.com</u>
      </p>
      <p>
        If you do not follow these requirements, your notice may not be valid. Please note, only notices of alleged
        copyright infringement should be sent to our Copyright Agent.
      </p>
      <p>
        b. <u>Termination Policy</u>: If we determine that you are a repeat infringer, we may terminate your access to
        our Site, remove or ban you (and any Site account you created or control), and take other appropriate action in
        our sole discretion.
      </p>
      <p>
        10. <strong>Privacy</strong>
        <strong />
      </p>
      <p>
        Your privacy is important to us. Our <u>Privacy Policy</u> explains how we collect, use, and share personal
        information and other data. By using our Site, you agree to our <u>Privacy Policy</u>.
      </p>
      <p>
        11. <strong>Third Party Content and Interactions</strong>
        <strong />
      </p>
      <p>
        a. Our Site may contain features and functionalities that link to or provide you with access to third party
        content, that is completely independent of LIVENET INC/PEN Performances, including LIVENET INC/PEN PERFORMANCES
        Videos, websites, platforms, directories, servers, networks, systems, information, databases, applications,
        software, programs, products or services, and the Internet in general. Your interactions with third parties,
        including users and Talent Users, found on or through our Site are solely between you and the third party. You
        should make whatever investigation you feel necessary or appropriate before proceeding with any contact or
        interaction, in connection with our Site or otherwise. However, you agree not to contact or interact with any
        Talent User except as expressly permitted through our Site. You also agree that LIVENET INC/PEN Performances
        may, in its sole discretion, intercede in any dispute and you will reasonably cooperate with LIVENET INC/PEN
        Performances if it does so. You acknowledge and agree that we are not responsible or liable for any damages,
        losses, costs, expenses, or liabilities of any kind or nature incurred as the result of any such interaction.
        You hereby release each LIVENET INC/PEN Performances Party (defined below) from claims, demands, and damages
        (actual and consequential) of every kind or nature, known and unknown, suspected and unsuspected, disclosed and
        undisclosed, arising out of or in any way related to such disputes or our Site. IF YOU ARE A CALIFORNIA
        RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 (AND ANY OTHER SIMILAR APPLICABLE STATE STATUTE),
        WHICH PROVIDES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW
        OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER,
        WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY&quot;.
      </p>
      <p>
        b. From time to time, an entity may be identified on a Talent User’s booking page or be associated with the
        Talent User elsewhere on our Site as a charitable organization or a recipient of funds that are being raised
        (“Charity”). For example, the Talent User’s booking page may indicate that all or a portion of the Talent User’s
        revenue from a LIVENET INC/PEN PERFORMANCES Video will be given to the Charity. Those arrangements are strictly
        between the Talent User and the Charity. LIVENET INC/PEN Performances is not a sponsor of, does not endorse, and
        is not affiliated with the Charity and is not a commercial co-venturer with respect to such arrangements (unless
        otherwise expressly stated in writing by LIVENET INC/PEN Performances). Unless expressly stated, LIVENET INC/PEN
        Performances does not control and makes no warranties about the Charity or any donation to the Charity.
      </p>
      <p>
        12. <strong>Links</strong>
        <strong />
      </p>
      <p>
        Our Site may contain links to social media platforms or third party websites. You acknowledge and agree that:
        (a) the link does not mean that we endorse or are affiliated with the platform or website; and (b) we are not
        responsible or liable for any damages, losses, costs, expenses, or liabilities related to your use of the
        platform or website. You should always read the terms and conditions and privacy policy of a platform or website
        before using it.
      </p>
      <p>
        13. <strong>Changes to our Site</strong>
        <strong />
      </p>
      <p>
        You acknowledge and agree we may change or discontinue any aspect of our Site at any time, without notice to you
      </p>
      <p>
        14. <strong>Termination and Reservation of Rights</strong>
        <strong />
      </p>
      <p>
        You may cancel your Site account at any time by contacting a member of the LIVENET INC/PEN Performances team at{' '}
        <u>hello@</u> <u>PENPerformances.com</u>. We reserve the right to terminate access to our Site to any person,
        including you, at any time, for any reason, in our sole discretion. If you violate any of these Terms, your
        permission to use our Site automatically terminates.
      </p>
      <p>
        15. <strong>Indemnification</strong>
        <strong />
      </p>
      <p>
        You agree to indemnify, defend, and hold harmless LIVENET INC/PEN Performances and its parents, subsidiaries,
        affiliates, officers, employees, directors, shareholders, agents, partners, vendors, and licensors (each, a
        “LIVENET INC/PEN Performances Party,” and collectively, “LIVENET INC/PEN Performances Parties”) from and against
        any and all claims, costs, proceedings, demands, losses, damages, and expenses (including reasonable attorneys’
        fees and costs) of any kind or nature arising from, out of, in connection with, or relating to: (a) these Terms;
        or (b) use of our Site. LIVENET INC/PEN Performances may select counsel for and control the defense of any claim
        that you are indemnifying. You will reasonably cooperate with us in connection with any claim.
      </p>
      <p>
        16. <strong>Disclaimers and Limitations on our Liability</strong> <strong />
      </p>
      <p>
        a.
        <strong>
          You acknowledge and agree that your use of our Site is at your own risk and that our Site is provided on an
          “as is” and “as available” basis. To the extent permitted by applicable law, the LIVENET INC/PEN Performances
          Parties disclaim all warranties, conditions, and representations of any kind, whether express, implied,
          statutory, or otherwise, including those related to merchantability, fitness for a particular purpose,
          non-infringement, and arising out of course of dealing or usage of trade.
        </strong>
        <strong />
      </p>
      <p>
        b.
        <strong>
          In particular, the LIVENET INC/PEN Performances Parties make no representations or warranties about the
          accuracy or completeness of content available on or through our Site or the content of any social media
          platform or third party website linked to or integrated with our Site. You acknowledge and agree that the
          LIVENET INC/PEN Performances Parties will have no liability for any: (i) errors, mistakes, or inaccuracies of
          content; (ii) personal injury, property damage, or other harm resulting from your access to or use of our
          Site; (iii) any unauthorized access to or use of our servers, any personal information, or user data; (iv) any
          interruption of transmission to or from our Site; (v) any bugs, viruses, trojan horses, or the like that may
          be transmitted on or through our Site; or (vi) any damages, losses, costs, expenses, or liabilities of any
          kind incurred as a result of any content posted or shared through our Site.
        </strong>
        <strong />
      </p>
      <p>
        c.
        <strong>
          You acknowledge and agree that any material or information downloaded or otherwise obtained through our Site,
          including any LIVENET INC/PEN PERFORMANCES Video, is done at your own risk and that you will be solely
          responsible for any damages, losses, costs, expenses, or liabilities arising from or in connection with doing
          so. No advice or information, whether oral or written, obtained by you from us or through our Site, including
          through a LIVENET INC/PEN PERFORMANCES Video, will create any warranty not expressly made by us.
        </strong>
        <strong />
      </p>
      <p>
        d.
        <strong>
          You acknowledge and agree that when using our Site, you will be exposed to content from a variety of sources,
          and that LIVENET INC/PEN Performances is not responsible for the accuracy, usefulness, safety, legality, or
          intellectual property rights of or relating to any such content. You further understand and acknowledge that
          you may be exposed to content that is inaccurate, offensive, indecent, objectionable, or harassing, and you
          agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against
          any LIVENET INC/PEN Performances Party with respect thereto.
        </strong>
        <strong />
      </p>
      <p>
        e.
        <strong>
          To the fullest extent permitted by applicable law, you acknowledge and agree that in no event will any LIVENET
          INC/PEN Performances Party be liable to you or to any third party for any indirect, special, incidental,
          punitive, or consequential damages (including for loss of profits, revenue, or data) or for the cost of
          obtaining substitute products, arising out of or in connection with these Terms, however caused, whether such
          liability arises from any claim based upon contract, warranty, tort (including negligence), strict liability,
          or otherwise, and whether or not LIVENET INC/PEN Performances has been advised of the possibility of such
          damages.
        </strong>
        <strong />
      </p>
      <p>
        f.
        <strong>
          To the maximum extent permitted by applicable law, our total cumulative liability to you or any third party
          under these Terms, including from all causes of action and all theories of liability, will be limited to and
          will not exceed the fees actually received by LIVENET INC/PEN Performances from you during the 12 months
          preceding the claim giving rise to such liability.
        </strong>
        <strong />
      </p>
      <p>
        g.
        <strong>
          Certain jurisdictions do not allow the exclusion or limitation of certain damages. If those laws apply to you,
          some or all of the above exclusions or limitations may not apply to you, and you might have additional rights.
        </strong>
        <strong />
      </p>
      <p>
        h.
        <strong>
          You agree that the limitations of damages set forth above are fundamental elements of the basis of the bargain
          between LIVENET INC/PEN Performances and you.
        </strong>
        <strong />
      </p>
      <p>
        17. <strong>Arbitration Agreement and Waiver of Certain Rights</strong> <strong />
      </p>
      <p>
        a. <u>Arbitration</u>: You and LIVENET INC/PEN Performances agree to resolve any disputes between you and
        LIVENET INC/PEN Performances through binding and final arbitration instead of through court proceedings. You and
        LIVENET INC/PEN Performances each hereby waive any right to a jury trial of any controversy, claim,
        counterclaim, or other dispute arising between you and LIVENET INC/PEN Performances relating to these Terms or
        our Site (each a “Claim,” and collectively, “Claims”). Any Claim will be submitted for binding arbitration in
        accordance with the Rules of the American Arbitration Association (“AAA Rules”). The arbitration will be heard
        and determined by a single arbitrator. The arbitrator’s decision will be in writing, will include the
        arbitrator’s reasons for the decision, will be final and binding upon the parties, and may be enforced in any
        court of competent jurisdiction. The parties agree that the arbitration will be kept confidential and that the
        existence of the proceeding and any element of it (including any pleadings, briefs, or other documents submitted
        or exchanged, any testimony or other oral submissions, and awards) will not be disclosed beyond the arbitration
        proceedings, except as may lawfully be required in judicial proceedings relating to the arbitration or by
        applicable disclosure rules and regulations of securities regulatory authorities or other governmental agencies.
      </p>
      <p>
        b. <u>Costs and Fees</u>: If you demonstrate that the costs of arbitration will be prohibitive as compared to
        the costs of litigation, LIVENET INC/PEN Performances will pay as much of the administrative costs and
        arbitrator’s fees required for the arbitration as the arbitrator deems necessary to prevent the cost of the
        arbitration from being prohibitive. In the final award, the arbitrator may apportion the costs of arbitration
        and the compensation of the arbitrator among the parties in such amounts as the arbitrator deems appropriate.
      </p>
      <p>
        c. <u>No Preclusions</u>: This arbitration agreement does not preclude you or LIVENET INC/PEN Performances from
        seeking action by federal, state, or local government agencies. You and LIVENET INC/PEN Performances each also
        have the right to bring any qualifying Claim in small claims court. In addition, you and LIVENET INC/PEN
        Performances each retain the right to apply to any court of competent jurisdiction for provisional relief,
        including pre-arbitral attachments or preliminary injunctions, and any such request will not be deemed to be
        either incompatible with these Terms or a waiver of the right to have disputes submitted to arbitration as
        provided in these Terms.
      </p>
      <p>
        d. <u>No Class Representative or Private Attorney General</u>: You and LIVENET INC/PEN Performances each agree
        that with respect to any Claim, neither may: (i) act as a class representative or private attorney general, or
        (ii) participate as a member of a class of claimants. You agree that no Claim may be arbitrated on a class or
        representative basis. The arbitrator can decide only individual Claims (whether brought by you or LIVENET
        INC/PEN Performances). The arbitrator may not consolidate or join the claims of other persons or parties who may
        be similarly situated.
      </p>
      <p>
        e. <u>Severability/No Waiver/Survival</u>: If any provision of this <u>Section 17</u> is found to be invalid or
        unenforceable, that provision will be deemed appropriately modified to give effect to the intent of the
        provision or, if modification is not possible, will be severed and the remainder of this <u>Section 17</u> will
        continue in full force and effect. No waiver of any provision of this <u>Section 17</u> will be effective or
        enforceable unless recorded in a writing signed by the party waiving such a right or requirement. Such a waiver
        will not waive or affect any other provision of these Terms. This <u>Section 17</u> will survive the termination
        of your relationship with LIVENET INC/PEN Performances.
      </p>
      <p>
        f. <u>30-Day Opt-Out Right</u>: You have the right to opt out of the provisions of this Arbitration Agreement by
        sending, within 30 days after first becoming subject to this Arbitration Agreement, written notice of your
        decision to opt out to the following address: LIVENET INC/PEN Performances, c/o Legal Department, 400 N.
        Aberdeen Street, Chicago, IL 60642. Your notice must include your name and address, any usernames, each email
        address you have used to submit your LIVENET INC/PEN PERFORMANCES Video requests or set up an account on our
        Site (if applicable), and an unequivocal statement that you want to opt out of this Arbitration Agreement. You
        agree that if you opt out of this Arbitration Agreement, all other parts of these Terms will continue to apply
        to you. Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may
        currently have, or may enter in the future, with us.
      </p>
      <p>
        g.{' '}
        <strong>
          <u>LIMITATIONS</u>
        </strong>
        <strong>: This </strong>{' '}
        <strong>
          <u>Section 17</u>{' '}
        </strong>
        <strong>
          limits certain rights, including the right to maintain certain court actions, the right to a jury trial, the
          right to participate in any form of class or representative claim, the right to engage in discovery except as
          provided in AAA rules, and the right to certain remedies and forms of relief. In addition, other rights that
          you or LIVENET INC/PEN Performances would have in court may not be available in arbitration.
        </strong>
        <strong />
      </p>
      <p>
        18. <strong>Other Provisions</strong>
        <strong />
      </p>
      <p>
        a. <u>Force Majeure</u>: Under no circumstances will any LIVENET INC/PEN Performances Party be liable for any
        delay or failure in performance due in whole or in part to any acts of God (such as earthquakes, storms, floods,
        etc.), unavoidable accidents, laws, rules, regulations or orders of government authorities, acts of war
        (declared or not), terrorism, hostilities, blockades, civil disturbances, embargoes, strikes, or any other event
        or cause beyond the reasonable control of any LIVENET INC/PEN Performances Party.
      </p>
      <p>
        b. <u>Choice of Law and Jurisdiction</u>: These Terms will be governed by and construed in accordance with the
        laws of the State of Illinois, without giving effect to any conflict of laws rules or provisions. You agree that
        any action of whatever nature arising from or relating to these Terms or our Site will be filed only in the
        state or federal courts located in Chicago, Illinois. You consent and submit to the personal jurisdiction of
        such courts for the purposes of any such action.
      </p>
      <p>
        c. <u>Severability</u>: If any provision of these Terms is found to be invalid or unenforceable, that provision
        will be deemed appropriately modified to give effect to the intent of the provision or, if modification is not
        possible, will be severed from these Terms and will not affect the enforceability of any other provision.
      </p>
      <p>
        d. <u>No Waiver or Amendment</u>: The failure by LIVENET INC/PEN Performances to enforce any right or provision
        of these Terms will not prevent LIVENET INC/PEN Performances from enforcing such right or provision in the
        future and will not be deemed to modify these Terms.
      </p>
      <p>
        e. <u>Assignment</u>: LIVENET INC/PEN Performances may, at any time, assign its rights and obligations under
        these Terms, including to an affiliated entity or in connection with a sale of assets, merger, acquisition,
        reorganization, bankruptcy, other transaction, or by operation of law.
      </p>
      <p>
        f. <u>Miscellaneous</u>: The term “including” in these Terms will be interpreted broadly and will mean
        “including, without limitation.” Titles are for convenience only and will not be considered when interpreting
        these Terms.
      </p>
      <p>
        19. <strong>Changes to these Terms</strong>
        <strong />
      </p>
      <p>
        We may change these Terms. If we do, we will post the revised Terms on our Site and update the “Last Updated”
        date at the top of these Terms. The revised Terms will be effective immediately if you accept them (for example,
        by agreeing when you create an account or login to an existing account, or using or continuing to use our Site
        after the revised Terms have been posted); otherwise, they will be effective 30 days after posting.
      </p>
      <br />
      <br />
      <p>
        <strong>Additional Site Terms</strong>
        <strong />
      </p>
      <p>
        <strong>
          PLEASE READ THE FOLLOWING CAREFULLY. By using the product or service, you agree to the applicable additional
          terms that apply (which are Additional Terms as described in Section
        </strong>
        <strong>
          <u>5</u>
        </strong>
        <strong>
          of the Site Terms of Service (“Site Terms”)). These Additional Terms will be governed by, and are incorporated
          into, the Site Terms. Terms that are defined in the Site Terms will have the same meaning in these Additional
          Terms.
        </strong>
      </p>
      <p>
        From time to time, we may change these Additional Terms. If we do, we will give you notice by posting them on
        our Site and updating the “Last Updated” date. The revised Additional Terms will be effective immediately. By
        using or continuing to use this product or service, you represent and warrant that you have read, understand,
        and agree to these Additional Terms.
      </p>
      <p>
        <strong>LIVENET INC/PEN PERFORMANCES FAN CLUB - BETA</strong>
      </p>
      <p>Last Updated: May 5, 2021</p>
      <p>
        1. <strong>Fan Club Membership</strong>
      </p>
      <p>
        You may join a Fan Club from the Talent User’s profile page on our website or App; currently, you must download
        and use the App to participate.
      </p>
      <p>a. When you join a Fan Club:</p>
      <p>
        i. You may view messages and other content (each such message and other content, a “Message”) that the Talent
        User posts; you may also reply to or “like” a Message (each such reply, like, or other content is also a
        “Message”).
      </p>
      <p>
        ii. Your user profile page will be visible as described in <u>Section 3.a</u>.
      </p>
      <p>
        iii. You will be eligible for standard Fan Club benefits, as described on the Fan Club page or the Talent User’s
        profile page.
      </p>
      <p>
        iv. Membership is free, but you may also subscribe to any available Paid Features (described in{' '}
        <u>Section 2.a</u>).
      </p>
      <p>b. Leaving a Fan Club: You may end your membership in any Fan Club for any reason at any time:</p>
      <p>
        i. By email, contact <u>hello@PENPerformances.com</u> and provide your username, the email address associated
        with your account, and the name of the Fan Club.
      </p>
      <p>ii. From the App or website:</p>
      <p>
        A. on the Talent User’s profile page, select the icon that says “Joined” in the upper-right hand corner, then
        select “Leave Fan Club”; or
      </p>
      <p>
        B. in the App, in the Talent User’s Fan Club, select the three dots in the upper-right hand corner, then select
        “Leave Fan Club”.
      </p>
      <p>
        <strong>Leaving a Fan Club will not cancel any subscription to a Paid Feature for that Fan Club.</strong>
      </p>
      <p>
        2. <strong>Paid Features</strong>
      </p>
      <p>
        As and when made available by LIVENET INC/PEN Performances, a Talent User may offer Fan Club members the
        opportunity to subscribe to other features (for example, VIP Subscriptions), as described on the Talent User’s
        profile page (each, a “Paid Feature”).
      </p>
      <p>
        a. <u>Payment</u>. You may subscribe to a Paid Feature for the price displayed on the Talent User’s profile
        page. By subscribing to a Paid Feature, you agree to pay all amounts due in accordance with the payment terms in
        effect at that time.
        <strong>
          Your payment card will be charged at the time you subscribe to each Paid Feature, and monthly thereafter.
        </strong>
      </p>
      <p>
        b. <u>Renewal</u>. Your subscription to each Paid Feature will automatically renew at the end of the applicable
        subscription period and you will be charged a subscription fee for each renewal automatically on a monthly
        recurring basis. Subscription fees are non-refundable, including for any partial subscription periods.
      </p>
      <p>
        c. <u>Cancellation</u>. You may cancel your subscription to any Paid Feature for any reason at any time.
      </p>
      <p>
        i. By email, contact <u>hello@</u> <u>PENPerformances.com</u>, and provide your username, the email address
        associated with your account, and the name of the Fan Club and the Paid Feature. You must contact us to cancel
        at least one day before the then-current subscription period ends to avoid being charged again.
      </p>
      <p>ii. From the App or LIVENET INC/PEN Performances website:</p>
      <p>
        A. on the Talent User’s profile page, click the VIP banner in the upper-right hand corner, select “View VIP
        Membership”, then select “Cancel VIP Membership”.
      </p>
      <p>
        B. in the App, in the Talent User’s Fan Club, select the three dots in the upper-right hand corner, then select
        “Cancel VIP Membership”.
      </p>
      <p>
        <strong>Deleting the App from your device will not cancel your subscription to a Paid Feature.</strong>
      </p>
      <p>
        3. <strong>Acknowledgement</strong>
      </p>
      <p>You acknowledge and agree that:</p>
      <p>
        a. the Talent User and other Users who subscribe to a Fan Club will see your profile page, Messages, and
        username; therefore, such information will not be private and you should not share or disclose any personal,
        private, or sensitive information relating to you or a third party;
      </p>
      <p>
        b. you hereby grant to each other User (subject to such User’s payment in full) and to the Talent User the
        following limited rights to use your Messages
        <strong>solely for their own personal, non-commercial, and non-promotional purposes</strong>, subject to these
        Additional Terms: a non-exclusive, royalty-free, fully paid, worldwide, sublicensable, revocable license to use,
        reproduce, distribute, and publicly display that Message
        <strong>
          only on social media platforms and personal websites; in text and email messages; and in other personal
          storage media
        </strong>
        ; and, (subject to your payment in full) you may use Messages created by other Users and the Talent User in the
        same manner; in addition, you hereby grant to the Talent User the right to use your Messages to reasonably
        promote their participation on our Site;
      </p>
      <p>c. by subscribing to any Paid Feature, you agree to the terms applicable to that feature;</p>
      <p>
        d. each Message you create is a Submission under the <u>Site Terms</u>;
      </p>
      <p>
        e. each Message is subject to the <u>Acceptable Use Policy/Community Guidelines</u> and you may not, among other
        things:
      </p>
      <p>
        i. violate, infringe, or misappropriate the intellectual property, privacy, publicity, moral or “droit moral,”
        or other legal rights of any third party; or
      </p>
      <p>
        ii. communicate anything that is, or that incites or encourages action that is, explicitly or implicitly
        illegal, abusive, harassing, threatening, hateful, racist, derogatory, harmful to any reputation, pornographic,
        indecent, profane, obscene, or otherwise objectionable;
      </p>
      <p>
        f. if you believe that a User, Talent User, or Fan Club activity has violated the{' '}
        <u>Acceptable Use Policy/Community Guidelines</u>, you will promptly notify LIVENET INC/PEN Performances in
        writing at <u>hello@PENPerformances.com</u>LIVENET INC/PEN Performances may monitor any Fan Club activity and
        Messages, and LIVENET INC/PEN Performances retains the right, in its sole discretion, to cancel any Fan Club
        membership; and
      </p>
      <p>
        g. LIVENET INC/PEN Performances will not be responsible or liable for any Submission (including any Message from
        a User) or any Talent Content (including any Message or other content from a Talent User).
      </p>
      <p>
        <strong>LIVENET INC/PEN PERFORMANCES LIVE AND GAMING ADDITIONAL SITE TERMS</strong>
      </p>
      <p>Last Updated: November 18, 2020</p>
      <p>
        1. <strong>LIVENET INC/PEN Performances Live and Gaming Events</strong>
      </p>
      <p>
        A Talent User may offer you the opportunity to participate in a LIVENET INC/PEN Performances Live and LIVENET
        INC/PEN Performances Gaming activity involving audio, video, or both (each, an “Event”).
      </p>
      <p>a. The details of the Event will be specified on the Event booking page on the Site, including:</p>
      <p>i. the approximate length;</p>
      <p>
        ii. any limits on the number of or criteria for each User and other individuals included by a User who can
        participate in the Event (collectively, “Participants”), for example, a maximum number of Participants per Event
        or per login/video screen;
      </p>
      <p>iii. any booking fee;</p>
      <p>iv. scheduling details (for example, Talent User’s availability, specific dates and times, etc.);</p>
      <p>v. the hosting or other platform specified by LIVENET INC/PEN Performances; and</p>
      <p>vi. any other limitations, restrictions, or information.</p>
      <p>
        b. You acknowledge that each Event is subject to change in the date or details, or cancellation, in LIVENET
        INC/PEN Performances’s sole discretion.
      </p>
      <p>
        c. You may not cancel, reschedule, substitute, return, or exchange an Event after booking and no refunds will be
        issued.
      </p>
      <p>
        d. Unlike a LIVENET INC/PEN Performances Video, you may not record, use, publish, reproduce, distribute,
        display, post, or share any portion of the Event
      </p>
      <p>
        e. You will use any communication (for example, Discord, Zoom, LIVENET INC/PEN Performances platform, etc.),
        calendar (for example, Calendly, LIVENET INC/PEN Performances scheduling tool, etc.), or other platform,
        software, or app designated by LIVENET INC/PEN Performances for the Event and will comply with any applicable
        terms and privacy policies
        <strong>
          Please note that some platforms, software, or apps may require that you create your own account on the
          platform.
        </strong>
        You will use any login or other information provided by LIVENET INC/PEN Performances only to participate in the
        Event.
      </p>
      <p>
        f. You may end your participation in the Event at any time. LIVENET INC/PEN Performances or the Talent User may
        end the Event at any time. If the Event is ended due to a violation of the <u>Acceptable Use Policy</u>, you
        will promptly notify LIVENET INC/PEN Performances in writing at <u>hello@</u> <u>PENPerformances.com</u>.
      </p>
      <p>
        2. <strong>Payment</strong>
      </p>
      <p>
        You may book an Event by paying in full using a valid payment card through the applicable third party payment
        provider we select as set forth in the<u>Site Terms.</u>.{' '}
        <strong>Your payment card will be charged at the time of booking.</strong>
        If an Event does not take place, LIVENET INC/PEN Performances will refund the booking fee.
      </p>
      <p>
        3. <strong>Acknowledgement</strong>
      </p>
      <p>You acknowledge and agree, and will notify each Participant that you include, that</p>
      <p>
        a. any submission made by you or any Participant you include in connection with the Event (including any chat,
        comment, audio, image, video, idea, intellectual property, publicity rights, Feedback, review, photo, email,
        text, post, element, or other communication) (collectively, “Event Submission”) is a Submission under the Site
        Terms;
      </p>
      <p>
        b. Event Submissions will not be private and you and any Participant you include should not share or disclose
        any personal, private, or sensitive information relating to you or a third party;
      </p>
      <p>
        c. in addition to the <u>Site Terms,</u> each Event Submission is subject to the <u>Acceptable Use Policy</u>{' '}
        and may not, among other things:
      </p>
      <p>
        i. violate, infringe, or misappropriate the intellectual property, privacy, publicity, moral or “droit moral,”
        or other legal rights of any third party; or
      </p>
      <p>
        ii. communicate anything that is, or that incites or encourages action that is, explicitly or implicitly:
        illegal, abusive, harassing, threatening, hateful, racist, derogatory, harmful to any reputation, pornographic,
        indecent, profane, obscene, or otherwise objectionable (including nudity);
      </p>
      <p>
        d. in addition to the rights you grant under the <u>Site Terms</u>, you and any Participant that you include
        grant to LIVENET INC/PEN Performances the exclusive (including exclusive as to you), royalty-free, fully paid,
        unlimited, universal, sublicensable (through multiple tiers of sublicenses), irrevocable license to use,
        publish, reproduce, record, modify, edit, and display your name, image, voice, appearance, performance,
        likeness, and Event Submission in connection with the Event, including advertising, marketing, promoting,
        republishing, rebroadcasting, and re-airing the Event;
      </p>
      <p>
        e. LIVENET INC/PEN Performances may record the Event and an Event Submission may be edited or modified (for
        example, add music or captions, capture screenshot or excerpts, or otherwise) and each Event Submission (for
        example, an edited or modified version thereof) may be included in a compilation with content from or featuring
        others;
      </p>
      <p>
        f. each Event is subject to the <u>Privacy Policy</u>
      </p>
      <p>
        g. if you believe that a Participant or an Event activity violates the <u>Acceptable Use Policy</u>, you will
        promptly notify LIVENET INC/PEN Performances in writing at <u>hello@</u> <u>PENPerformances.com</u>;
      </p>
      <p>
        h. LIVENET INC/PEN Performances may monitor any Event, and LIVENET INC/PEN Performances retains the right, in
        its sole discretion, to cancel any Event or block any User; and
      </p>
      <p>
        i. LIVENET INC/PEN Performances will not be responsible or liable for any Event Submission or any Talent Content
        (including from another Participant or Talent User).
      </p>
      <p>
        4. <strong>Indemnification</strong>
      </p>
      <p>
        In addition to the indemnification in Section 15 of the <u>Site Terms,</u>
        you also agree to indemnify, defend, and hold harmless the LIVENET INC/PEN Performances Parties from and against
        any and all claims, costs, proceedings, demands, losses, damages, and expenses (including reasonable attorneys’
        fees and costs) of any kind or nature, including any arising from, out of, in connection with, or relating to:
        (a) the Event; (b) these Additional Terms; (c) any Participant; or (d) use of our Site.
      </p>
      <p>
        <strong>LIVENET INC/PEN PERFORMANCES DIRECT</strong>
      </p>
      <p>Last Updated: August 13, 2020</p>
      <p>
        1. <strong>LIVENET INC/PEN PERFORMANCES DIRECT</strong>
      </p>
      <p>
        A Talent User may offer you the opportunity to send a paid direct message to that Talent User as permitted
        through the LIVENET INC/PEN Performances Direct feature in the App (if the feature is available), as indicated
        on the Talent User’s profile page.
      </p>
      <p>a. You may send:</p>
      <p>
        i. a User-created message (for example, a message of up to a specified number of characters, a video file of up
        to specified size, etc.) (each such message, and any response sent through the LIVENET INC/PEN Performances
        Direct feature, a “Message”); or
      </p>
      <p>
        ii. a sticker from among a collection of images made available by LIVENET INC/PEN Performances (each such image,
        a “Sticker”).
      </p>
      <p>
        b. After you send a Message or Sticker, the Talent User may, but has no obligation to, respond. In addition, the
        Talent User may close out the thread at any time.
      </p>
      <p>c. To send another Message or Sticker, you must pay the applicable price.</p>
      <p>d. You may not return or exchange a purchased Message or Sticker and no refunds will be issued.</p>
      <p>
        2. <strong>Fees and Payment</strong>
      </p>
      <p>
        If a Talent User offers the LIVENET INC/PEN Performances Direct feature, the price for each Message is displayed
        on the Talent User’s profile page and on the purchase button in the App when you send the Message. The price for
        each Sticker is displayed in the LIVENET INC/PEN Performances Direct feature. You agree to pay all amounts due
        in accordance with the payment terms in effect at that time.
      </p>
      <p>
        3. <strong>Acknowledgement</strong>
      </p>
      <p>You acknowledge and agree that:</p>
      <p>
        a. each Message you create is a Submission under the <u>Site Terms</u>
      </p>
      <p>
        b. each Message (whether created by you or the Talent User) is subject to the <u>Acceptable Use Policy</u>
      </p>
      <p>
        c. each Message from a Talent User is owned by the Talent User who created it, and subject to your payment in
        full, you have the right to use it{' '}
        <strong>solely for your own personal, non-commercial, and non-promotional use </strong>
        subject to these Terms: a non-exclusive, royalty-free, fully paid, worldwide, sublicensable, revocable license
        to use, reproduce, distribute, and publicly display that Message, in any and all media (for example, on social
        media platforms), whether now known or hereafter invented or devised; and
      </p>
      <p>
        d. LIVENET INC/PEN Performances will not be liable or responsible for any Submission (including any Message or
        Sticker you send) or any Talent Content (including any Message from the Talent User).
      </p>
      <p>
        <strong>LIVENET INC/PEN PERFORMANCES CALLS ADDITIONAL SITE TERMS</strong>
      </p>
      <p>Last Updated: August 13, 2021</p>
      <p>
        1. <strong>LIVENET INC/PEN PERFORMANCES </strong>
      </p>
      <p>
        A Talent User may offer you the opportunity to participate in a LIVENET INC/PEN Performances real-time video
        performance (each, a “PEN Performance”) using the App.
      </p>
      <p>a. The details of a PEN Performance will be specified on the Call booking page on the App, including:</p>
      <p>i. the approximate length;</p>
      <p>
        ii. any limits on the number of or criteria for you and other individuals included by you who can participate in
        the Call (collectively, “Participants”), for example, a maximum number of Participants per PEN Performance;
      </p>
      <p>iii. any participation criteria;</p>
      <p>iv. any booking fee; and</p>
      <p>v. any other conditions, limitations, restrictions, or information.</p>
      <p>
        b. You will inform other Participants of these Additional Terms as they will be subject to these Additional
        Terms as Participants.
      </p>
      <p>
        c. You acknowledge that the Talent User has the sole discretion whether or not to participate in a PEN
        Performance.
      </p>
      <p>
        d. You acknowledge that each Call is subject to change in the date or details, or cancellation, in LIVENET
        INC/PEN Performances’s sole discretion.
      </p>
      <p>e. You may not cancel, reschedule, substitute, or exchange a Call after booking.</p>
      <p>
        f. You may end your participation in a C PEN Performance at any time. LIVENET INC/PEN Performances or the Talent
        User may end a Call at any time. If a Call is ended due to another’s violation of the{' '}
        <u>Acceptable Use Policy/Community Guidelines</u>, you will promptly notify LIVENET INC/PEN Performances in
        writing at <u>hello@PEN Performances.com</u>.
      </p>
      <p>
        2. <strong>Payment</strong>
      </p>
      <p>
        a. You may book a Call by paying in full using a valid payment card through the applicable third party payment
        provider we select as set forth in the Site Terms.
      </p>
      <p>
        b.
        <strong> Your payment card will be charged at the time of booking the </strong> PEN Performance
        <strong>
          and is not refundable (unless the Call does not take place because it was cancelled by LIVENET INC/PEN
          Performances or the Talent User).
        </strong>
      </p>
      <p>
        3. <strong>Acknowledgement</strong>
      </p>
      <p>You acknowledge and agree, and will notify other Participants, that:</p>
      <p>
        a. LIVENET INC/PEN Performances may take a photo of you, other Participants and the Talent User during a PEN
        Performance (“Performance Photo”), which will be made available to you at the end of that Call;
      </p>
      <p>
        b. any submission made by you or other Participants in connection with the Call (including any chat, comment,
        audio, image, video, idea, intellectual property, publicity rights, Feedback, review, Performance Photo and any
        other photo, email, text, post, element, or other communication) is a Submission under the Site Terms;
      </p>
      <p>
        c. Submissions will not be private and you and other Participants should not share or disclose any personal,
        private, or sensitive information relating to you or a third party;
      </p>
      <p>
        d. you{' '}
        <strong>
          <u>may not</u>
        </strong>{' '}
        PEN Performance in any way;
      </p>
      <p>
        e. you and other Participants may use the PEN Performance
        <strong>
          solely for your and other Participant’s own personal, non-commercial, and non-promotional purposes
        </strong>
        . Any such use is (subject to these Additional Terms): a non-exclusive, royalty-free, fully paid, worldwide,
        sublicensable, revocable license to use, reproduce, distribute, and publicly display your Call Photo
        <strong>
          only on social media platforms and personal websites; in text and email messages; and in other personal
          storage media
        </strong>
        ;
      </p>
      <p>
        f. in addition to the Site Terms, each Submission is subject to the{' '}
        <u>Acceptable Use Policy/Community Guidelines</u> and may not, among other things:
      </p>
      <p>
        i. violate, infringe, or misappropriate the intellectual property, privacy, publicity, moral or “droit moral,”
        or other legal rights of any third party; or
      </p>
      <p>
        ii. communicate anything that is, or that incites, promotes, facilitates or encourages action that is,
        explicitly or implicitly: offensive, dangerous, gratuitously violent, defamatory, illegal (including illegal
        gambling, games of chance, sweepstakes, or contests), a violation of law or the rights of any third party,
        abusive, harassing, threatening, hateful, racist, derogatory, harmful to any reputation, pornographic, indecent,
        profane, obscene, or otherwise objectionable (including nudity);
      </p>
      <p>
        g. you and other Participants grant to the Talent User the following limited rights to use the Call Photo
        <strong>solely for their own personal, non-commercial, and non-promotional purposes</strong>
        (subject to these Additional Terms): a non-exclusive, royalty-free, fully paid, worldwide, sublicensable,
        revocable license to use, reproduce, distribute, and publicly display your Call Photo
        <strong>
          only on social media platforms and personal websites; in text and email messages; and in other personal
          storage media
        </strong>
        ;
      </p>
      <p>
        h. in addition to the rights you grant under the Site Terms, in connection with each Call you and other
        Participants grant to LIVENET INC/PEN Performances:
      </p>
      <p>
        i. an exclusive (including exclusive as to you, but excluding the license granted to any platform or media as
        set out below), royalty-free, fully paid, unlimited, universal, sublicensable (through multiple tiers of
        sublicenses), perpetual, and irrevocable license in any and all manner and media, whether now known or
        hereinafter invented or devised to use, publish, reproduce, record, modify, edit, and display your name, image,
        voice, appearance, performance, likeness, and Submission, including in or in connection with advertising,
        marketing, promoting, republishing, rebroadcasting, and re-airing the Call and the Call Photo;
      </p>
      <p>
        ii. to any platform or media on which your Submission is stored, performed, recorded, republished, rebroadcast,
        re-aired, or displayed any rights necessary or required by the platform or media in order to store, perform,
        record, republish, rebroadcast, re-air, or display your Submission; and
      </p>
      <p>
        iii. the right to record the Call and Call Photo and to edit or modify them and your Submission (for example,
        add music or captions, capture screenshot or excerpts, or otherwise). The recordings and your Submission (for
        example, an edited or modified version thereof) may be included in a compilation with content from or featuring
        others;
      </p>
      <p>
        i. if you believe that another Participant, Talent or a Call activity has violated the{' '}
        <u>Acceptable Use Policy/Community Guidelines</u>, you will promptly notify LIVENET INC/PEN Performances in
        writing at <u>hello@LIVENET INC/PEN Performances.com</u>;
      </p>
      <p>
        j. LIVENET INC/PEN Performances may monitor any Call, and LIVENET INC/PEN Performances retains the right, in its
        sole discretion, to cancel any Call or block any User; and
      </p>
      <p>
        k. LIVENET INC/PEN Performances will not be responsible or liable for any Submission or Talent Content
        (including from another Participant or Talent User).
      </p>
      <p>
        4. <strong>Indemnification</strong>
      </p>
      <p>
        In addition to the indemnification in Section 15 of the Site Terms, you also agree to indemnify, defend, and
        hold harmless the LIVENET INC/PEN Performances Parties from and against any and all claims, costs, proceedings,
        demands, losses, damages, and expenses (including reasonable attorneys’ fees and costs) of any kind or nature,
        arising from, out of, in connection with, or relating to: (a) the Call; (b) the Call Photo; (c) other
        Participants; (d) these Additional Terms; or (e) use of our Site.
      </p>
      <p>
        <strong>ADDITIONAL SITE TERMS FOR POLITICAL LIVENET INC/PEN PERFORMANCES PRODUCTS</strong>
      </p>
      <p>Last Updated: August 24, 2020</p>
      <p>
        These terms are “Additional Terms” under LIVENET INC/PEN Performances’s<u>Site Terms of Service</u> (“Site
        Terms,” together with the<u>Acceptable Use Policy</u> (“Acceptable Use Policy”), <u>Talent Terms of Service</u>,
        including Additional Terms, each as modified from time to time, collectively, <u>“Terms of Service”</u>). Except
        as set out below each Political LIVENET INC/PEN Performances Product (defined below) will be subject to the
        Terms of Service, and, in particular, each Political LIVENET INC/PEN Performances Live Event (defined below)
        will be subject to the <u>Additional LIVENET INC/PEN Performances Live Site Terms</u>. Terms that are defined in
        the Terms of Service will have the same meaning in these Additional Terms. To the extent that these Additional
        Terms conflict with any other Terms of Service, these Additional Terms will govern with respect to the matters
        covered herein.
      </p>
      <p>
        1. <strong>Political LIVENET INC/PEN Performances Products.</strong>
      </p>
      <p>
        a. A Talent User may offer you the opportunity to request LIVENET INC/PEN Performances products or services
        intended to benefit a candidate, campaign, party, committee, platform, or partisan political cause (each, a
        “Political Committee,” and such products and services, collectively, “Political LIVENET INC/PEN Performances
        Products”), specifically and solely including a LIVENET INC/PEN Performances Video (each, a “Political LIVENET
        INC/PEN Performances Video”) or a LIVENET INC/PEN Performances Live Event (each, a “Political LIVENET INC/PEN
        Performances Live Event”).
      </p>
      <p>
        b. Because you will be making a non-refundable contribution to the Political Committee, you must meet all
        applicable requirements for such contributions, including:
      </p>
      <p>i. being a United States citizen or a lawful permanent resident of the United States; and</p>
      <p>
        ii. meeting any other requirements established by the Political Committee, as set forth on the website of the
        Political Committee’s third-party payment processor.
      </p>
      <p>c. You acknowledge and agree that:</p>
      <p>
        i. LIVENET INC/PEN Performances may discontinue offering any Political LIVENET INC/PEN Performances Product at
        any time, for any reason, in its sole discretion; and
      </p>
      <p>ii. each LIVENET INC/PEN Performances Political Product is excluded from:</p>
      <p>A. any promotional offer or promo code; and</p>
      <p>B. the Customer Referral Program.</p>
      <p>
        2. <strong>Submissions.</strong>
      </p>
      <p>
        a. Any materials or content you submit in connection with booking a LIVENET INC/PEN Performances Political
        Product is a “Submission” under the <u>Site Terms</u>.
      </p>
      <p>
        b. You are responsible for your Submission. In addition to the<u>Site Terms</u>, each Submission is subject to
        the <u>Acceptable Use Policy</u> and may not, among other things:
      </p>
      <p>
        i. provide any false, deceptive, or misleading information, including as to your identity, the identity of any
        Recipient, and the identity of anyone mentioned in your Submission;
      </p>
      <p>
        ii. attempt to deceive, mislead, or induce the Talent User into making any false, misleading, defamatory, or
        untrue statements;
      </p>
      <p>iii. impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity;</p>
      <p>
        iv. violate, infringe, or misappropriate the intellectual property, privacy, publicity, moral or “droit moral,”
        or other legal rights of any third party; or
      </p>
      <p>
        v. communicate anything that is, or that incites or encourages action that is, explicitly or implicitly illegal,
        abusive, harassing, intimidating, threatening, violent, hateful, racist, disparaging, defamatory, derogatory,
        harmful to any reputation, pornographic, indecent, profane, obscene, or otherwise objectionable (including
        nudity).
      </p>
      <p>
        3. <strong>Payment.</strong>
      </p>
      <p>You acknowledge and agree that your payment for a Political LIVENET INC/PEN Performances Product:</p>
      <p>
        a. is a{' '}
        <strong>
          <u>non-refundable</u>
        </strong>{' '}
        contribution to the Political Committee, even if your request is not fulfilled;
      </p>
      <p>
        b. is not a payment to LIVENET INC/PEN Performances, but is instead a contribution to the Political Committee;
        and
      </p>
      <p>
        c. will be made through the third-party payment provider designated by the Political Committee to receive
        contributions.
      </p>
      <p>
        <strong>TEXT MESSAGING AND DISCLOSURES</strong>
      </p>
      <p>Effective date: November 24, 2020</p>
      <p>
        LIVENET INC/PEN Performances offers various text messaging programs, which may include recurring, occasional,
        one-time, or limited-duration text messages.
      </p>
      <p>
        Our text messaging programs are open only to legal residents of the United States who are 13 years of age or
        older. Your participation in a LIVENET INC/PEN Performances text messaging program is subject to our{' '}
        <u>Site Terms of Service</u> (including, without limitation, the arbitration agreement and class action waiver)
        and <u>Privacy Policy</u>. If there is any inconsistency between the Site Terms and these Additional Terms,
        these Additional Terms will apply.
      </p>
      <p>
        You may sign up for a text messaging program through our website or by otherwise providing your consent. You may
        need to confirm your consent by replying to an initial text message sent to you by LIVENET INC/PEN Performances.
        The text messaging program may relate specifically to a purchase you have made or to a specific LIVENET INC/PEN
        Performances promotion. By signing up, you agree to receive text messages from LIVENET INC/PEN Performances
        related to that program, such as updates on a purchase, offers, deals, alerts, reminders, or other pertinent
        information.
      </p>
      <p>
        Message and data rates may apply. Messages may be in SMS or MMS format and may be sent using an automatic
        telephone dialing system. Consent to receive text messages is not required as a condition of purchase. To stop
        transactional text messages from LIVENET INC/PEN Performances, reply STOP to message received; to stop
        promotional text messages, text STOP TO 22636. If you reply or text STOP, you may receive a text message
        confirmation.
      </p>
      <p>
        For help or additional information, email us at: <u>hello@LIVENET INC/PEN Performances.com</u>.
      </p>
      <p>
        If you change or deactivate your mobile number, you must promptly notify LIVENET INC/PEN Performances by email
        at <u>hello@LIVENET INC/PEN Performances.com</u> to have your number removed.
      </p>
      <p>
        <strong>
          Supported mobile operators: Certain mobile operators do not support text messaging services. If your mobile
          operator does not, you will not be able to receive text messages from LIVENET INC/PEN Performances. In
          addition, pre-paid users may not be able to participate, so you should check with your mobile service
          provider. Neither LIVENET INC/PEN Performances nor a mobile operator is liable for delayed or undelivered
          messages.
        </strong>
      </p>
      <p>
        1. <strong>No Implied Warranties</strong>
      </p>
      <p>
        A Gift Card is not a credit, debit, or a charge card. To the extent permitted by applicable law, LIVENET INC/PEN
        Performances disclaims all warranties, conditions, and representations of any kind with respect to any Gift Card
        or the balance of any Gift Card, whether express, implied, statutory, or otherwise, including those related to
        merchantability, fitness for a particular purpose, non-infringement, and arising out of course of dealing or
        usage of trade. To the fullest extent permitted by applicable law, in the event a valid Gift Card is non-
        functional, the sole remedy, and the sole liability of Issuer will be the replacement of that Gift Card.
      </p>
      <p>
        2. <strong>Other Terms</strong>
      </p>
      <p>
        a. <u>No Expiration.</u> While a Gift Card and its balance do not expire, the Gift Card will be deactivated when
        the balance is reduced to zero. LIVENET INC/PEN Performances does not guarantee that Gift Cards or their value
        will be available at all times or at any given time. LIVENET INC/PEN Performances does not guarantee that we
        will continue to offer Gift Cards for any particular length of time. LIVENET INC/PEN Performances may suspend or
        terminate Gift Cards from time to time for any or no reason, in our sole discretion, and without advance notice
        or liability.
      </p>
      <p>
        b. <u>Nonrefundable; Not for Resale.</u> A Gift Card may not be resold; resale or attempted resale is grounds
        for immediate cancellation of the Gift Card without compensation or further notice. A Gift Card is not
        refundable or redeemable for cash, except where required by law. A recipient of a Gift Card may not transfer or
        assign the Gift Card.
      </p>
      <p>
        c. <u>Lost or Stolen Gift Card.</u> The purchaser and recipient are responsible for securing the activation,
        redemption, and/or access numbers for a Gift Card (collectively, “Access Information”) and any Gift Card. Access
        Information may be used by anyone who obtains it. A lost or stolen Gift Card or Access Information will not be
        replaced. LIVENET INC/PEN Performances is not responsible for any lost or stolen Gift Card or Access Information
        or any unauthorized use of a Gift Card or Access Information.
      </p>
      <p>
        d. <u>Endorsements.</u> Your purchase of a Gift Card and the recipient’s use of the Gift Card are subject to the{' '}
        <u>Terms</u> including the{' '}
        <strong>
          <u>Acceptable Use Policy/Community Guidelines</u>
        </strong>{' '}
        and you may not, among other things use a Gift Card in any manner that states or implies that any person,
        website, business, product, or service is endorsed or sponsored by, or otherwise affiliated with, a LIVENET
        INC/PEN Performances.
      </p>
      <p>
        e. Issuer may refuse, cancel, or hold for review any Gift Card, or any request to purchase, activate, or redeem
        a Gift Card, where it suspects fraud, mistake, alteration, error in denomination, or a violation of any
        applicable law or regulation, the <u>Terms</u>, or these Additional Terms.
      </p>
      <p>
        3. <strong>Issuer Changes to Additional Terms</strong>
      </p>
      <p>
        Issuer reserves the right to, and each holder or purchaser of a Gift Card agrees that Issuer may, at any time,
        change these Additional Terms, as permitted by applicable law, at its sole discretion. Such changes may make
        Gift Cards more or less desirable, effective, or functional. Current versions of these Additional Terms are
        accessible at <u>https://www.LIVENET INC/PEN Performances.com/terms#giftcards</u>
      </p>
      <p>
        4. <strong>Choice of Law</strong>
      </p>
      <p>
        The choice of law provision of the Site Terms will not apply to these Additional Terms. Except as governed by
        federal law, these Additional Terms will be governed by and construed in accordance with the laws of the
        Commonwealth of Virginia, without giving effect to any conflict of laws rules or provisions.
      </p>
      <p>
        5. <strong>Arbitration</strong>
      </p>
      <p>
        <u>Section 17 (Arbitration Agreement and Waiver of Certain Rights)</u>
        of the Site Terms apply to the purchase and use of Gift Cards.
      </p>
      <p>
        6. <strong>Contact Us</strong>
      </p>
      <p>a. To check a Gift Card balance or value:</p>
      <p>
        i. visit <u>www.LIVENET INC/PEN Performances.com/dashboard</u>
      </p>
      <p>
        ii. email Issuer at <u>giftcards@LIVENET INC/PEN Performances.com</u>; or
      </p>
      <p>
        iii. call Issuer toll-free at <u>1-800-243-1739</u>.
      </p>
      <p>
        Note that the balance provided (whether online, by email or telephone) is an estimate only. In most cases, the
        price of each transaction will be debited immediately, but there may be times when the updated balance is
        delayed.
      </p>
      <p>
        b. To contact us with other questions or for help with Gift Cards, email Issuer at{' '}
        <u>help@LIVENET INC/PEN Performances.com</u>.
      </p>
      <br />
      <br />
      <p>
        <strong>Acceptable Use Policy</strong>
        <strong />
      </p>
      <p>Last Updated: March 3, 2020</p>
      <p>
        Your use of our Site (collectively, the LIVENET INC/PEN PERFORMANCES™ website, mobile application (“App”), and
        services we provide through them) is governed by this Acceptable Use Policy. Terms that are defined in the Site
        Terms of Service or Talent Terms of Service will have the same meaning in our Acceptable Use Policy.
      </p>
      <p>
        You are responsible for your use of any LIVENET INC/PEN PERFORMANCES Video, your Site account, our Site, and any
        Submission (if you are a Site user) or Talent Content (if you are a Talent User). Our goal is to create a
        positive, useful, and safe user experience. To promote this goal, we prohibit certain kinds of conduct that may
        be harmful.
      </p>
      <p>1. You represent and warrant that:</p>
      <p>a. you will not use a false identity or provide any false or misleading information;</p>
      <p>
        b. you will not create an account if you (or any Site account that you created or controlled) have previously
        been removed or banned from our Site;
      </p>
      <p>
        c. you will not use or authorize the use of any LIVENET INC/PEN PERFORMANCES Video for any purposes other than:
        (i) the specific limited purposes set forth in the Site Terms; (ii) those set out in any applicable Additional
        Terms; or (iii) if you are a Talent User, those set out in any other written agreement; and
      </p>
      <p>
        d. in connection with any Business LIVENET INC/PEN PERFORMANCES Video, you will not request (if you are a User)
        or fulfill a request for (if you are a Talent User): (i) a Business or any other Recipient that is the subject
        of any criminal action, or that is involved in, connected with or promotes illegal or unlawful activity,
        violence or hate speech; or (ii) disparages or defames any person, entity, brand, or business.
      </p>
      <p>e. you will not:</p>
      <p>i. violate any law, regulation, or court order;</p>
      <p>
        ii. violate, infringe, or misappropriate the intellectual property, privacy, publicity, moral or &quot;droit
        moral,&quot; or other legal rights of any third party;
      </p>
      <p>
        iii. take any action (even if requested by another) that is, or submit, post, share, or communicate anything
        that is, or that incites or encourages, action that is, explicitly or implicitly: illegal, abusive, harassing,
        threatening, hateful, racist, derogatory, harmful to any reputation, pornographic, indecent, profane, obscene,
        or otherwise objectionable (including nudity);
      </p>
      <p>
        iv. send advertising or commercial communications, including spam, or any other unsolicited or unauthorized
        communications;
      </p>
      <p>
        v. engage in spidering or harvesting, or participate in the use of software, including spyware, designed to
        collect data from our Site, including from any user of our Site;
      </p>
      <p>
        vi. transmit any virus, other computer instruction, or technological means intended to, or that may, disrupt,
        damage, or interfere with the use of computers or related systems;
      </p>
      <p>vii. stalk, harass, threaten, or harm any third party;</p>
      <p>viii. impersonate any third party;</p>
      <p>ix. participate in any fraudulent or illegal activity, including phishing, money laundering, or fraud;</p>
      <p>x. use any means to scrape or crawl any part of our Site;</p>
      <p>
        xi. attempt to circumvent any technological measure implemented by us, any of our providers, or any other third
        party (including another user) to protect us, our Site, users, Recipients, or any other third party;
      </p>
      <p>
        xii. access our Site to obtain information to build a similar or competitive website, application, or service;
      </p>
      <p>
        xiii. attempt to decipher, decompile, disassemble, or reverse engineer any of the software or other underlying
        code used to provide our Site; or
      </p>
      <p>xiv. advocate, encourage, or assist any third party in doing any of the foregoing.</p>
      <p>
        For clarity, your use of any LIVENET INC/PEN PERFORMANCES Video includes use anywhere (on our Site or
        otherwise).
      </p>
      <p>2. Investigations:</p>
      <p>
        You acknowledge and agree that we are not obligated to monitor access to or use of our Site by you or third
        parties (including monitoring any LIVENET INC/PEN PERFORMANCES Video, Talent Content, Submission, or Feedback),
        but we have the right to do so to operate our Site; enforce this Policy or our Terms; or comply with applicable
        law, regulation, court order, or other legal, administrative, or regulatory request or process; or otherwise.
      </p>
      <br />
      <br />
      <p>
        <strong>Talent Terms of Service</strong>
        <strong />
      </p>
      <p>Last Updated: April 14, 2021</p>
      <p>
        This Talent Terms of Service Agreement (“Terms”) governs your use as a talent user of the LIVENET INC/PEN
        PERFORMANCES™ marketplace platform offered by Baron App, Inc., doing business as LIVENET INC/PEN Performances
        (“we”, “us”, or “LIVENET INC/PEN Performances”), including our website (LIVENET INC/PEN Performances.com),
        mobile application (“App”), and services we provide (collectively, the website, App, and services referred to as
        our “Site”). “You” and “Talent User” refer to you as a talent user of the Site.
      </p>
      <p>
        These Terms apply solely to Talent Users of our Site. Use of our Site is also subject to our current Acceptable
        Use Policy. Any use by you of our Site other than as a Talent User is governed by the{' '}
        <u>Site Terms of Service.</u> (“Site Terms”).
      </p>
      <p>
        <strong>
          PLEASE READ THESE TERMS CAREFULLY. By using our Site as a Talent User or otherwise indicating your acceptance
          (for example, by agreeing when creating or logging into your account, clicking “I Agree,” etc.), you represent
          and warrant that you have read, understand, and agree to be bound by these Terms. If you do not agree, do not
          access or use our Site as a Talent User.
        </strong>
      </p>
      <p>
        <strong>ARBITRATION NOTICE:</strong>
        <strong>
          <u> Section 19</u>
        </strong>
        <strong>
          of these Terms contains provisions governing how claims that you and LIVENET INC/PEN Performances have against
          each other are resolved, including any claims that arose or were asserted prior to the “Last Updated” date at
          the top of these Terms. It contains an arbitration agreement that will, with limited exceptions, require
          disputes between you and LIVENET INC/PEN Performances to be submitted to binding and final arbitration. Unless
          you opt out of the arbitration agreement as described in
        </strong>
        <strong>
          <u>Section 19</u>
        </strong>
        <strong>
          : (1) you may pursue claims and seek relief against us only on an individual basis, not as a plaintiff or
          class member in any class or representative action or proceeding; and (2) you waive your right to seek relief
          in a court of law and to have a jury trial on your claims.
        </strong>
      </p>
      <p>
        1.
        <strong>Participation in the LIVENET INC/PEN Performances Marketplace</strong>
        <strong />
      </p>
      <p>
        a. <u>Registration</u>: In order to participate on our Site, you must register. By registering, you agree to
        provide true, accurate, current, and complete information about yourself as prompted by our registration form,
        as well as any other information reasonably requested by us (collectively, “Registration Data”), and maintain
        and promptly update the Registration Data to keep it true, accurate, current, and complete. If you do not do so
        or we reasonably believe that you have not done so, we have the right to suspend or terminate your Site account
        and your use of our Site. You agree not to create a Site account using a false identity or providing false
        information, on behalf of another person (except as outlined below for a parent or legal guardian and
        Organizations), or if you (and your parent or legal guardian, or an Organization, if applicable) have previously
        been removed or banned from our Site. You are responsible for maintaining the confidentiality of your Site
        account information, including your username and password. You are responsible for all activities that occur on
        or in connection with your Site account and you agree to notify us immediately of any unauthorized access or use
        of your Site account. You acknowledge and agree that we are not responsible or liable for any damages, losses,
        costs, expenses, or liabilities related to any unauthorized access to or use of your Site account.
      </p>
      <p>
        i. <u>Talent User age 13 or older</u>: Talent Users who are at least 13 years old may register directly. By
        registering, you represent and warrant that: (A) you are at least 13 years old (and, if between 13 and 18 years
        old, are registering with the supervision and with the consent of your parent or legal guardian, who also agrees
        to these Terms); (B) you are of legal age to form a binding contract; (C) you are (and if you are between 13 and
        18 years old, your parent or legal guardian is) not barred from using our Site under the laws of the United
        States, your place of residence (and if you are between 13 and 18 years old, the place of residence of your
        parent or legal guardian), or any other applicable jurisdiction; and (D) you are (and if you are between 13 and
        18 years old, your parent or legal guardian is) responsible for complying with all applicable laws and
        regulations relating to Talent User’s participation on our Site and will fully indemnify the LIVENET INC/PEN
        Performances Parties (defined below) for any failure to do so.
      </p>
      <p>
        ii. <u>Parent or Legal Guardian of Talent User, under age 16</u>: Talent Users who are under 16 years old, may
        register only through a parent or legal guardian who, by registering, represents and warrants that: (A) he or
        she is the parent or legal guardian of the Talent User and agrees to these Terms; (B) neither the parent or
        legal guardian nor the Talent User is barred from using our Site under the laws of the United States, the place
        of residence of the parent, legal guardian, or Talent User, or any other applicable jurisdiction; and (C) he or
        she is responsible for complying with all applicable laws and regulations relating to Talent User’s
        participation on our Site and will fully indemnify the LIVENET INC/PEN Performances Parties for any failure to
        do so.
      </p>
      <p>
        iii. <u>Organizations</u>: A management company, manager, agency, agent, publicist, or other individual or
        organization (each, an “Organization”) may register a Talent User (“Affiliated Talent”). By registering, the
        Organization represents and warrants for itself and each Affiliated Talent that: (A) Organization is the
        authorized representative of the Affiliated Talent (and, if the Affiliated Talent is below the age of 18, is
        registering with any consent required of the Affiliated Talent’s parent or legal guardian as set forth in{' '}
        <u>Sections 1.a(i) </u>and <u>1.a(ii))</u>
        and agrees to these Terms; (B) neither the Organization nor the Affiliated Talent is barred from using our Site
        under the laws of the United States, the place of residence of the Organization or any Affiliated Talent, or any
        other applicable jurisdiction; and (C) Organization is responsible for complying with all applicable laws and
        regulations relating to Affiliated Talent’s participation on our Site under these Terms and will fully indemnify
        the LIVENET INC/PEN Performances Parties for any failure to do so. To register Affiliated Talent as an
        Organization, please contact <u>talentsuccess@LIVENET INC/PEN Performances.com</u>.
      </p>
      <p>
        b. <u>Promotional Materials</u>: At no cost to LIVENET INC/PEN Performances, you will provide to us the
        following promotional materials (“Promotional Materials”) within 72 hours of beginning the talent on-boarding
        process on our Site: (i) if you would like us to promote your participation on our Site, three high resolution
        images of yourself; (ii) your Site profile bio; and (iii) a promotional video of approximately :15 in length to
        let your fans know that they can book you on our Site. Please note that you will not be able to receive requests
        from Users until we receive your promotional video. From time to time we may request additional Promotional
        Materials from you for LIVENET INC/PEN Performances’s use to promote you on or in connection with our Site or on
        any social media platform or third party website. Any other materials or photos of or concerning you that you
        approve for LIVENET INC/PEN Performances’s use will also be Promotional Materials under these Terms.
      </p>
      <p>
        2. <strong>LIVENET INC/PEN PERFORMANCES Videos</strong>
        <strong />
      </p>
      <p>
        From time to time, a user of our Site (“User”) may request one or more video recordings (each, a “LIVENET
        INC/PEN PERFORMANCES Video”) from you through our Site.
      </p>
      <p>
        a. While we hope you will fulfill a request within 48 hours of receiving it, you may have up to seven days (at
        LIVENET INC/PEN Performances’s sole discretion) to complete and upload the LIVENET INC/PEN PERFORMANCES Video.
        If you do not either: (i) accept the request and upload the LIVENET INC/PEN PERFORMANCES Video; or (ii) decline
        the request; the request will expire and can no longer be fulfilled (except that you may fulfill the expired
        request at no cost to the User and with no payment due from us). You may decline a request or otherwise refuse,
        in your discretion, to create or upload a LIVENET INC/PEN PERFORMANCES Video if a User’s request is
        objectionable or otherwise offensive to you. If you accept a request, you agree to record and upload to our Site
        one LIVENET INC/PEN PERFORMANCES Video.
      </p>
      <p>
        b. LIVENET INC/PEN Performances retains the right, in its sole discretion, to cancel any request from a User. No
        payment will be made to you for any declined, cancelled, or unfulfilled LIVENET INC/PEN PERFORMANCES Video
        request.
      </p>
      <p>
        c. Each LIVENET INC/PEN PERFORMANCES Video will be approximately :30 in duration and will follow the general
        directions and requests of the User (for example, birthday message, congratulatory message, or “Good luck!”
        message). You will have sole discretion over the script and content of any LIVENET INC/PEN PERFORMANCES Video,
        except that you agree: (i) you will use your name (please introduce yourself), the User’s name, and the name of
        any third party that the User identifies as a recipient (“Recipient”) in each LIVENET INC/PEN PERFORMANCES
        Video, unless otherwise requested by the User; and (ii) you will not state in the LIVENET INC/PEN PERFORMANCES
        Video that the LIVENET INC/PEN PERFORMANCES Video is incomplete or cannot be completed. Your response to the
        User’s request is your responsibility and at your discretion, subject to these Terms; however, Users are usually
        happiest when at least the majority of their request is followed by a Talent User. If you complete a LIVENET
        INC/PEN PERFORMANCES Video but do not follow the general directions and requests of the User, we will discuss
        with you an appropriate adjustment of the payment made to you.
      </p>
      <p>
        3. <strong>Business LIVENET INC/PEN PERFORMANCES Videos</strong> <strong />
      </p>
      <p>
        If you are a resident of the United States, you may choose to offer LIVENET INC/PEN PERFORMANCES Videos for
        promotion of a U.S. commercial entity, brand, or business (&quot;Business&quot;) through our Site (each, a
        &quot;Business LIVENET INC/PEN PERFORMANCES Video&quot;). Except as noted, each Business LIVENET INC/PEN
        PERFORMANCES Video is a LIVENET INC/PEN PERFORMANCES Video under these Terms.
      </p>
      <p>
        4. <strong>Fees and Payment</strong>
        <strong />
      </p>
      <p>
        a. <u>Booking Fee</u>: You set your own price for each LIVENET INC/PEN PERFORMANCES Video and any other offering
        you choose to make available through the LIVENET INC/PEN Performances marketplace platform (e.g., Business
        LIVENET INC/PEN PERFORMANCES Videos) (each, a “Booking Fee”), provided that: (i) your price on the App for iOS
        must be an available Apple SKU; and (ii) where there is no identical Apple SKU, your price will be the Apple SKU
        that is closest to the price you set (e.g., Booking Fee of US$5.00 adjusted to US$5.99; Booking Fee of US$100.00
        adjusted to US$99.99). In addition, the Booking Fee for each LIVENET INC/PEN PERFORMANCES Video must be at least
        US$5.00 (unless it is either US$0.00 or as otherwise agreed in writing by LIVENET INC/PEN Performances). The
        Booking Fee excludes any service, transaction, or processing fees paid to LIVENET INC/PEN Performances.
      </p>
      <p>
        b. <u>Fees</u>: Other than with respect to an expired request that you choose to fulfill (as set forth in{' '}
        <u>Section 2(a)</u>) and subject to these Terms, we will pay you 75% of the Booking Fee actually received by
        LIVENET INC/PEN Performances for each LIVENET INC/PEN PERFORMANCES Video or other LIVENET INC/PEN Performances
        product offering that you created and delivered to fulfill to a User’s request that you accepted through our
        Site, except that in the case of a Booking Fee paid through the App, we will pay you 75% of the amount actually
        received by LIVENET INC/PEN Performances after subtracting any payment to or deduction by the application
        platform (e.g., Apple deducts a 30% commission from the Booking Fee) from the Booking Fee (“Net App Booking
        Fee”). In addition, if the offering permits a User to designate an additional amount as a “tip”, we will pay you
        75% (unless a different percentage is agreed in writing by LIVENET INC/PEN Performances) of any amount paid by a
        User via our Site as a &quot;tip&quot; (after subtracting any payment to or deduction by the application
        platform).
      </p>
      <p>
        c. <u>Fees and Payment Representations and Warranties</u>: You represent and warrant that: (i) LIVENET INC/PEN
        PERFORMANCES Videos are not, and are not intended to be, covered by any guild, union, collective bargaining,
        management, agency, or similar agreement and there will be no residual or any other type of payment due from
        LIVENET INC/PEN Performances to you, to any third party, guild, union, management, or agent, or pursuant to any
        collective bargaining agreement, in connection with any LIVENET INC/PEN PERFORMANCES Video, payment from us to
        you, or our Site; (ii) LIVENET INC/PEN Performances is not responsible for, and will not make any deduction to
        payments made to you for any fees, commissions, costs, expenses, or payments of any kind to or with respect to
        any third party, including any manager, agent, attorney, representative, or service provider, in connection with
        any revenue earned by or payments made to you in connection with our Site; and (iii) LIVENET INC/PEN
        Performances is not responsible for any contributions, payments, taxes, or deductions for Social Security,
        retirement benefits, unemployment insurance, annuities, or pension or welfare fund payments required by law or
        any labor union, or any withholding or income taxes.
      </p>
      <p>
        d. <u>Payment</u>: You agree to register with the third party payment provider selected by LIVENET INC/PEN
        Performances, which LIVENET INC/PEN Performances may change in its sole discretion. You may not use a payment
        provider other than the one selected by LIVENET INC/PEN Performances. You will provide the payment provider any
        information required in order to receive payments via the payment provider, e.g., information about the bank
        account that you own at a regulated financial institution. Any payments due to you from LIVENET INC/PEN
        Performances will be made via the payment provider. If available, you may transfer funds from your LIVENET
        INC/PEN Performances account to your bank account by submitting a payment request. Your request will be
        processed by the payment provider. If not available, LIVENET INC/PEN Performances will submit the payment
        request, for processing by the payment provider. In addition, LIVENET INC/PEN Performances may, in its sole
        discretion, transfer funds from your LIVENET INC/PEN Performances account to your bank account especially in the
        case of account inactivity.
        <strong>
          If you do not provide the payment provider with all required information, you may not be able to receive the
          payments due to you. LIVENET INC/PEN Performances will not be responsible for any damages, delays, losses,
          costs, expenses, or liabilities arising out of or in connection with your inability to receive payments as a
          result of your failure to provide such information.
        </strong>
        You acknowledge and agree that LIVENET INC/PEN Performances does not operate, own, or control the payment
        provider; and your use of any payment provider is subject to the terms and privacy policies of that payment
        provider. You agree that we are not responsible for any delay, failure, damage, or liability caused by a payment
        provider, any other third party, a force majeure, or your failure to timely or properly set up an account with
        the payment provider or otherwise provide requested information for payment. Other than with respect any payment
        to or deduction by the application platform (as set forth in <u>Section 4.b</u>). LIVENET INC/PEN Performances
        will be responsible for fees, costs, and expenses incurred in connection with the payment provider selected by
        LIVENET INC/PEN Performances. Unless otherwise agreed by LIVENET INC/PEN Performances in writing, you
        acknowledge and agree that you are solely responsible for any other fees, costs, and expenses, including with
        respect to your bank account and foreign exchange fees. Notwithstanding anything to the contrary contained in
        these Terms, if LIVENET INC/PEN Performances, in its sole discretion, believes that any fraud, money laundering,
        or other violation of law or regulation is taking place on or in connection with our Site, you acknowledge and
        agree that we may withhold, delay, or seek repayment of any payments we believe, in our sole discretion, are
        related to the violation.
      </p>
      <p>
        e. <u>Currency</u>: Payments via the payment provider are in U.S. dollars unless the payment provider permits
        you to choose another currency and you do so.
      </p>
      <p>
        f. <u>Fundraising</u>: If you identify any entity on your booking page or elsewhere on our Site as a charitable
        organization or a recipient of any funds that you are raising (each, a “Charity”), you: (i) represent and
        warrant that you will comply with all applicable laws and regulations relating to that identification or the
        Charity, including making disclosures, registering, or entering into any agreement, such as a commercial
        co-venturer agreement; (ii) acknowledge and agree that we have the right in our sole discretion to reject your
        identification or the Charity; (iii) represent and warrant that the Charity is and will remain in good standing
        at all times the identification is used, that within 48 hours of your receipt of our request, you will provide
        us with written evidence of the Charity’s good standing and charitable status in all applicable jurisdictions,
        and that you will promptly remove the identification if the Charity ceases to be in good standing; (iv)
        represent and warrant that you will be responsible for making any payment to the Charity (unless we expressly
        agree in writing in advance to do so on your behalf and in satisfaction of our payment obligations to you under
        these Terms); and (v) represent and warrant that you have all rights necessary to authorize use of the Charity’s
        name and logo in connection with (and on) our Site, in the identification, and in any social and other media.
        You further acknowledge and agree that we may add a statement to your booking page disclaiming a connection
        between LIVENET INC/PEN Performances and the Charity, as we determine in our sole discretion.
      </p>
      <p>
        5. <strong>Associates Program</strong>
        <strong />
      </p>
      <p>
        We offer an Associates (“LIVENET INC/PEN Performances Associates”) opportunity to provide a unique Associates
        code we provide you (“Code”) that a prospective talent user may use to apply to register as a talent user on our
        Site (“Associates Program”).
        <strong>
          To participate in the Associates Program, you must agree to the following Talent Associates Program Terms
          (“Associates Terms”), as well as the rest of these Terms:
        </strong>
      </p>
      <p>
        a. LIVENET INC/PEN Performances reserves the right to suspend or terminate the Associates Program or your
        participation in the Associates Program at any time for any reason. Without limiting the foregoing, we reserve
        the right to disqualify anyone from participating in the Associates at any time at our sole discretion. A
        violation of the Associates Terms may also result in the forfeiture of all Associates Fees (defined below) you
        earned through the Associates Program. We reserve the right to review and investigate activities undertaken in
        connection with the Associates Program and suspend your Site account, modify the Associates Fee, or take other
        action in our sole discretion.
      </p>
      <p>
        b. Eligibility to participate in this Associates Program is limited to individuals who have registered an
        account on our Site as a Talent User or LIVENET INC/PEN Performances Partner. The Associates Program cannot be
        used for affiliate lead generation or other commercial purposes. Employees, officers, directors, contractors,
        agents, and representatives of LIVENET INC/PEN Performances may not participate in the Associates Program.
      </p>
      <p>
        c. For every new user whose application to register as a Talent User on our Site using your Code is accepted in
        writing by LIVENET INC/PEN Performances (“Referred Talent”), LIVENET INC/PEN Performances will pay you, for one
        year, beginning as of the date that the Referred Talent first created an account as a Talent User on our Site
        using your Code, 5% of the Booking Fee actually received by LIVENET INC/PEN Performances, except that in the
        case of a Booking Fee paid through the App, it is 5% of the Net App Booking Fee, for each LIVENET INC/PEN
        PERFORMANCES Video the Referred Talent creates and delivers to fulfill a User’s request accepted through our
        Site during that one-year period (excluding any LIVENET INC/PEN PERFORMANCES Video for which no revenue was
        earned, and, at LIVENET INC/PEN Performances’s discretion, any LIVENET INC/PEN PERFORMANCES Video to which a
        promotion applies or for which a promo code was used) (“Associates Fee”). The Associates Fee is based on the
        Booking Fee or Net App Booking Fee but is paid from LIVENET INC/PEN Performances’s share of the revenue (as
        described in <u>Section 4.b</u>) and will be paid as described in <u>Section 4.d</u>. Notwithstanding anything
        to the contrary contained in these Terms, you acknowledge and agree that if a new user does not use your Code
        when applying to register, has previously registered on our Site (with another code, under another name, or
        otherwise), has previously begun the on-boarding process for our Site, or if anyone else has received or is
        receiving a Associates Fee from us in connection with that user, no Associates Fee will be due to you. You
        further acknowledge and agree that each Referred Talent may have only one referring person or entity, and that
        we are not obligated to pay a Associates Fee to more than one referring person or entity with respect to any
        Referred Talent.
      </p>
      <p>
        d. This Associates Program may be used only for personal purposes and you may share your Code only with your
        personal connections. You may not advertise your Code or share it with anyone other than your friends, family,
        or other personal contacts (for example, you may not share it on a social media channel). We reserve the right
        to revoke or refuse to issue any Associates Fees for Referred Talent that we suspect were generated through
        improper channels or otherwise in violation of these Associates Terms.
      </p>
      <p>
        e. You represent and warrant that any messages you use to share your Code with your personal connections will:
        (i) be created and distributed in a personal manner; (ii) not be distributed in a bulk manner; and (iii) not be
        an unsolicited commercial email or a “spam” message under any applicable law or regulation. Any messages that do
        not meet these requirements are expressly prohibited and constitute grounds for immediate termination of your
        Site account and participation in this Associates Program. Registrations on our Site by a Talent User using a
        Code that was distributed through an unauthorized channel will not be valid and any Associates Fees issued in
        connection with such transactions may be revoked.
      </p>
      <p>
        f. We reserve the right to deactivate your Site account and any Referred Talent’s Site account, cancel all
        related Associates Fees, and seek repayment, if we determine, in our sole discretion, that either you or the
        Referred Talent: (i) has tampered with this Associates Program; (ii) has used (or attempted to use) this
        Associates Program in a fraudulent, abusive, unethical, unsportsmanlike, or otherwise questionable or suspicious
        manner; (iii) has breached any of these Associates Terms; or (iv) has violated any law or regulation or
        infringed or violated the rights of any third party. We also reserve the right to cancel this Associates Program
        or change these Associates Terms at any time and for any reason in our sole discretion.
      </p>
      <p>
        g. As of the effective date of these Terms and notwithstanding anything to the contrary herein, a Talent User
        may become an Affiliated Talent to an Organization, subject to LIVENET INC/PEN Performances’s prior approval,
        by: (i) confirming in writing, in the form provided by LIVENET INC/PEN Performances, that the Organization is
        its representative; and (ii) the Organization registering the Talent User as its Affiliated Talent. The
        Organization will be deemed a LIVENET INC/PEN Performances Partner and the Affiliated Talent will be deemed a
        Referred Talent under these Associates Terms. The Organization (as a LIVENET INC/PEN Performances Partner) will
        be entitled to the Associates Fee set forth in <u>Section 5.c</u> as of the date the Talent User becomes an
        Affiliated Talent.
      </p>
      <p>
        h. By participating in the Associates Program, you agree to release and hold harmless LIVENET INC/PEN
        Performances Parties from and against any and all economic liabilities, claims, damages, loss, harm, costs, or
        expenses, including property damage, that arise from or relate in any way to the Associates Program. THIS{' '}
        <u>SECTION 5.h</u> APPLIES ONLY TO ECONOMIC DAMAGES AND DOES NOT APPLY TO CLAIMS OF FRAUD OR PERSONAL INJURY.
      </p>
      <p>
        6. <strong>Talent Content</strong>
        <strong />
      </p>
      <p>
        a. <u>License Grant to Talent Content</u>: Our Site allows you to upload, submit, store, send, transmit,
        approve, and receive content and data, including your LIVENET INC/PEN PERFORMANCES Videos and Promotional
        Materials (collectively, “Talent Content”). When you upload, submit, store, send, transmit approve, or receive
        Talent Content to or through our Site, you grant to us a non-exclusive, royalty-free, fully paid, unlimited,
        universal, sublicensable (through multiple tiers of sublicenses), perpetual, and irrevocable license in any and
        all manner and media, whether now known or hereinafter invented or devised (including social media channels and
        third party websites and platforms), to reproduce, license, distribute, modify, adapt, publicly perform,
        publicly display, create derivative works of (for example, translations, adaptations, or other changes we make
        so that Talent Content works better with our Site or otherwise), and to use your Talent Content for the purposes
        of operating and providing our Site, to develop and improve our products and services, and to advertise, market,
        and promote our Site, products, and services, and you agree that such Talent Content may, in LIVENET INC/PEN
        Performances’s sole discretion, be used, including performed or displayed, in connection with any other
        elements, materials, copyrights, rights of publicity, or copyrighted materials. Please remember that third
        parties (including Users) may search for and see any Talent Content you submit to public areas of our Site. You
        agree that we may display advertising with or in connection with your Talent Content. You further acknowledge
        and agree that LIVENET INC/PEN Performances has no obligation to you in connection with any advertising
        displayed on or in connection with our Site (including no obligation to share any revenue received by LIVENET
        INC/PEN Performances as a result of any such advertising).
      </p>
      <p>
        b. <u>License Grant to Users</u>:
      </p>
      <p>
        i. For each LIVENET INC/PEN PERFORMANCES Video (other than a Business LIVENET INC/PEN PERFORMANCES Video), you
        hereby grant to the User and the Recipient a non-exclusive, royalty-free, fully paid, worldwide, sublicensable,
        and perpetual license to use, reproduce, distribute, and publicly display the LIVENET INC/PEN PERFORMANCES
        Video, in each case, solely in accordance with the <u>Site Terms</u>, in any and all media, whether now known or
        hereafter invented or devised (including social media channels and third party websites and platforms).
      </p>
      <p>
        ii. For each Business LIVENET INC/PEN PERFORMANCES Video, you hereby grant to User and the Business for up to
        180 days from the date the Business LIVENET INC/PEN PERFORMANCES Video is sent by LIVENET INC/PEN Performances
        to the User (and for any additional 180-day periods to which you agree):
      </p>
      <p>
        A. a non-exclusive, royalty-free, fully paid, worldwide, sublicensable, revocable license to use, reproduce,
        distribute, and publicly display the Business LIVENET INC/PEN PERFORMANCES Video on or in: (A) one website
        wholly-owned, operated, and controlled by the Business; (B) up to four social media accounts, such as Facebook,
        Instagram, LinkedIn, SnapChat, TikTok, or Twitter, in each case, wholly-owned, operated, and controlled by the
        Business along with the right to advertise and promote the display of the Business LIVENET INC/PEN PERFORMANCES
        Video on a social media account through advertising only on the applicable social media platform; (C) one
        communication sent through a Business-operated and controlled channel of communication (such as an email blast
        or text message); (D) if the Business has fewer than 1,000 employees, one event conducted and operated by the
        Business (for example, a company-wide meeting); and (E) a compilation with content consisting of other LIVENET
        INC/PEN Performances Offerings.
      </p>
      <p>
        c. <u>Right to Remove LIVENET INC/PEN PERFORMANCES Videos</u>: You acknowledge and agree that we cannot restrict
        the use of your LIVENET INC/PEN PERFORMANCES Videos or other offerings by the Users for whom you created them or
        by any third party with whom they have already been shared (including Recipients) and we have no obligation to
        remove those uses (including from social media channels or third party websites or platforms). If we do seek to
        remove a LIVENET INC/PEN PERFORMANCES Video from a social media channel or third party website or platform, we
        may notify you of our intent to do so. As owner of the copyright in your Talent Content, you hereby authorize
        LIVENET INC/PEN Performances to act as your agent in order to submit any DMCA notice or other demand with
        respect to your LIVENET INC/PEN PERFORMANCES Videos. You will promptly notify us if you learn that any Business
        LIVENET INC/PEN PERFORMANCES Video is being used in violation of the <u>Site Terms</u>. We will reasonably
        cooperate with your efforts to address the violation. However, you acknowledge and agree that we are not
        responsible or liable for any damages, losses, costs, expenses, or liabilities related to use of the Business
        LIVENET INC/PEN PERFORMANCES Video for any reason, including if the use exceeds the license in{' '}
        <u>Section 1)a(ii)</u> or otherwise violates the <u>Site Terms</u>.
      </p>
      <p>
        d. <u>Licenses</u>: You may not sell, resell, commercialize, or encumber your rights in any LIVENET INC/PEN
        Performances offering, including creating a non-fungible token (“NFT”) from any LIVENET INC/PEN Performances
        offering (including LIVENET INC/PEN Performances Videos) except as agreed by LIVENET INC/PEN Performances in
        writing. Please note that the licenses granted in this <u>Section 6</u> are fully-paid and royalty free, meaning
        we do not owe you anything in connection with the use of your Talent Content, by us, Users, or third parties
        (including Recipients and, in the case of a Business LIVENET INC/PEN PERFORMANCES Video, the Business), other
        than the payment set forth in <u>Section 4</u>. We may exercise our rights under this license anywhere in the
        universe. We may sublicense our rights as needed to provide and promote our Site or otherwise in accordance with
        these Terms, and Users may sublicense their rights subject to the <u>Site Terms</u>. Finally, the licenses
        granted in this <u>Section 6</u>
        are perpetual, meaning that the rights granted under these licenses continue even after you stop using our Site.
      </p>
      <p>
        e. <u>Talent Content Representations and Warranties</u>: You represent and warrant that:
      </p>
      <p>
        i. you own all rights in and to your Talent Content and Feedback (defined below) and that you have the right to
        grant the rights described in these Terms;
      </p>
      <p>
        ii. you have paid and will pay in full any fees, royalties, or other payments that are due or may become due in
        connection with any use of your Talent Content and Feedback by us, Users, or third parties (including
        Recipients) as described in these Terms;
      </p>
      <p>
        iii. your agreement to, and provision of services under, these Terms does not violate any agreement that you may
        have with any third party;
      </p>
      <p>iv. In connection with each Business LIVENET INC/PEN PERFORMANCES Video:</p>
      <p>a. you are a resident of the U.S.;</p>
      <p>
        a. any statements that you make in connection with the Business are factually correct and not misleading, are
        not disparaging or defamatory, and represent your true opinion; and
      </p>
      <p>
        b. you will comply with all applicable laws, rules, and regulations, including the Federal Trade Commission
        &quot;Guides Concerning the Use of Endorsements and Testimonials in Advertising,&quot; which may require adding
        an appropriate hashtag (e.g. #ad, #sponsored) or other disclosure to the Business LIVENET INC/PEN PERFORMANCES
        Video.
      </p>
      <p>
        i. your Talent Content and Feedback does not infringe, misappropriate, or otherwise use without necessary
        authorization, any intellectual property, privacy, publicity, moral, or other rights of any third party, or
        violate any law, regulation, or court order;
      </p>
      <p>
        ii. you will not post or make publicly available any LIVENET INC/PEN PERFORMANCES Video that the User has
        requested not be posted to your booking page;
      </p>
      <p>
        iii. you will not contact, respond to, or communicate with any User that you meet on or through our Site, except
        as expressly permitted through our Site.
      </p>
      <p>
        iv. you will not provide your contact information to any User or send merchandise or anything else to a User
        other than as permitted by these Terms;
      </p>
      <p>
        v. you will not edit, change, modify, or remove the watermark from any LIVENET INC/PEN PERFORMANCES Video or
        assist or encourage any third party to do so;
      </p>
      <p>
        vi. you will not ask us for permission or to assist you with the actions prohibited by <u>Sections 6.e(vi)</u>{' '}
        through <u>6.e(ix))</u>; and
      </p>
      <p>
        vii. except for a LIVENET INC/PEN PERFORMANCES Video that you choose to remake (at no additional cost to the
        User and with no additional payment due from us) if you receive a review of three stars or less, you will not
        remake any LIVENET INC/PEN PERFORMANCES Video unless asked by us to do so.
      </p>
      <p>
        a. <u>Treatment of Talent Content</u>: Any Talent Content is non-confidential, non-proprietary, and must not
        contain or include any information which you do not have the right to disclose or that you do not wish to be
        disclosed. We will not be responsible or liable for any use or disclosure of Talent Content, including any
        personal information included in that Talent Content. You acknowledge and agree that your relationship with us
        is not a confidential, fiduciary, joint-venture, employer/employee, agency, or other type of special
        relationship, and that your decision to participate in our Site or submit any Talent Content does not place us
        in a position that is any different from the position held by members of the general public, including with
        regard to your Talent Content. None of your Talent Content will be subject to any obligation of confidence by
        us, Users, or third parties (including Recipients), and we will not be liable or responsible for any use or
        disclosure of any Talent Content.
      </p>
      <p>
        b. <u>Refusal and Removal of Talent Content</u>: We may refuse to accept or transmit Talent Content for any
        reason without notice to you. We may remove Talent Content from our Site for any reason without notice to you.
      </p>
      <p>
        c. <u>Cancellation of Site Account</u>: If you cancel your Site account, you may, on at least three business
        days’ advance written notice to us, request that we no longer include your LIVENET INC/PEN PERFORMANCES Videos
        on our Site and that we not make any new public use of them. As noted in <u>Section 6.c</u>, we have no
        obligation as to any use of your LIVENET INC/PEN PERFORMANCES Videos by the Users for whom you created them or
        by any third party with whom they have already been shared (including Recipients) (including from social media
        channels or third party websites or platforms).
      </p>
      <p>
        7. <strong>Ownership</strong>
        <strong />
      </p>
      <p>
        a. Other than Talent Content, we or our licensors own all right, title, and interest in and to: (i) our Site and
        the “look and feel” of our Site, including all software, ideas, processes, data, text, media, and other content
        available on our Site (individually, and collectively, “LIVENET INC/PEN Performances Content”); and (ii) our
        trademarks, logos, and brand elements (“Marks”). Our Site, LIVENET INC/PEN Performances Content, and Marks are
        each protected under U.S. and international laws. You may not duplicate, copy, or reuse any portion of LIVENET
        INC/PEN Performances Content or use the Marks without our prior express written consent. You acknowledge and
        agree that each request or message from a User is a Submission (as defined in the <u>Site Terms</u>) owned by
        the User who created it.
      </p>
      <p>
        b. LIVENET INC/PEN Performances desires to avoid the possibility of misunderstandings if a project developed by
        us, our employees, or our contractors might seem similar to material submitted to us by you or a third party. To
        the extent you submit any ideas, suggestions, proposals, plans, or other materials related to our business
        (individually, and collectively, “Feedback”), you acknowledge and agree that you are submitting that Feedback at
        your own risk and that LIVENET INC/PEN Performances has no obligation (including of confidentiality or privacy)
        with respect to your Feedback, and you grant to LIVENET INC/PEN Performances a non-exclusive, royalty-free,
        fully paid, unlimited, universal, sublicensable (through multiple tiers of sublicenses), perpetual, and
        irrevocable license, in any and all manner and media, whether now known or hereinafter invented or devised, to
        reproduce, license, distribute, modify, adapt, publicly perform, publicly display, create derivative works of
        (for example, translations, adaptations, or other changes), and otherwise use and exploit in any manner
        (including commercially), any and all Feedback.
      </p>
      <p>
        c. You hereby waive any and all moral rights or “droit moral” that you may have in Talent Content or Feedback,
        and you represent and warrant that no third party has any moral, “droit moral” or other rights in the Talent
        Content or Feedback.
      </p>
      <p>
        8. <strong>Additional Terms</strong>
        <strong />
      </p>
      <p>
        Some products or services offered through the Site may have additional terms and conditions (“Additional
        Terms”). If Additional Terms apply, we will make them available for you to read in connection with that product
        or service. By using that product or service, you agree to the Additional Terms. To the extent that the
        Additional Terms conflict with any of these Terms, these Terms will govern unless the Additional Terms say that
        all or some of these Terms don’t apply.
      </p>
      <p>
        9. <strong>Eligibility</strong>
        <strong />
      </p>
      <p>
        a. <u>Age</u>: You must be at least 13 years old (or, outside of the United States, the applicable legal age to
        enter into a contract) to use our Site. If you are a minor or under the age of majority in your state of
        residence, your parent or legal guardian must agree to these Terms on your behalf and you may access and use our
        Site only with permission from your parent or legal guardian.
      </p>
      <p>
        b. <u>Eligibility Representations and Warranties</u>: You represent and warrant that:
      </p>
      <p>
        i. you have not been prohibited from using or accessing any aspect of our Site by us or pursuant to any
        applicable law or regulation;
      </p>
      <p>
        ii. you will comply with all applicable terms of any third party payment provider we select, and you are not on
        a prohibited list of that payment provider;
      </p>
      <p>
        iii. if you include an animal in any LIVENET INC/PEN PERFORMANCES Video, you will cause no harm to the animal
        and will comply with all applicable laws and regulations;
      </p>
      <p>
        iv. you (and any Site account that you created or control) have not been previously banned or removed from our
        Site for any reason; and
      </p>
      <p>v. you are not a convicted sex offender.</p>
      <p>
        c. <u>Export Control</u>: You may not use, export, import, or transfer any part of our Site except as authorized
        by United States law, the laws of the jurisdiction in which you use or access our Site, or any other applicable
        laws. In particular, but without limitation, no part of our Site may be exported or re-exported: (i) into any
        country embargoed by the U.S.; or (ii) to anyone on the U.S. Treasury Department’s list of Specially Designated
        Nationals or the U.S. Department of Commerce’s Denied Persons List or Entity List. By using our Site, you
        represent and warrant that: (x) you are not located in a country that is subject to a U.S. Government embargo or
        that has been designated by the U.S. Government as a “terrorist supporting” country; and (y) you are not listed
        on any U.S. Government list of prohibited or restricted parties. You also will not use our Site for any purpose
        prohibited by law. You acknowledge and agree that products, services, and technology provided by LIVENET INC/PEN
        Performances are subject to the export control laws and regulations of the U.S. You will comply with those laws
        and regulations and will not, without prior U.S. government authorization, export, re-export, or transfer
        LIVENET INC/PEN Performances products, services, or technology, either directly or indirectly, to any country in
        violation of those laws and regulations.
      </p>
      <p>
        10. <strong>Copyright and Intellectual Property Policy</strong> <strong />
      </p>
      <p>
        a. <u>Digital Millennium Copyright Act Notice</u>: We respond to notices of alleged copyright infringement and
        terminate access to our Site for repeat infringers. If you believe that your material has been copied in a way
        that constitutes copyright infringement, please forward the following information to the Copyright Agent named
        below:
      </p>
      <p>i. your address, telephone number, and email address;</p>
      <p>ii. a description of the work that you claim is being infringed;</p>
      <p>
        iii. a description of the material that you claim is infringing and are requesting be removed along with
        information about where it is located;
      </p>
      <p>
        iv. a statement that you have “a good faith belief that use of the material in the manner complained of is not
        authorized by the copyright owner, its agent, or the law”;
      </p>
      <p>
        v. an electronic or physical signature of the copyright owner (or a person authorized to act for the copyright
        owner); and
      </p>
      <p>
        vi. a statement by you, made under penalty of perjury, that the information you are providing is accurate and
        that you are the copyright owner or authorized to act on behalf of the copyright owner.
      </p>
      <p>Copyright Agent:</p>
      <p>
        <u>agent@PenPerformances.com</u>
      </p>
      <p>
        If you do not follow these requirements, your notice may not be valid. Please note, only notices of alleged
        copyright infringement should be sent to our Copyright Agent.
      </p>
      <p>
        b. <u>Termination Policy</u>: If we determine that you are a repeat infringer, we may terminate your access to
        our Site, remove or ban you (and any Site account you created or control), and take other appropriate action in
        our sole discretion.
      </p>
      <p>
        11. <strong>Privacy</strong>
        <strong />
      </p>
      <p>
        Your privacy is important to us. Our <u>Privacy Policy</u> explains how we collect, use, and share personal
        information and other data. By using our Site, you agree to our <u>Privacy Policy</u>.
      </p>
      <p>
        12. <strong>Third Party Content and Interactions</strong>
        <strong />
      </p>
      <p>
        Our Site may contain features and functionalities that link to or provide you with access to third party
        content, that is completely independent of LIVENET INC/PEN Performances, including LIVENET INC/PEN PERFORMANCES
        Videos, websites, platforms, directories, servers, networks, systems, information, databases, applications,
        software, programs, products or services, and the Internet in general. Your interactions with third parties,
        including Users, on or through our Site, are solely between you and the third party; however, LIVENET INC/PEN
        Performances may, in its sole discretion, intercede and you will reasonably cooperate with LIVENET INC/PEN
        Performances if it does so. You acknowledge and agree that LIVENET INC/PEN Performances will not be responsible
        for any damages, losses, costs, expenses, or liabilities incurred as the result of such interactions, including
        any requests or Submissions from Users. You agree to contact Users about matters relating to our Site only
        through the Site. You hereby release each LIVENET INC/PEN Performances Party (defined below) from claims,
        demands, and damages (actual and consequential) of every kind or nature, known and unknown, suspected and
        unsuspected, disclosed and undisclosed, arising out of or in any way related to such interactions or our Site.
        IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 (AND ANY OTHER SIMILAR
        APPLICABLE STATE STATUTE), WHICH PROVIDES: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR
        RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND
        THAT, IF KNOWN BY HIM OR HER WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED
        PARTY.
      </p>
      <p>
        13.<strong>Business Relationship with LIVENET INC/PEN Performances</strong> <strong />
      </p>
      <p>
        a. You and LIVENET INC/PEN Performances agree and declare you and LIVENET INC/PEN Performances are in a direct
        business relationship and the relationship between the parties, including these Terms, is solely an independent
        contractor relationship. It is the parties’ express intent that their relationship be interpreted and held to be
        that of independent contractor for all purposes. You acknowledge and agree that you are not a joint venturer,
        franchisee, partner, agent, or employee of LIVENET INC/PEN Performances, and will not represent yourself as
        such. As an independent contractor using our platform to provide marketing, artistic, writing, and photography
        services to Users, you are solely and exclusively responsible for determining the manner, method, details, and
        means of your performance under these Terms and you acknowledge and agree that we do not control those elements
        of your performance. You also retain the option to accept, decline, or ignore any User request. We have no right
        to, and will not, control the manner or determine the method of accomplishing your performance. You represent
        and warrant that you are customarily engaged in an independently established trade, occupation, or business.
      </p>
      <p>
        b. You represent and warrant that as between LIVENET INC/PEN Performances and you (whether a Talent User, parent
        or legal guardian of a Talent User, an Organization, Affiliated Talent, LIVENET INC/PEN Performances Partner, or
        otherwise), you assume sole liability for and will pay or cause to be paid all applicable contributions,
        payments, taxes, and deductions for Social Security, retirement or other benefits, healthcare insurance,
        unemployment insurance, annuities, pension and welfare fund payments required by law, regulation, or any labor
        union, and all withholding and income taxes, and make any reports required as a result of participation on our
        Site under these Terms.
      </p>
      <p>c. You will use your own equipment to perform your obligations under these Terms.</p>
      <p>
        d. You are solely responsible for making any disclosure required by any applicable law, regulation, court order
        or any agreement you may have with any third parties to any person or entity regarding your performance under
        these Terms.
      </p>
      <p>
        e. Your relationship with LIVENET INC/PEN Performances is non-exclusive, meaning that you may provide similar
        services to third parties, including LIVENET INC/PEN Performances’s competitors, and you may engage in other
        business or employment activities. Similarly, we can and do engage third parties to provide services similar to
        those that you may provide under these Terms.
      </p>
      <p>
        14. <strong>Links</strong>
        <strong />
      </p>
      <p>
        Our Site may contain links to social media platforms or third party websites. You acknowledge and agree that:
        (a) the link does not mean that we endorse or are affiliated with the platform or website; and (b) we are not
        responsible or liable for any damages, losses, costs, expenses, or liabilities related to your use of the
        platform or website. You should always read the terms and conditions and privacy policy of a platform or website
        before using it.
      </p>
      <p>
        15. <strong>Changes to our Site</strong>
        <strong />
      </p>
      <p>
        You acknowledge and agree we may change or discontinue any aspect of our Site at any time, without notice to
        you.You acknowledge and agree we may change or discontinue any aspect of our Site at any time, without notice to
        you.
      </p>
      <p>
        16. <strong>Termination and Reservation of Rights</strong>
        <strong />
      </p>
      <p>
        You may cancel your Site account at any time by contacting a member of the LIVENET INC/PEN Performances team at{' '}
        <u>talentsuccess@PENPerformances.com</u>. We reserve the right to terminate access to our Site to any person,
        including you, at any time, for any reason, in our sole discretion. If you violate any of these Terms, your
        permission to use our Site automatically terminates.
      </p>
      <p>
        17. <strong>Disclaimers and Limitations on our Liability</strong> <strong />
      </p>
      <p>
        a.
        <strong>
          You acknowledge and agree that your use of our Site is at your own risk and that our Site is provided on an
          “as is” and “as available” basis. To the extent permitted by applicable law, the LIVENET INC/PEN Performances
          Parties disclaim all warranties, conditions, and representations of any kind, whether express, implied,
          statutory, or otherwise, including those related to merchantability, fitness for a particular purpose,
          non-infringement, and arising out of course of dealing or usage of trade.
        </strong>
        <strong />
      </p>
      <p>
        b.
        <strong>
          In particular, the LIVENET INC/PEN Performances Parties make no representations or warranties about the
          accuracy or completeness of content available on or through our Site or the content of any social media
          platform or third party website linked to or integrated with our Site. You acknowledge and agree that the
          LIVENET INC/PEN Performances Parties will have no liability for any: (i) errors, mistakes, or inaccuracies of
          content; (ii) personal injury, property damage, or other harm resulting from your access to or use of our
          Site; (iii) any unauthorized access to or use of our servers, any personal information, or user data; (iv) any
          interruption of transmission to or from our Site; (v) any bugs, viruses, trojan horses, or the like that may
          be transmitted on or through our Site; or (vi) any damages, losses, costs, expenses, or liabilities of any
          kind incurred as a result of any content or the use of any content posted or shared through our Site.
        </strong>
        <strong />
      </p>
      <p>
        c.
        <strong>
          You acknowledge and agree that any material or information downloaded or otherwise obtained through our Site,
          including a User request, is done at your own risk and that you will be solely responsible for any damages,
          losses, costs, expenses, or liabilities arising from or in connection with doing so. No advice or information,
          whether oral or written, obtained by you from us or through our Site, including through a LIVENET INC/PEN
          PERFORMANCES Video, will create any warranty not expressly made by us.
        </strong>
        <strong />
      </p>
      <p>
        d.
        <strong>
          You acknowledge and agree that when using our Site, you will be exposed to content from a variety of sources,
          and that LIVENET INC/PEN Performances is not responsible for the accuracy, usefulness, safety, legality, or
          intellectual property rights of or relating to any such content. You further understand and acknowledge that
          you may be exposed to content that is inaccurate, offensive, indecent, objectionable, or harassing, and you
          agree to waive, and do hereby waive, any legal or equitable rights or remedies you have or may have against
          any LIVENET INC/PEN Performances Party with respect thereto.
        </strong>
        <strong />
      </p>
      <p>
        e.
        <strong>
          To the fullest extent permitted by applicable law, you acknowledge and agree that in no event will any LIVENET
          INC/PEN Performances Party be liable to you or to any third party for any indirect, special, incidental,
          punitive, or consequential damages (including for loss of profits, revenue, or data) or for the cost of
          obtaining substitute products, arising out of or in connection with these Terms, however caused, whether such
          liability arises from any claim based upon contract, warranty, tort (including negligence), strict liability
          or otherwise, and whether or not LIVENET INC/PEN Performances has been advised of the possibility of such
          damages.
        </strong>
        <strong />
      </p>
      <p>
        f.
        <strong>
          To the maximum extent permitted by applicable law, our total cumulative liability to you or any third party
          under these Terms, including from all causes of action and all theories of liability, will be limited to and
          will not exceed the fees actually received by LIVENET INC/PEN Performances from you during the 12 months
          preceding the claim giving rise to such liability.
        </strong>
        <strong />
      </p>
      <p>
        g.
        <strong>
          Certain jurisdictions do not allow the exclusion or limitation of certain damages. If those laws apply to you,
          some or all of the above exclusions or limitations may not apply to you, and you might have additional rights.
        </strong>
        <strong />
      </p>
      <p>
        h.
        <strong>
          You agree that the limitations of damages set forth above are fundamental elements of the basis of the bargain
          between LIVENET INC/PEN Performances and you.
        </strong>
        <strong />
      </p>
      <p>
        18. <strong>Indemnification</strong>
        <strong />
      </p>
      <p>
        You agree to indemnify, defend, and hold harmless LIVENET INC/PEN Performances and its parents, subsidiaries,
        affiliates, officers, employees, directors, shareholders, agents, partners, and licensors (each, a “LIVENET
        INC/PEN Performances Party, “ and collectively, “LIVENET INC/PEN Performances Parties”) from and against any and
        all claims, costs, proceedings, demands, losses, damages, and expenses (including reasonable attorneys’ fees and
        costs) of any kind or nature, arising from, out of, in connection with, or relating to: (a) these Terms; (b) use
        of our Site; (c) your negligence, misconduct, or fraud; (d) any action or inaction by you or anyone acting on
        your behalf; (e) any Organization or Affiliated Talent; (f) any Charity; (g) your status as a parent or legal
        guardian of a Talent User; (h) Talent Content; (i) Feedback; (j) your eligibility with the NCAA or any other
        association, group, authority, or organization referenced in <u>Section 9.b</u>; or (k) your participation in
        the Associates Program. LIVENET INC/PEN Performances may select counsel and control the defense of any claim
        that you are indemnifying. You will reasonably cooperate with us in connection with any claim.
      </p>
      <p>
        19. <strong>Arbitration Agreement and Waiver of Certain Rights</strong> <strong />
      </p>
      <p>
        a. <u>Arbitration</u>: You and LIVENET INC/PEN Performances agree to resolve any disputes between you and
        LIVENET INC/PEN Performances through binding and final arbitration instead of through court proceedings. You and
        LIVENET INC/PEN Performances each hereby waive any right to a jury trial of any controversy, claim,
        counterclaim, or other dispute arising between you and LIVENET INC/PEN Performances relating to these Terms or
        our Site (each a “Claim,” and collectively, “Claims”). Any Claim will be submitted for binding arbitration in
        accordance with the Rules of the American Arbitration Association (“AAA Rules”). The arbitration will be heard
        and determined by a single arbitrator. The arbitrator’s decision will be in writing, will include the
        arbitrator’s reasons for the decision, will be final and binding upon the parties, and may be enforced in any
        court of competent jurisdiction. The parties agree that the arbitration will be kept confidential and that the
        existence of the proceeding and any element of it (including any pleadings, briefs or other documents submitted
        or exchanged, any testimony or other oral submissions, and awards) will not be disclosed beyond the arbitration
        proceedings, except as may lawfully be required in judicial proceedings relating to the arbitration or by
        applicable disclosure rules and regulations of securities regulatory authorities or other governmental agencies.
      </p>
      <p>
        b. <u>Costs and Fees</u>: If you demonstrate that the costs of arbitration will be prohibitive as compared to
        the costs of litigation, LIVENET INC/PEN Performances will pay as much of the administrative costs and
        arbitrator’s fees required for the arbitration as the arbitrator deems necessary to prevent the cost of the
        arbitration from being prohibitive. In the final award, the arbitrator may apportion the costs of arbitration
        and the compensation of the arbitrator among the parties in such amounts as the arbitrator deems appropriate.
      </p>
      <p>
        c. <u>No Preclusions</u>: This arbitration agreement does not preclude you or LIVENET INC/PEN Performances from
        seeking action by federal, state, or local government agencies. You and LIVENET INC/PEN Performances each also
        have the right to bring any qualifying Claim in small claims court. In addition, you and LIVENET INC/PEN
        Performances each retain the right to apply to any court of competent jurisdiction for provisional relief,
        including pre-arbitral attachments or preliminary injunctions, and any such request will not be deemed to be
        either incompatible with these Terms or a waiver of the right to have disputes submitted to arbitration as
        provided in these Terms.
      </p>
      <p>
        d. <u>No Class Representative or Private Attorney General</u>: Each of you and LIVENET INC/PEN Performances
        agree that with respect to any Claim, neither may: (i) act as a class representative or private attorney
        general; or (ii) participate as a member of a class of claimants. You agree that no Claim may be arbitrated on a
        class or representative basis. The arbitrator can decide only individual Claims (whether brought by you or
        LIVENET INC/PEN Performances). The arbitrator may not consolidate or join the claims of other persons or parties
        who may be similarly situated.
      </p>
      <p>
        e. <u>Severability/No Waiver/Survival</u>: If any provision of this <u>Section 19</u> is found to be invalid or
        unenforceable, that provision will be deemed appropriately modified to give effect to the intent of the
        provision or, if modification is not possible, will be severed and the remainder of this <u>Section 19</u> will
        continue in full force and effect. No waiver of any provision of this <u>Section 19</u> will be effective or
        enforceable unless recorded in a writing signed by the party waiving such a right or requirement. Such a waiver
        will not waive or affect any other provision of these Terms. This <u>Section 19</u> will survive the termination
        of your relationship with LIVENET INC/PEN Performances.
      </p>
      <p>
        f. 30-Day Opt-Out Right: You have the right to opt out of the provisions of this Arbitration Agreement by
        sending, within 30 days after first becoming subject to this Arbitration Agreement, written notice of your
        decision to opt out to the following address: LIVENET INC/PEN Performances, c/o Legal Department, 400 N.
        Aberdeen Street, Chicago, IL 60642. Your notice must include your name and address, any usernames, each email
        address you have used to set up an account on our Site, and an unequivocal statement that you want to opt out of
        this Arbitration Agreement. You agree that if you opt out of this Arbitration Agreement, all other parts of
        these Terms will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any other
        arbitration agreements that you may currently have, or may enter in the future, with us.
      </p>
      <p>
        g.{' '}
        <strong>
          <u>LIMITATIONS</u>
        </strong>
        <strong>: This </strong>{' '}
        <strong>
          <u>Section 19</u>
        </strong>
        <strong>
          limits certain rights, including the right to maintain certain court actions, the right to a jury trial, the
          right to participate in any form of class or representative claim, the right to engage in discovery except as
          provided in AAA rules, and the right to certain remedies and forms of relief. In addition, other rights that
          you or LIVENET INC/PEN Performances would have in court may not be available in arbitration.
        </strong>
      </p>
      <p>
        20. <strong>Other Provisions</strong>
        <strong />
      </p>
      <p>
        a. <u>Force Majeure</u>: Under no circumstances will any LIVENET INC/PEN Performances Party be liable for any
        delay or failure in performance due in whole or in part to any acts of God (such as earthquakes, storms, floods,
        etc.), unavoidable accidents, laws, rules, regulations or orders of government authorities, acts of war
        (declared or not), terrorism, hostilities, blockades, civil disturbances, embargoes, strikes, or any other event
        or cause beyond the reasonable control of any LIVENET INC/PEN Performances Party.
      </p>
      <p>
        b. <u>Choice of Law and Jurisdiction</u>: These Terms will be governed by and construed in accordance with the
        laws of the State of Illinois, without giving effect to any conflict of laws rules or provisions. You agree that
        any action of whatever nature arising from or relating to these Terms or our Site will be filed only in the
        state or federal courts located in Chicago, Illinois. You consent and submit to the personal jurisdiction of
        such courts for the purposes of any such action.
      </p>
      <p>
        c. <u>Severability</u>: If any provision of these Terms is found to be invalid or unenforceable, that provision
        will be deemed appropriately modified to give effect to the intent of the provision or, if modification is not
        possible, will be severed from these Terms and will not affect the enforceability of any other provision.
      </p>
      <p>
        d. <u>No Waiver or Amendment</u>: The failure by LIVENET INC/PEN Performances to enforce any right or provision
        of these Terms will not prevent LIVENET INC/PEN Performances from enforcing such right or provision in the
        future and will not be deemed to modify these Terms.
      </p>
      <p>
        e. <u>Assignment</u>: LIVENET INC/PEN Performances may, at any time, assign its rights and obligations under
        these Terms, including to an affiliated entity or in connection with a sale of assets, merger, acquisition,
        reorganization, bankruptcy, other transaction, or by operation of law.
      </p>
      <p>
        f. <u>Miscellaneous</u>: The term “including” in these Terms will be interpreted broadly and will mean
        “including, without limitation.” Titles are for convenience only and will not be considered when interpreting
        these Terms.
      </p>
      <p>
        21. <strong>Changes to these Terms</strong>
        <strong />
      </p>
      <p>
        We may change these Terms. If we do, we will post the revised Terms on our Site and update the “Last Updated”
        date at the top of these Terms. The revised Terms will be effective immediately if you accept them (for example,
        by agreeing when you create an account or login to an existing account, or using or continuing to use our Site
        after the revised Terms have been posted); otherwise, they will be effective 30 days after posting.
      </p>
      <br />
      <br />
      <p>
        <strong>Additional Talent Terms</strong>
        <strong />
      </p>
      <p>
        <strong>
          PLEASE READ THE FOLLOWING CAREFULLY. By offering the product or service, you agree to the applicable
          additional terms that apply (which are Additional Terms as described in Section [
        </strong>
        <strong>
          <u>8</u>
        </strong>
        <strong>
          ] of the Talent Terms of Service (“Talent Terms”)). These Additional Terms will be governed by, and are
          incorporated into, the Talent Terms. Terms that are defined in the Talent Terms or the Site Terms of Service
          (“Site Terms”) will have the same meaning in these Additional Terms.
        </strong>
      </p>
      <p>
        From time to time, we may change these Additional Terms. If we do, we will give you notice by posting them on
        our Site and updating the “Last Updated” date. The revised Additional Terms will be effective immediately. By
        offering or using or continuing to offer or use the applicable product or service, you represent and warrant
        that you have read, understand, and agree to these Additional Terms.
      </p>
      <p>
        1. <strong>LIVENET INC/PEN Performances Live Content</strong>
      </p>
      <p>
        Any materials, rights of publicity, performances, participation (including any chat, comments, statements,
        audio, or other communication), or other content that you provide in connection with your participation in the
        Event (“Event Content”) will be Talent Content under the Talent Terms.
      </p>
      <p>a. You are responsible for your Event Content.</p>
      <p>
        i. Your Event Content will not be private and you should not share or disclose any personal, private, or
        sensitive information relating to you or a third party
      </p>
      <p>
        ii. In addition to the <u>Talent Terms</u>, Event Content is subject to the Acceptable Use Policy : and may not,
        among other things: violate, infringe, or misappropriate the intellectual property, privacy, publicity, moral or
        “droit moral,” or other legal rights of any third party; or communicate anything that is, or that incites or
        encourages action that is, explicitly or implicitly: illegal, abusive, harassing, threatening, hateful, racist,
        derogatory, harmful to any reputation, pornographic, indecent, profane, obscene, or otherwise objectionable
        (including nudity).
      </p>
      <p>
        b. In addition to the rights you grant under the<u> Talent Terms</u>, you grant:
      </p>
      <p>
        i. to LIVENET INC/PEN Performances an exclusive (including exclusive as to you, but excluding the license
        granted to any platform or media as set out below), royalty-free, fully paid, unlimited, universal,
        sublicensable (through multiple tiers of sublicenses), perpetual, and irrevocable license in any and all manner
        and media, whether now known or hereinafter invented or devised to use, publish, reproduce, record, modify,
        edit, and display your name, image, voice, appearance, performance, likeness, and Event Content, including in or
        in connection with advertising, marketing, promoting, republishing, rebroadcasting, and re-airing; provided that
        LIVENET INC/PEN Performances will not use your Event Content in or in connection with advertising or marketing
        without your consent.
      </p>
      <p>
        ii. to any platform or media on which the Talent Content is stored, performed, recorded, republished,
        rebroadcast, re-aired, or displayed any rights necessary or required by the platform or media in order to store,
        perform, record, republish, rebroadcast, re-air, or display the Talent Content.
      </p>
      <p>
        c. You acknowledge and agree that Event Content may be edited or modified (e.g., add music or captions, capture
        screenshot or excerpts, or otherwise) and Event Content (e.g., an edited or modified version thereof) may be
        included in a compilation with content from or featuring others.
      </p>
      <p>
        2. <strong>Payment</strong>
      </p>
      <p>
        a. On completion of each paid Event, as provided in these Additional Terms, we will pay you 75% of the
        applicable price actually received by LIVENET INC/PEN Performances after subtracting any payment to, or
        deduction by, the application platform on the same terms as in <u>Section 4.b</u> of Talent Terms of Service.
      </p>
      <p>
        <strong>LIVENET INC/PEN PERFORMANCES DIRECT</strong>
      </p>
      <p>Last Updated: August 13, 2020</p>
      <p>
        1. <strong>LIVENET INC/PEN PERFORMANCES DIRECT</strong>
      </p>
      <p>
        You may choose to offer Users the opportunity to send you a paid direct message as permitted through the LIVENET
        INC/PEN Performances Direct feature in the App (if the feature is available).
      </p>
      <p>a. If you choose to do so, a User may send you:</p>
      <p>
        i. a User-created message (for example, a message of up to a specified number of characters, a video file of up
        to specified size, etc.) (each such message, and any response sent through the LIVENET INC/PEN Performances
        Direct feature, a “Message”); or
      </p>
      <p>
        ii. a sticker from among a collection of images made available by LIVENET INC/PEN Performances (each such image,
        a “Sticker”).
      </p>
      <p>
        b. When a User sends you a Message or Sticker, you may, but have no obligation to, respond. In addition, you may
        close out the thread at any time.
      </p>
      <p>c. Any additional Message or Sticker sent by the User will be at the applicable price.</p>
      <p>
        2. <strong>Payment</strong>
      </p>
      <p>
        a. You set your price for a Message, provided your price on the App for iOS must be an available Apple SKU
        (i.e., ending in US$0.99). LIVENET INC/PEN Performances sets the price for each Sticker.
      </p>
      <p>
        b. We will pay you 75% of the applicable price actually received by LIVENET INC/PEN Performances for each paid
        Message or Sticker after subtracting any payment to, or deduction by, the application platform (e.g., Apple
        deducts a 30% commission) on the same terms as in Section <u>4.b</u>.
      </p>
      <p>
        3. <strong>Acknowledgement</strong>
      </p>
      <p>You acknowledge and agree that:</p>
      <p>
        a. each Message you create is Talent Content under the <u>Talent Terms </u>
        and each Message from a User is a Submission owned by the User who created it
      </p>
      <p>b. each Message (whether created by you or a User) is subject to the Acceptable Use Policy;</p>
      <p>
        c. you grant to each User the right to use each Message you create a non-exclusive, royalty-free, fully paid,
        worldwide, sublicensable, and perpetual license to use, reproduce, distribute, and publicly display that
        Message, in each case, solely in accordance with the Site Terms and the Additional Site Terms for “LIVENET
        INC/PEN Performances Direct” in any and all media, whether now known or hereafter invented or devised (including
        social media channels and third party websites and platforms); and
      </p>
      <p>
        d. LIVENET INC/PEN Performances will not be liable or responsible for any Submission (including any Message or
        Sticker from the User) or any Talent Content (including any Message you send).
      </p>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export { TermsOfService };
