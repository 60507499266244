import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ModalPicker from '../../ModalPicker';
import { ReactComponent as VipAccessSVG } from './vip-access.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setVipCode } from '../../../store/user/userActions';
import { userSelector, userVipSelector } from '../../../store/user/userSlice';
import { getFullArtistList, getFeaturedArtists } from '../../../store/artists/artistsActions';

const VIP_ACCESS_HEADER_COLOR = '#000000';
const VIP_ACCESS_HEADER_WIDTH = 500;
const VIP_ACCESS_INPUT_LENGTH = 6;

interface VipAccessCodeInputProps {
  isOpen: boolean;
  handleClose: () => void;
}

export function VipAccessCodeInput({ isOpen = false, handleClose }: VipAccessCodeInputProps) {
  const vipCode = useAppSelector(userVipSelector);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const [inputVipCode, setInputVipCode] = useState<string>(vipCode);

  const user = useAppSelector(userSelector);
  const { vipCodeLoading, vipIsValidCode, vipIsInvalidCode } = user;

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const response = await dispatch(setVipCode(inputVipCode));

    const status = response.payload.vipCodeStatus;
    if (status === 200) {
      dispatch(getFullArtistList());
      dispatch(getFeaturedArtists());
      history.push('/roster');
      handleClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ModalPicker isOpen={isOpen} handleClose={handleClose} showCloseBtn={false} className="bg-gray-500">
      <div className=" flex items-center px-6 text-lg flex-col relative">
        <form onSubmit={handleSubmit} className="p-4">
          <label htmlFor="vipCode" className="text-white px-2 uppercase">
            VIP Code:
            <input
              id="vipCode"
              name="vipCode"
              maxLength={VIP_ACCESS_INPUT_LENGTH}
              minLength={VIP_ACCESS_INPUT_LENGTH}
              required
              className="ml-2 px-2 text-black text-xl"
              value={inputVipCode}
              onChange={e => setInputVipCode(e.target.value.toUpperCase())}
            />
          </label>
          <button type="submit" className="text-white mx-2 px-2 bg-gray-700" disabled={vipCodeLoading}>
            Submit
          </button>
          <button type="button" className="text-white absolute top-0 right-2" onClick={handleClose}>
            X
          </button>
        </form>
        {vipCodeLoading && <div className="text-white w-full text-right">Verifying code...</div>}
        {vipIsValidCode && <div className="text-white w-full text-right">Valid code</div>}
        {vipIsInvalidCode && <div className="text-white w-full text-right">Invalid code please add a new one</div>}
      </div>
    </ModalPicker>
  );
}

export function VipAccessFooterButton() {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <button
        type="button"
        className="text-lg text-left h-5 hover:text-black"
        onClick={() => {
          setIsVisible(true);
        }}
      >
        VIP Access
      </button>
      <VipAccessCodeInput isOpen={isVisible} handleClose={() => setIsVisible(false)} />
    </>
  );
}

export function VipAccessHeaderButton() {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="ml-24">
      <VipAccessSVG
        color={VIP_ACCESS_HEADER_COLOR}
        width={VIP_ACCESS_HEADER_WIDTH}
        className="hover:bg-gray-200 hover:opacity-90 w-24 p-2"
        onClick={() => setIsVisible(true)}
      />
      <VipAccessCodeInput isOpen={isVisible} handleClose={() => setIsVisible(false)} />
    </div>
  );
}

export function VipAccessMenuButton() {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="hover:text-blue-600">
      <button type="button" className="uppercase w-full text-center" onClick={() => setIsVisible(true)}>
        Vip Access
      </button>
      <VipAccessCodeInput isOpen={isVisible} handleClose={() => setIsVisible(false)} />
    </div>
  );
}
