/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { userRoleSelector } from '../store/user/userSlice';
import { UserRoles } from '../types';

export function withRoleAccess(WrappedComponent: any, allowedRoles: UserRoles[], fallbackComponent: any) {
  const ComponentWithRole = (props: any) => {
    const currentRole = useSelector(userRoleSelector) as UserRoles;
    if (allowedRoles.includes(currentRole)) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...props} />;
    }
    return fallbackComponent;
  };
  ComponentWithRole.displayName = WrappedComponent.displayName ?? 'ComponentWithRole';
  return ComponentWithRole;
}

export const ArtistOnlyRoute = withRoleAccess(
  Route,
  [UserRoles.artist],
  <Redirect
    to={{
      pathname: '/',
    }}
  />,
);
