import { useState } from 'react';

interface IListItem {
  id: string;
  value: string;
  label: string;
  onChange(id: string, value: boolean): void;
}

const ListItem = ({ id, value, label, onChange }: IListItem) => {
  const [cheked, setCheked] = useState(true);

  const changeHandler = () => {
    onChange(id, cheked);
    setCheked(!cheked);
  };

  return (
    <div className="flex ">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <span className="flex items-center text-white" onClick={changeHandler} role="button" tabIndex={0}>
        <input
          type="checkbox"
          id={id}
          value={value}
          onChange={changeHandler}
          className="form-checkbox h-3 w-3 text-gray-600 mr-3 cursor-pointer"
          checked={!cheked}
        />
        {label}
      </span>
    </div>
  );
};

export { ListItem };
