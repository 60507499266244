export interface IAvailabilityRow {
  startDate: string | undefined;
  endDate: string | undefined;
  selectedHours: TSelectedHours;
  selectedWeekDays: TSelectedWeekdays;
  showHourPicker: boolean;
  showWeekdayPicker: boolean;
  oldStartDate: string | undefined;
  oldEndDate: string | undefined;
}

export enum DaysOfWeek {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum HoursOfDay {
  '00:00' = '00:00',
  '01:00' = '01:00',
  '02:00' = '02:00',
  '03:00' = '03:00',
  '04:00' = '04:00',
  '05:00' = '05:00',
  '06:00' = '06:00',
  '07:00' = '07:00',
  '08:00' = '08:00',
  '09:00' = '09:00',
  '10:00' = '10:00',
  '11:00' = '11:00',
  '12:00' = '12:00',
  '13:00' = '13:00',
  '14:00' = '14:00',
  '15:00' = '15:00',
  '16:00' = '16:00',
  '17:00' = '17:00',
  '18:00' = '18:00',
  '19:00' = '19:00',
  '20:00' = '20:00',
  '21:00' = '21:00',
  '22:00' = '22:00',
  '23:00' = '23:00',
}

export type TSelectedHours = { [key in HoursOfDay]: boolean };
export type TSelectedWeekdays = { [key in DaysOfWeek]: boolean };
export interface IProfile {
  availability: { byId: { [key: string]: IAvailabilityRow }; allIds: string[] };
}
export interface IArtist {
  id: string;
  urlFriendlyId: string;
  firstName: string;
  lastName: string;
  paypalEmail: string;
  adminFacebookEmail: string;
  headline: string;
  profileImageSrc?: string;
  profileVideoUrl?: string;
  artistBio: string;
  productDescription: string;
  performancePrice: number;
  performanceDuration: string;
  timezone: string;
  categoryId: string;
  categories: string[] | undefined | null | [];
  privateProfile: boolean;
  email: string;
  nextPerformance?: Date | string;
  featured?: boolean;
  shortTitle: string;
  token?: string;
}

export interface ICategory {
  id: string;
  displayName: string;
  imageSrc: string;
  subcategories?: string[];
}

export interface ICategoryBanner {
  name: string;
  description: string;
  artists: IArtist[];
}

export enum UserRoles {
  'artist' = 'artist',
  'admin' = 'admin',
}

export interface ArtistsState {
  artists: TArtistsState;
  featured: IArtist[];
  isLoading: boolean;
}

export interface IUser {
  isLogged: boolean;
  email: string | undefined;
  username: string | undefined;
  isSignInProgress: boolean;
  role: UserRoles | undefined;
}

export interface IMessage {
  message: string | undefined;
}

export interface IVonageInfo {
  sessionId: string | undefined;
  apiKey: string | undefined;
  token: string | undefined;
  isLoading: boolean;
  message: string | undefined;
}

export interface IRootState {
  artists: ArtistsState;
  user: IUser;
  profile: IProfile;
  paypal: IMessage;
  vonage: IVonageInfo;
}

export interface PickerProps<T> {
  isOpen: boolean;
  initialState: T;
  handleClose(updatedState: T): void;
}

export interface IProduct {
  price: number;
  duration: string;
  description: string;
  img: string;
}

export interface IEmailOption {
  artistName: string;
  artistEmail: string;
  artistTime: string;
  artistPhone: string;
  clientName: string;
  clientEmail: string;
  clientTime: string;
  clientPhone: string;
  utcTime: string;
}

export interface IPenResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  message: string | undefined;
  success: boolean;
}

export type TArtistsState = {
  byId: { [key: string]: IArtist };
  allIds: string[];
  allIdsByCategory: { [category: string]: string[] };
};

export enum ArtistCardType {
  featured = 'featured',
  roster = 'roster',
  otherSingers = 'otherSinger',
}
