import { useState } from 'react';
import { Link } from 'react-router-dom';
import { VipAccessHeaderButton, VipAccessMenuButton } from './buttons/VipAccess/VipAccess';
import { ReactComponent as HamburgerMenuSVG } from '../assets/icons/hamburger-menu.svg';
import { ReactComponent as PENLogo } from '../assets/icons/pen-logo.svg';
import { userSignout } from '../store/user/userActions';
import { useAppDispatch, useAppSelector } from '../hooks';
import { userSelector } from '../store/user/userSlice';
import { FacebookLoginButton } from './buttons/FacebookLogin';

interface IPageHeader {
  color?: string;
  white?: boolean;
  position?: 'absolute' | 'relative';
  showVipAccessButton?: boolean;
  publicMenuCallback?: () => void;
}

interface IPublicMenu {
  publicMenuCallback: () => void;
}

const PublicMenu = ({ publicMenuCallback }: IPublicMenu) => {
  const findAPerformer = () => {
    if (window.location.pathname === '/roster') {
      publicMenuCallback();
    }
  };

  return (
    <>
      <Link to="/roster" className="hover:text-blue-600" onClick={findAPerformer}>
        Find a performer
      </Link>
      <VipAccessMenuButton />
      <FacebookLoginButton label="Artist Access" />
      <Link to="/technicalSupport" className="hover:text-blue-600">
        Technical Support
      </Link>
      <Link to="/contact" className="hover:text-blue-600">
        Contact Us
      </Link>
    </>
  );
};

const PrivateMenu = ({ handleSignOut }: { handleSignOut: () => void }) => {
  return (
    <>
      <button type="button" onClick={handleSignOut} className="hover:text-blue-600">
        Sign Out
      </button>
    </>
  );
};

export function PageHeader({
  color = 'text-blue-800',
  white = false,
  showVipAccessButton = false,
  position = 'relative',
  publicMenuCallback = () => {},
}: IPageHeader): JSX.Element {
  const [showMenu, setShowMenu] = useState(false);

  const { isLogged } = useAppSelector(userSelector);

  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    dispatch(userSignout());
  };

  return (
    <div className="relative z-20">
      <div className={`py-5 h-24 ${position} w-full flex flex-row justify-between`}>
        {showVipAccessButton ? <VipAccessHeaderButton /> : <div />}
        <div className="flex relative float-right px-2">
          <Link to="/">
            <h1 className={`text-2xl font-bold ${color}`}>
              <PENLogo width={185} height={30} />
            </h1>
          </Link>
          <button type="button" onClick={() => setShowMenu(prev => !prev)}>
            <HamburgerMenuSVG className="w-12 h-12 mx-4 my-2 select-none" fill={white ? '#fff' : '#000'} />
          </button>
          {showMenu && (
            <>
              <div className="fixed inset-0 z-10" onClick={() => setShowMenu(false)} aria-hidden="true" />
              <div
                className={`absolute w-full bg-gray-100 top-10 border-solid py-4 z-10 ${
                  white && 'text-black'
                } text-lg text-center grid grid-flow-row gap-6 uppercase`}
              >
                {isLogged ? (
                  <PrivateMenu handleSignOut={handleSignOut} />
                ) : (
                  <PublicMenu publicMenuCallback={publicMenuCallback} />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
