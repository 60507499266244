import { useFormContext } from 'react-hook-form';

/* eslint-disable react/jsx-props-no-spreading */
interface FormInputProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  name: string;
  id: string;
  helper?: string;
}

export function FormInput({ label, name, id, helper, ...rest }: FormInputProps) {
  const { register } = useFormContext();
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <div className="text-black mt-3">
        <input
          className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white"
          id={id}
          type="text"
          {...rest}
          {...register(name, { required: rest.required, maxLength: rest.maxLength })}
        />
        {helper}
      </div>
    </>
  );
}
