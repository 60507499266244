import { ArtistCardType, IArtist } from '../types';
import { ArtistCard } from './ArtistCard';

interface ICardGrid {
  columns?: number;
  gap?: number;
  displayFooter?: boolean;
  displayInfo?: boolean;
  artists: IArtist[];
  cardType: ArtistCardType;
  style?: React.CSSProperties;
  cardColorCategoryId?: string;
}

export const CardGrid = ({
  columns = 5,
  gap = 0,
  displayFooter = false,
  displayInfo = false,
  cardColorCategoryId,
  artists,
  cardType,
  style = {},
}: ICardGrid) => {
  const attributes = `grid grid-cols-${columns} ${gap > 0 ? `gap-${gap}` : ''}`;

  return (
    <div className={`mx-auto ${attributes}`} style={style}>
      {artists?.map(artist => {
        if (!artist?.id) return null;
        return (
          <ArtistCard
            artist={artist}
            key={artist.id}
            displayFooter={displayFooter}
            displayInfo={displayInfo}
            type={cardType}
            colorCategoryId={cardColorCategoryId}
          />
        );
      })}
    </div>
  );
};
