/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DayPickerRangeController, isSameDay } from 'react-dates';
import ReactModal from 'react-modal';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './calendar.css';
import { useState } from 'react';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { FormProvider, useForm } from 'react-hook-form';
import { PayPalButton } from '../PaypalButton';
import { IEmailOption, IProduct } from '../../types';
import { FormInput } from '../Form/FormInput';
import { FormTextArea } from '../Form/FormTextArea';
import { Calendar } from '../calendarDatePicker/Calendar';
import { EMAIL_TIME_FORMAT } from '../../utils/constants';
import { currentConfig } from '../../config';
import { DayContent } from './DayContent';

interface ICalendar {
  artistId?: string;
  artistName?: string;
  artistEmail?: string;
  artistTimezone?: string;
  isOpen: boolean;
  handleClose(): void;
  categoryId: string;
  product: IProduct;
  availableDates: string[];
  onChange(date: string): void;
  selectedDate: string;
}

const separateDate = (dates: string[]) => {
  return dates.map(date => date.split('T')[0]);
};

const parseDates = (dates: string[]) => {
  const datesWithoutTimes = separateDate(dates);
  return datesWithoutTimes.map(date => moment(date));
};

export default function ModalCalendar({
  artistId,
  artistName,
  artistEmail,
  artistTimezone,
  isOpen,
  handleClose,
  categoryId,
  product,
  availableDates,
  onChange,
  selectedDate,
}: ICalendar) {
  const parsedDates = parseDates(availableDates);
  const isDayHighlighted = (currDate: Moment) => {
    return parsedDates.some(date => isSameDay(currDate, date));
  };

  const initialValues = {
    email: '',
    comment: '',
  };

  const getArtistLocalTime = (date: string | undefined) => {
    if (date) {
      return `${moment
        .tz(moment(selectedDate).utc(), artistTimezone || '')
        .format(EMAIL_TIME_FORMAT)} - ${artistTimezone}`;
    }
    return `Not selected by the client - ${artistTimezone}`;
  };

  const getClientLocalTime = (date: string | undefined) => {
    if (date) {
      return `${moment(selectedDate).format(EMAIL_TIME_FORMAT)} - ${moment.tz.guess()}`;
    }
    return `Not selected by the client - ${moment.tz.guess()}`;
  };

  const getUTCTime = (date: string | undefined) => {
    if (date) {
      return moment(selectedDate).utc().toISOString();
    }
    return `Not selected by the client`;
  };

  const emailOptions: IEmailOption = {
    artistName: artistName || '',
    artistEmail: artistEmail || '',
    artistTime: getArtistLocalTime(selectedDate),
    artistPhone: '',
    clientName: '',
    clientEmail: '',
    clientTime: getClientLocalTime(selectedDate),
    clientPhone: '',
    utcTime: getUTCTime(selectedDate),
  };

  const startDate = new Date(moment().add(2, 'day').toString());

  const backgroundColor = `bg-${categoryId}`;
  const backgroundOverlayColor = `bg-overlay-${categoryId}`;
  const [formStep, setFromStep] = useState(1);
  const [paidFor, setPaidFor] = useState(false);
  const [datePerformance, setDatePerformance] = useState(startDate);
  const [loadingSpecialRequest, setLoadingSpecialRequest] = useState<boolean>(false);
  const [specialRequestMessage, setSpecialRequestMessage] = useState<string>('');
  const methods = useForm({ defaultValues: initialValues });

  const requestHandler = () => {
    setFromStep(0);
  };

  const requestPerformanceHandler = () => {
    setFromStep(3);
  };

  const onSubmitPerformanceRequest = async ({ email, comment }: { email: string; comment: string }) => {
    setLoadingSpecialRequest(true);
    const payload = {
      utcTime: moment(datePerformance).utc().toISOString(),
      artistName: artistName || '',
      artistEmail: artistEmail || '',
      artistTime: `${moment
        .tz(moment(datePerformance).utc(), artistTimezone || '')
        .format(EMAIL_TIME_FORMAT)} - ${artistTimezone}`,
      artistPhone: '',
      clientName: '',
      clientEmail: email,
      clientTime: `${moment(datePerformance).format(EMAIL_TIME_FORMAT)} - ${moment.tz.guess()}`,
      clientPhone: '',
      specialRequest: comment,
    };
    const body = JSON.stringify(payload);
    const sendRequest = async () => {
      const response = await fetch(`${currentConfig.API_URL}/performers/artist/special-request`, {
        method: 'POST',
        body,
      });

      const responseData = await response.json();
      setSpecialRequestMessage(responseData.message);
      methods.reset();
      setDatePerformance(startDate);
      setLoadingSpecialRequest(false);
    };
    await sendRequest();
    requestPerformanceHandler();
  };

  const nextStep = () => {
    setFromStep(formStep + 1);
  };

  const closeModal = () => {
    setFromStep(1);
    setPaidFor(false);
    handleClose();
  };

  const changeHandler = (date: string) => {
    onChange(date);
  };

  const specialRequestDone = () => {
    handleClose();
    setFromStep(1);
    setSpecialRequestMessage('');
  };

  const activeRequest = selectedDate !== '';

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      overlayClassName="fixed inset-0 flex items-center justify-center z-30"
      className={`${backgroundOverlayColor} bg-opacity-90 max-w-lg p-6 text-white outline-none`}
    >
      {formStep === 1 && (
        <>
          <div className="mt-4 text-white">CHOOSE A DAY & TIME</div>
          <div className="flex items-center justify-center">
            {
              // @ts-ignore: wrong ts error
              <DayPickerRangeController
                numberOfMonths={1}
                daySize={65}
                hideKeyboardShortcutsPanel
                renderDayContents={date => (
                  <DayContent
                    onChange={changeHandler}
                    availableDatesObj={availableDates}
                    date={date}
                    className={backgroundColor}
                    selectedDate={selectedDate}
                  />
                )}
                isDayBlocked={day => moment.weekdays(day.weekday()) === 'Friday'}
                isDayHighlighted={isDayHighlighted}
                renderMonthElement={({ month }) => {
                  return (
                    <div className={`flex flex-row font-base text-xl text-white bg-${categoryId} py-2 lowercase`}>
                      <div className="flex-1">{moment(month).format('MMMM YYYY')}</div>
                    </div>
                  );
                }}
                renderNavPrevButton={({ onClick }) => (
                  <button
                    type="button"
                    onClick={onClick}
                    className="absolute left-10 font-medium top-8 px-10 text-lg opacity-70"
                  >
                    {`<`}
                  </button>
                )}
                renderNavNextButton={({ onClick }) => (
                  <button
                    type="button"
                    onClick={onClick}
                    className="absolute right-10 font-medium top-8 px-10 text-lg opacity-70"
                  >
                    {`>`}
                  </button>
                )}
                renderWeekHeaderElement={day => {
                  const weekdays: { [key: string]: string } = {
                    su: 'sun',
                    mo: 'mon',
                    tu: 'tues',
                    we: 'wed',
                    th: 'thur',
                    fr: 'fri',
                    sa: 'sat',
                  };
                  return <div className="mt-3 uppercase text-white">{weekdays[day.toLowerCase()]}</div>;
                }}
                noBorder
              />
            }
          </div>
          <div className="text-white mt-4">
            <div className="flex mr-8">
              <div className="bottom-left">
                <div className="m-3 leading-4 text-center pr-6">
                  If you would like to request a day & time that&apos;s not currently available, please enter it here
                </div>
              </div>
              <div className="bottom-right">
                <div className="flex justify-center items-center h-full">
                  <div className={`w-6 h-6 mx-2 ${backgroundColor}`} />
                  <div>available</div>
                </div>
              </div>
            </div>
            <div className="flex justify-around my-4 -mr-5">
              <button className={`${backgroundColor} px-4 py-1`} type="button" onClick={requestHandler}>
                Request
              </button>
              <button
                className={`${backgroundColor} px-4 py-1 disabled:opacity-40`}
                type="button"
                onClick={() => nextStep()}
                disabled={!activeRequest}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}

      {formStep === 2 && (
        <>
          <button
            type="button"
            className="text-2xl w-10 rounded-md bg-gray-900 bg-opacity-10 hover:bg-opacity-30"
            onClick={() => setFromStep(1)}
          >
            ᐸ
          </button>
          <div className="mt-4 text-white">Payment Checkout</div>
          <div className="flex items-center justify-center">
            <PayPalButton
              artistId={artistId}
              paidFor={paidFor}
              setPaidFor={setPaidFor}
              product={product}
              emailOptions={emailOptions}
            />
          </div>
          {paidFor && (
            <div className="flex justify-around my-4 -mr-5">
              <button className={`${backgroundColor} px-4 py-1`} type="button" onClick={() => closeModal()}>
                Complete
              </button>
            </div>
          )}
        </>
      )}
      {formStep === 0 && (
        <div className="w-96 relative">
          <button
            type="button"
            className="text-2xl w-10 rounded-md bg-gray-900 bg-opacity-10 hover:bg-opacity-30"
            onClick={() => setFromStep(1)}
          >
            ᐸ
          </button>
          <div className="text-lg text-center mb-6">REQUEST PERFORMANCE</div>
          <FormProvider {...methods}>
            <form className="p-5" onSubmit={methods.handleSubmit(onSubmitPerformanceRequest)}>
              <FormInput label="Email:" name="email" id="email" maxLength={244} required type="email" />
              <Calendar
                date={datePerformance}
                setDate={setDatePerformance}
                showHours
                label="Date: "
                required
                startDate={startDate}
              />
              <FormTextArea label="Comment:" name="comment" id="comment" maxLength={200} required />
              <input type="submit" className={`${backgroundColor} px-4 py-1 mx-auto`} />
            </form>
          </FormProvider>
          {loadingSpecialRequest && <div className="text-right">Requesting a special performance</div>}
        </div>
      )}
      {formStep === 3 && (
        <div className="flex">
          <div className="text-center mx-4">{specialRequestMessage}</div>
          <button className={`${backgroundColor} px-4 py-1`} type="button" onClick={specialRequestDone}>
            Done
          </button>
        </div>
      )}
    </ReactModal>
  );
}
