import { PageHeader } from '../../components';
import { Footer } from '../../components/Footer';

const PrivacyPolicy = () => {
  return (
    <div className="container max-w-lg min-w-lg mx-auto">
      <PageHeader color="text-black" />
      <div data-custom-class="body">
        <div>
          <strong>PRIVACY NOTICE</strong>
        </div>
        <div>
          <strong>Last updated May 27, 2021</strong>
        </div>
        <div>
          Thank you for choosing to be part of our community at PEN. We are committed to protecting your personal
          information and your right to privacy. If you have any questions or concerns about this privacy notice, or our
          practices with regards to your personal information, please contact us at pen@penperformances.com.
        </div>
        <div>
          When you and more generally, use any of our services (the &quot;<strong>Services</strong>&quot;, which include
          the&nbsp;), we appreciate that you are trusting us with your personal information. We take your privacy very
          seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we
          collect, how we use it and what rights you have in relation to it. We hope you take some time to read through
          it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with,
          please discontinue use of our Services immediately.
        </div>
        <div>
          This privacy notice applies to all information collected through our Services (which, as described above,
          includes our&nbsp;), as well as, any related services, sales, marketing or events.
        </div>
        <div>
          <strong>
            Please read this privacy notice carefully as it will help you understand what we do with the information
            that we collect.
          </strong>
        </div>
        <div>
          <strong>TABLE OF CONTENTS</strong>
        </div>
        <div>
          <a href="#infocollect" data-custom-class="link">
            1. WHAT INFORMATION DO WE COLLECT?
          </a>
        </div>
        <div>
          <a href="#infouse" data-custom-class="link">
            2. HOW DO WE USE YOUR INFORMATION?
          </a>
        </div>
        <div>
          <a href="#infoshare" data-custom-class="link">
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </a>
        </div>
        <div>
          <a href="#whoshare" data-custom-class="link">
            4. WHO WILL YOUR INFORMATION BE SHARED WITH?
          </a>
        </div>
        <div>
          <a href="#cookies" data-custom-class="link">
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
        </div>
        <div>
          <a href="#sociallogins" data-custom-class="link">
            6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </a>
        </div>
        <div>
          <a href="#inforetain" data-custom-class="link">
            7. HOW LONG DO WE KEEP YOUR INFORMATION?
          </a>
        </div>
        <div>
          <a href="#infosafe" data-custom-class="link">
            8. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </a>
        </div>
        <div>
          <a href="#infominors" data-custom-class="link">
            9. DO WE COLLECT INFORMATION FROM MINORS?
          </a>
        </div>
        <div>
          <a href="#privacyrights" data-custom-class="link">
            10. WHAT ARE YOUR PRIVACY RIGHTS?
          </a>
        </div>
        <div>
          <a href="#DNT" data-custom-class="link">
            11. CONTROLS FOR DO-NOT-TRACK FEATURES
          </a>
        </div>
        <div>
          <a href="#caresidents" data-custom-class="link">
            12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </div>
        <div>
          <a href="#policyupdates" data-custom-class="link">
            13. DO WE MAKE UPDATES TO THIS NOTICE?
          </a>
        </div>
        <div>
          <a href="#contact" data-custom-class="link">
            14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
        </div>
        <div>
          <a href="#request" data-custom-class="link">
            15. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
          </a>
        </div>
        <div>
          <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
        </div>
        <div>
          <strong>
            <u>
              <br />
            </u>
          </strong>
          <strong>Personal information you disclose to us</strong>
        </div>
        <div>
          <div>
            <strong>
              <em>In Short:&nbsp;</em>
            </strong>
            <strong />
            <em>We collect personal information that you provide to us.</em>
          </div>
        </div>
        <div>
          We collect personal information that you voluntarily provide to us when you express an interest in obtaining
          information about us or our products and Services, when you participate in activities on the &nbsp;or
          otherwise when you contact us.
        </div>
        <div>
          The personal information that we collect depends on the context of your interactions with us and the , the
          choices you make and the products and features you use. The personal information we collect may include the
          following:
        </div>
        <div>
          <strong>Personal Information Provided by You.</strong> We collect names;&nbsp;phone numbers;&nbsp;email
          addresses;&nbsp;mailing addresses;&nbsp;and other similar information.
        </div>
        <div>
          <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases,
          such as your payment instrument number (such as a credit card number), and the security code associated with
          your payment instrument. All payment data is stored by Paypal. You may find their privacy notice link(s) here:{' '}
          <a
            href="https://www.paypal.com/en/webapps/mpp/ua/privacy-full"
            target="_blank"
            data-custom-class="link"
            rel="noreferrer"
          >
            https://www.paypal.com/en/webapps/mpp/ua/privacy-full
          </a>
          .
        </div>
        <div>
          <strong>Social Media Login Data.&nbsp;</strong>We may provide you with the option to register with us using
          your existing social media account details, like your Facebook, Twitter or other social media account. If you
          choose to register in this way, we will collect the information described in the section called &quot;
          <a href="#sociallogins" data-custom-class="link">
            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </a>
          &quot; below.
        </div>
        <div>
          All personal information that you provide to us must be true, complete and accurate, and you must notify us of
          any changes to such personal information.
        </div>
        <div>
          <strong>
            <u>
              <br />
            </u>
          </strong>
          <strong>Information automatically collected</strong>
        </div>
        <div>
          <div>
            <strong>
              <em>In Short:&nbsp;</em>
            </strong>
            <strong />
            <em>
              Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device
              characteristics &mdash; is collected automatically when you visit our.
            </em>
          </div>
        </div>
        <div>
          We automatically collect certain information when you visit, use or navigate the. This information does not
          reveal your specific identity (like your name or contact information) but may include device and usage
          information, such as your IP address, browser and device characteristics, operating system, language
          preferences, referring URLs, device name, country, location, information about how and when you use our and
          other technical information. This information is primarily needed to maintain the security and operation of
          our , and for our internal analytics and reporting purposes.
        </div>
        <div>Like many businesses, we also collect information through cookies and similar technologies. </div>
        <div>The information we collect includes:</div>
        <ul>
          <li>
            <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage and performance
            information our servers automatically collect when you access or use our and which we record in log files.
            Depending on how you interact with us, this log data may include your IP address, device information,
            browser type and settings and information about your activity in the &nbsp;(such as the date/time stamps
            associated with your usage, pages and files viewed, searches and other actions you take such as which
            features you use), device event information (such as system activity, error reports (sometimes called
            &apos;crash dumps&apos;) and hardware settings).
          </li>
        </ul>
        <ul>
          <li>
            <em>Device Data.</em> We collect device data such as information about your computer, phone, tablet or other
            device you use to access the. Depending on the device used, this device data may include information such as
            your IP address (or proxy server), device and application identification numbers, location, browser type,
            hardware model Internet service provider and/or mobile carrier, operating system and system configuration
            information.
          </li>
        </ul>
        <ul>
          <li>
            <em>Location Data.</em> We collect location data such as information about your device&apos;s location, be
            be either precise or imprecise. How much information we collect depends on the type and settings of the
            device you use to access the. For example, we may use GPS and other technologies to collect geolocation data
            that tells us your current location (based on your IP address). You can opt out of allowing us to collect
            this information either by refusing access to the information or by disabling your Location setting on your
            device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.
          </li>
        </ul>
        <div>
          <strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
        </div>
        <div>
          <div>
            <strong>
              <em>In Short: </em>
            </strong>
            <em>
              We process your information for purposes based on legitimate business interests, the fulfillment of our
              contract with you, compliance with our legal obligations, and/or your consent.
            </em>
          </div>
        </div>
        <div>
          We use personal information collected via our for a variety of business purposes described below. We process
          your personal information for these purposes in reliance on our legitimate business interests, in order to
          enter into or perform a contract with you, with your consent, and/or for compliance with our legal
          obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
        </div>
        <div>We use the information we collect or receive:</div>
        <ul>
          <li>
            <strong>To facilitate account creation and logon process.</strong> If you choose to link your account with
            us to a third-party account (such as your Google or Facebook account), we use the information you allowed us
            to collect from those third parties to facilitate account creation and logon process for the performance of
            the contract.&nbsp;See the section below headed &quot;
            <a href="#sociallogins" data-custom-class="link">
              HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </a>
            &quot; for further information.
          </li>
        </ul>
        <ul>
          <li>
            <strong>To post testimonials.</strong> We post testimonials on our that may contain personal information.
            Prior to posting a testimonial, we will obtain your consent to use your name and the content of the
            testimonial. If you wish to update, or delete your testimonial, please contact us at
            privacy@penperformances.com and be sure to include your name, testimonial location, and contact information.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Request feedback.&nbsp;</strong>We may use your information to request feedback and to contact you
            about your use of our.
          </li>
        </ul>
        <ul>
          <li>
            <strong>To enable user-to-user communications.</strong> We may use your information in order to enable
            user-to-user communications with each user&apos;s consent.
          </li>
        </ul>
        <ul>
          <li>
            <strong>To manage user accounts.&nbsp;</strong>We may use your information for the purposes of managing our
            account and keeping it in working order.
          </li>
        </ul>
        <ul>
          <li>
            <strong>To send administrative information to you.&nbsp;</strong>We may use your personal information to
            send you product, service and new feature information and/or information about changes to our terms,
            conditions, and policies.
          </li>
        </ul>
        <ul>
          <li>
            <strong>To protect our Services.&nbsp;</strong>We may use your information as part of our efforts to keep
            our safe and secure (for example, for fraud monitoring and prevention).
          </li>
        </ul>
        <ul>
          <li>
            <strong>
              To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory
              requirements or in connection with our contract.
            </strong>
          </li>
        </ul>
        <ul>
          <li>
            <strong>To respond to legal requests and prevent harm.&nbsp;</strong>If we receive a subpoena or other legal
            request, we may need to inspect the data we hold to determine how to respond.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Fulfill and manage your orders.&nbsp;</strong>We may use your information to fulfill and manage your
            orders, payments, returns, and exchanges made through the.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Administer prize draws and competitions.</strong> We may use your information to administer prize
            draws and competitions when you elect to participate in our competitions.
          </li>
        </ul>
        <ul>
          <li>
            <strong>To deliver and facilitate delivery of services to the user.</strong> We may use your information to
            provide you with the requested service.
          </li>
        </ul>
        <ul>
          <li>
            <strong>To respond to user inquiries/offer support to users.</strong> We may use your information to respond
            to your inquiries and solve any potential issues you might have with the use of our Services.
          </li>
        </ul>
        <ul>
          <li>
            <strong>To send you marketing and promotional communications.</strong> We and/or our third-party marketing
            partners may use the personal information you send to us for our marketing purposes, if this is in
            accordance with your marketing preferences. For example, when expressing an interest in obtaining
            information about us or our , subscribing to marketing or otherwise contacting us, we will collect personal
            information from you. You can opt-out of our marketing emails at any time (see the &quot;
            <a href="#privacyrights" data-custom-class="link">
              WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
            &quot; below).
          </li>
        </ul>
        <ul>
          <li>
            <strong>Deliver targeted advertising to you.</strong>&nbsp;We may use your information to develop and
            display personalized content and advertising (and work with third parties who do so) tailored to your
            interests and/or location and to measure its effectiveness.
          </li>
        </ul>
        <ul>
          <li>
            <strong>For other business purposes.</strong>&nbsp;We may use your information for other business purposes,
            such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns
            and to evaluate and improve our , products, marketing and your experience. We may use and store this
            information in aggregated and anonymized form so that it is not associated with individual end users and
            does not include personal information. We will not use identifiable personal information without your
            consent.
          </li>
        </ul>
        <div>
          <strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
        </div>
        <div>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            {' '}
            We only share information with your consent, to comply with laws, to provide you with services, to protect
            your rights, or to fulfill business obligations.
          </em>
        </div>
        <div>
          We may process or share your data that we hold based on the following legal basis:
          <div>
            <div>
              <div>
                <div>
                  <div>
                    More specifically, we may need to process your data or share your personal information in the
                    following situations:
                  </div>
                  <ul>
                    <li>
                      <strong>Business Transfers.</strong> We may share or transfer your information in connection with,
                      or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or
                      a portion of our business to another company.
                    </li>
                  </ul>
                  <div>
                    <ul>
                      <li>
                        <strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share your
                        data with third-party vendors, service providers, contractors or agents who perform services for
                        us or on our behalf and require access to such information to do that work. Examples include:
                        payment processing, data analysis, email delivery, hosting services, customer service and
                        marketing efforts. We may allow selected third parties to use tracking technology on the , which
                        will enable them to collect data on our behalf about how you interact with our over time. This
                        information may be used to, among other things, analyze and track data, determine the popularity
                        of certain content, pages or features, and better understand online activity. Unless described
                        in this notice, we do not share, sell, rent or trade any of your information with third parties
                        for their promotional purposes.{' '}
                      </li>
                    </ul>
                    <div>
                      <ul>
                        <li>
                          <strong>Affiliates.</strong> We may share your information with our affiliates, in which case
                          we will require those affiliates to honor this privacy notice. Affiliates include our parent
                          company and any subsidiaries, joint venture partners or other companies that we control or
                          that are under common control with us.
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <strong>Business Partners.</strong> We may share your information with our business partners
                          to offer you certain products, services or promotions.
                        </li>
                      </ul>
                      <div>
                        <div>
                          <div>
                            <strong>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                          <div>
                            <strong>
                              <em>In Short:</em>
                            </strong>
                            <em> We only share information with the following categories of third parties.</em>
                          </div>
                          <div>
                            We only share and disclose your information with the following categories of third parties.
                            If we have processed your data based on your consent and you wish to revoke your consent,
                            please contact us using the contact details provided in the section below titled &quot;
                            <a href="#contact" data-custom-class="link">
                              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                            </a>
                            &quot;.
                          </div>
                          <ul>
                            <li>User Account Registration &amp; Authentication Services</li>
                          </ul>
                          <ul>
                            <li>Data Analytics Services</li>
                          </ul>
                          <ul>
                            <li>Finance &amp; Accounting Tools</li>
                          </ul>
                          <ul>
                            <li>Payment Processors</li>
                          </ul>
                          <ul>
                            <li>Performance Monitoring Tools</li>
                          </ul>
                          <ul>
                            <li>Product Engineering &amp; Design Tools</li>
                          </ul>
                          <ul>
                            <li>Sales &amp; Marketing Tools</li>
                          </ul>
                          <div>
                            <strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
                          </div>
                          <div>
                            <strong>
                              <em>In Short:&nbsp;</em>
                            </strong>
                            <em>
                              &nbsp;We may use cookies and other tracking technologies to collect and store your
                              information.
                            </em>
                          </div>
                          <div>
                            We may use cookies and similar tracking technologies (like web beacons and pixels) to access
                            or store information. Specific information about how we use such technologies and how you
                            can refuse certain cookies is set out in our Cookie Notice.
                          </div>
                          <div>
                            <strong>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                          <div>
                            <strong>
                              <em>In Short: </em>
                            </strong>
                            <em>
                              If you choose to register or log in to our services using a social media account, we may
                              have access to certain information about you.
                            </em>
                          </div>
                          <div>
                            Our offers you the ability to register and login using your third-party social media account
                            details (like your Facebook or Twitter logins). Where you choose to do this, we will receive
                            certain profile information about you from your social media provider. The profile
                            information we receive may vary depending on the social media provider concerned, but will
                            often include your name, email address, friends list, profile picture as well as other
                            information you choose to make public on such social media platform.{' '}
                          </div>
                          <div>
                            We will use the information we receive only for the purposes that are described in this
                            privacy notice or that are otherwise made clear to you on the relevant. Please note that we
                            do not control, and are not responsible for, other uses of your personal information by your
                            third-party social media provider. We recommend that you review their privacy notice to
                            understand how they collect, use and share your personal information, and how you can set
                            your privacy preferences on their sites and apps.
                          </div>
                          <div>
                            <strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
                          </div>
                          <div>
                            <strong>
                              <em>In Short:&nbsp;</em>
                            </strong>
                            <em>
                              &nbsp;We keep your information for as long as necessary to fulfill the purposes outlined
                              in this privacy notice unless otherwise required by law.
                            </em>
                          </div>
                          <div>
                            We will only keep your personal information for as long as it is necessary for the purposes
                            set out in this privacy notice, unless a longer retention period is required or permitted by
                            law (such as tax, accounting or other legal requirements). No purpose in this notice will
                            require us keeping your personal information for longer than 2 years.
                          </div>
                          <div>
                            When we have no ongoing legitimate business need to process your personal information, we
                            will either delete or anonymize such information, or, if this is not possible (for example,
                            because your personal information has been stored in backup archives), then we will securely
                            store your personal information and isolate it from any further processing until deletion is
                            possible.
                          </div>
                          <div>
                            <strong>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
                          </div>
                          <div>
                            <strong>
                              <em>In Short:&nbsp;</em>
                            </strong>
                            <em>
                              &nbsp;We aim to protect your personal information through a system of organizational and
                              technical security measures.
                            </em>
                          </div>
                          <div>
                            We have implemented appropriate technical and organizational security measures designed to
                            protect the security of any personal information we process. However, despite our safeguards
                            and efforts to secure your information, no electronic transmission over the Internet or
                            information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                            guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able
                            to defeat our security, and improperly collect, access, steal, or modify your information.
                            Although we will do our best to protect your personal information, transmission of personal
                            information to and from our is at your own risk. You should only access the within a secure
                            environment.
                          </div>
                          <div>
                            <strong>9. DO WE COLLECT INFORMATION FROM MINORS?</strong>
                          </div>
                          <div>
                            <strong>
                              <em>In Short:</em>
                            </strong>
                            <em> We do not knowingly collect data from or market to children under 18 years of age.</em>
                          </div>
                          <div>
                            We do not knowingly solicit data from or market to children under 18 years of age. By using
                            the , you represent that you are at least 18 or that you are the parent or guardian of such
                            a minor and consent to such minor dependent&rsquo;s use of the. If we learn that personal
                            information from users less than 18 years of age has been collected, we will deactivate the
                            account and take reasonable measures to promptly delete such data from our records. If you
                            become aware of any data we may have collected from children under age 18, please contact us
                            at privacy@penperformances.com.
                          </div>
                          <div>
                            <strong>10. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
                          </div>
                          <div>
                            <strong>
                              <em>In Short:</em>
                            </strong>
                            <em>
                              {' '}
                              In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you
                              have rights that allow you greater access to and control over your personal information.
                              You may review, change, or terminate your account at any time.
                            </em>
                          </div>
                          <div>
                            In some regions (like the EEA and UK), you have certain rights under applicable data
                            protection laws. These may include the right (i) to request access and obtain a copy of your
                            personal information, (ii) to request rectification or erasure; (iii) to restrict the
                            processing of your personal information; and (iv) if applicable, to data portability. In
                            certain circumstances, you may also have the right to object to the processing of your
                            personal information. To make such a request, please use the{' '}
                            <a href="#contact" data-custom-class="link">
                              contact details
                            </a>{' '}
                            provided below. We will consider and act upon any request in accordance with applicable data
                            protection laws.
                          </div>
                          <div>
                            If we are relying on your consent to process your personal information, you have the right
                            to withdraw your consent at any time. Please note however that this will not affect the
                            lawfulness of the processing before its withdrawal, nor will it affect the processing of
                            your personal information conducted in reliance on lawful processing grounds other than
                            consent.
                          </div>
                          <div>
                            If you are a resident in the EEA or UK and you believe we are unlawfully processing your
                            personal information, you also have the right to complain to your local data protection
                            supervisory authority. You can find their contact details here:{' '}
                            <a
                              href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                              target="_blank"
                              rel="noopener noreferrer"
                              data-custom-class="link"
                            >
                              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                            </a>
                            .
                          </div>
                          <div>
                            If you are a resident in Switzerland, the contact details for the data protection
                            authorities are available here:{' '}
                            <a
                              href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                              target="_blank"
                              rel="noopener noreferrer"
                              data-custom-class="link"
                            >
                              https://www.edoeb.admin.ch/edoeb/en/home.html
                            </a>
                            .
                          </div>
                        </div>
                        <div>
                          <strong>
                            <u>Cookies and similar technologies:</u>
                          </strong>{' '}
                          Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose
                          to set your browser to remove cookies and to reject cookies. If you choose to remove cookies
                          or reject cookies, this could affect certain features or services of our. To opt-out of
                          interest-based advertising by advertisers on our visit{' '}
                          <a
                            href="http://www.aboutads.info/choices/"
                            target="_blank"
                            rel="noopener noreferrer"
                            data-custom-class="link"
                          >
                            http://www.aboutads.info/choices/
                          </a>
                          .{' '}
                        </div>
                        <div>
                          <strong>11. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
                        </div>
                        <div>
                          Most web browsers and some mobile operating systems and mobile applications include a
                          Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy
                          preference not to have data about your online browsing activities monitored and collected. At
                          collected. At this stage no uniform technology standard for recognizing and implementing DNT
                          signals has As such, we do not currently respond to DNT browser signals or any other mechanism
                          automatically communicates your choice not to be tracked online. If a standard for online
                          tracking is adopted that we must follow in the future, we will inform you about that practice
                          in a revised version of this privacy notice.&nbsp;
                        </div>
                        <div>
                          <strong>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong>
                        </div>
                        <div>
                          <strong>
                            <em>In Short:&nbsp;</em>
                          </strong>
                          <em>
                            &nbsp;Yes, if you are a resident of California, you are granted specific rights regarding
                            access to your personal information.
                          </em>
                        </div>
                        <div>
                          California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law,
                          users who are California residents to request and obtain from us, once a year and free of
                          charge, information about categories of personal information (if any) we disclosed to third
                          parties for direct marketing purposes and the names and addresses of all third parties with
                          which we shared personal information in the immediately preceding calendar year. If you are a
                          California resident and would like to make such a request, please submit your request in
                          writing to us using the contact information provided below.
                        </div>
                        <div>
                          If you are under 18 years of age, reside in California, and have a registered account with ,
                          you have the right to request removal of unwanted data that you publicly post on the. To
                          request removal of such data, please contact us using the contact information provided below,
                          and include the email address associated with your account and a statement that you reside in
                          California. We will make sure the data is not publicly displayed on the , but please be aware
                          that the data may not be completely or comprehensively removed from all our systems (e.g.
                          backups, etc.).
                        </div>
                        <div>
                          <strong>13. DO WE MAKE UPDATES TO THIS NOTICE?</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div />
                        <div>
                          <em>
                            <strong>In Short:&nbsp;</strong> Yes, we will update this notice as necessary to stay
                            compliant with relevant laws.
                          </em>
                        </div>
                        <div>
                          We may update this privacy notice from time to time. The updated version will be indicated by
                          an updated &quot;Revised&quot; date and the updated version will be effective as soon as it is
                          accessible. If we make material changes to this privacy notice, we may notify you either by
                          prominently posting a notice of such changes or by directly sending you a notification. We
                          encourage you to review this privacy notice frequently to be informed of how we are protecting
                          your information.
                        </div>
                        <div>
                          <strong>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div>
                          If you have questions or comments about this notice, you may email us at
                          privacy@penperformances.com&nbsp;or by post to:
                        </div>
                        <div>pen@penperformances.com</div>
                        <div>
                          <strong>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</strong>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div>
                          Based on the applicable laws of your country, you may have the right to request access to the
                          personal information we collect from you, change that information, or delete it in some
                          circumstances. To request to review, update, or delete your personal information, please
                          submit a request form by clicking&nbsp;
                          <a
                            href="https://app.termly.io/notify/855dd29b-69b9-4950-9dec-6d445a8f4d41"
                            target="_blank"
                            rel="noopener noreferrer"
                            data-custom-class="link"
                          >
                            here
                          </a>
                          .
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export { PrivacyPolicy };
