import moment from 'moment';
import { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { availabilityRowSelectorById, updateAvailabilityRowById } from '../../../store/profile/profileSlice';
import { TSelectedWeekdays, TSelectedHours } from '../../../types';

import TimePicker from './TimePicker';
import WeekDaysPicker from './WeekdayPicker';

interface AvailabilityRowProps {
  handleRemove(): void;
  rowId: string;
  categoryId: string;
}

export default function AvailabilityRow({ handleRemove, rowId, categoryId }: AvailabilityRowProps) {
  const {
    startDate,
    endDate,
    selectedWeekDays,
    showWeekdayPicker,
    selectedHours,
    showHourPicker,
    oldStartDate,
    oldEndDate,
  } = useAppSelector(availabilityRowSelectorById(rowId));

  const dispatch = useAppDispatch();
  const today = new Date();

  const isOneDayBefore = () => {
    if (moment(moment(today).add(1, 'day').format('YYYY-MM-DD')).isSame(moment(startDate))) {
      return true;
    }
    return false;
  };

  const isSameDay = () => {
    if (moment(today).format('YYYY-MM-DD') === startDate) {
      return true;
    }
    return false;
  };

  const isCurrentDayInBetween = () => {
    if (moment(today).isBetween(moment(startDate), moment(endDate))) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isOneDayBefore() || isSameDay()) {
      const update = {
        oldStartDate: '',
        oldEndDate: '',
      };
      dispatch(updateAvailabilityRowById({ id: rowId, update }));
    } else if (isCurrentDayInBetween()) {
      const update = {
        oldStartDate: startDate,
        oldEndDate: moment(today).add(-1, 'day').format('YYYY-MM-DD'),
        startDate: moment(today).format('YYYY-MM-DD'),
      };
      dispatch(updateAvailabilityRowById({ id: rowId, update }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backgroundOverlayColor = `bg-overlay-${categoryId}`;

  const [isOpen, setOpen] = useState(false);

  const handleInputBlurChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateAvailabilityRowById({ id: rowId, update: { [name]: value } }));
  };

  const handleCloseWeekdaysPicker = (weekdays: TSelectedWeekdays) => {
    const update = { selectedWeekDays: weekdays, showWeekdayPicker: false };
    dispatch(updateAvailabilityRowById({ id: rowId, update }));
  };

  const handleCloseTimePicker = (hours: TSelectedHours) => {
    const update = { selectedHours: hours, showHourPicker: false };
    dispatch(updateAvailabilityRowById({ id: rowId, update }));
  };

  const handleOpenWeekdayPicker = () =>
    dispatch(updateAvailabilityRowById({ id: rowId, update: { showWeekdayPicker: true } }));

  const handleOpenTimePicker = () =>
    dispatch(updateAvailabilityRowById({ id: rowId, update: { showHourPicker: true } }));

  const closeModal = () => {
    setOpen(false);
  };

  const closeModalAndRemoveAvailability = () => {
    setOpen(false);
    handleRemove();
  };

  const minDateValidation = () => {
    if (isOneDayBefore()) {
      return moment().add(1, 'day').format('YYYY-MM-DD');
    }
    if (isSameDay() || isCurrentDayInBetween()) {
      return moment().format('YYYY-MM-DD');
    }
    return moment().add(2, 'day').format('YYYY-MM-DD');
  };

  return (
    <>
      <WeekDaysPicker
        isOpen={showWeekdayPicker}
        handleClose={handleCloseWeekdaysPicker}
        initialState={selectedWeekDays}
      />
      <TimePicker isOpen={showHourPicker} handleClose={handleCloseTimePicker} initialState={selectedHours} />
      <div className="mt-10 flex flex-col bg-black bg-opacity-20 px-4 pt-12 pb-6 relative m-2">
        {oldStartDate && (
          <div className="mb-4">
            <span>Past Availability </span>
            <input type="date" name="initialStartDate" className="text-black w-44" value={oldStartDate} readOnly />
            <span className="mx-3">to:</span>
            <input type="date" name="initialEndDate" value={oldEndDate} className="text-black w-44" readOnly />
          </div>
        )}
        <div className="mb-4">
          <span>Available </span>
          <span>from: </span>
          <input
            type="date"
            required
            name="startDate"
            className="text-black w-44"
            min={minDateValidation()}
            max={moment().add(300, 'days').format('YYYY-MM-DD')}
            value={startDate}
            onChange={handleInputBlurChange}
          />
          <span className="mx-3">to:</span>
          <input
            type="date"
            required
            name="endDate"
            className="text-black w-44"
            min={startDate || moment().add(2, 'day').format('YYYY-MM-DD')}
            max={moment().add(300, 'year').format('YYYY/MM/DD')}
            value={endDate}
            onChange={handleInputBlurChange}
          />
        </div>
        <div className="mb-0">
          <span>What days will be available?</span>
          <button type="button" onClick={handleOpenWeekdayPicker} className="ml-5 px-5 py-2 border border-white">
            Pick Week Days
          </button>
          <div className="py-2 text-base flex flex-row flex-wrap max-w-lg">
            {Object.entries(selectedWeekDays)
              .filter(([, selected]) => !!selected)
              .map(([hour]) => (
                <div className="w-auto mx-2" key={`${rowId}-${hour}`}>
                  {hour}
                </div>
              ))}
          </div>
        </div>

        <div className="mb-0 ">
          <span>What hours will be available?</span>
          <button type="button" onClick={handleOpenTimePicker} className="ml-5 px-5 py-2 border border-white">
            Pick Hours
          </button>
          <div className="py-3 text-base flex flex-row flex-wrap max-w-lg">
            {Object.entries(selectedHours)
              .filter(([, selected]) => !!selected)
              .map(([hour]) => (
                <div className="w-auto mx-2" key={`${rowId}-${hour}`}>
                  {hour}
                </div>
              ))}
          </div>
        </div>

        <ReactModal
          isOpen={isOpen}
          onRequestClose={closeModal}
          overlayClassName="fixed inset-0 flex items-center justify-center z-30"
          className={`${backgroundOverlayColor} bg-opacity-90 max-w-lg p-6 text-white outline-none`}
        >
          <>
            <div className="flex justify-end">
              <button
                type="button"
                className="text-2xl w-10 rounded-md bg-gray-900 bg-opacity-10 hover:bg-opacity-30"
                onClick={closeModal}
              >
                X
              </button>
            </div>
            <div className="flex justify-end mt-4 text-white">
              Are you sure that you want to delete this Availability?
            </div>
            <div className="flex justify-around my-4 mr-5">
              <button
                className="rounded-md bg-gray-900 bg-opacity-10 hover:bg-opacity-30 px-4 py-1"
                type="button"
                onClick={closeModalAndRemoveAvailability}
              >
                Yes
              </button>
            </div>
          </>

          <div />
        </ReactModal>

        <button
          type="button"
          onClick={() => {
            setOpen(true);
          }}
          className="ml-5 px-5 py-2 hover:bg-black hover:bg-opacity-20 absolute right-0 top-0 text-2xl"
        >
          X
        </button>
      </div>
    </>
  );
}
