import { CardGrid } from './CardGrid';
import { ArtistCardType, IArtist } from '../types';

interface IFinderArtists {
  artists: IArtist[] | null;
  displayFooter?: boolean;
  displayInfo?: boolean;
}

const FinderArtists = ({ artists, displayFooter = false, displayInfo = false }: IFinderArtists) => {
  const hasArtists = !!artists?.length;
  return (
    <div className="mt-12">
      <div className="inline-flex items-baseline leading-none text-2xl md:text-4vw xl:text-6xl font-bold text-actors w-full">
        <span className="w-full text-center">YOUR SEARCH RESULTS</span>
      </div>
      {!hasArtists && (
        <div className="text-2xl text-center mt-4">
          There are no performances available with this search criteria. Please try a new search or make a custom
          request with an Artist.
        </div>
      )}
      {hasArtists && (
        <CardGrid
          artists={artists || []}
          displayFooter={displayFooter}
          displayInfo={displayInfo}
          cardType={ArtistCardType.roster}
        />
      )}
    </div>
  );
};

export { FinderArtists };
