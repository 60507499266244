/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

/* eslint-disable react/jsx-props-no-spreading */
interface FormInputProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  name: string;
  id: string;
  helper?: string;
  options: { id: string; label: string }[];
}

export function FormCheckboxGroup({ label, name, id, helper, options, ...rest }: FormInputProps) {
  const { register } = useFormContext();
  return (
    <div className="mb-5">
      <div>{label}</div>
      <div className="flex flex-wrap">
        {options?.map((option, index) => (
          <div key={option.id} className="flex items-center whitespace-nowrap mr-2 py-1">
            <label htmlFor={option.id} className="pr-2 py-1">
              <input
                id={option.id}
                // eslint-disable-next-line react/no-array-index-key
                key={`${name}`}
                type="checkbox"
                className="form-checkbox"
                value={option.label}
                {...register(`${name}`)}
              />
              <span className="ml-2">{option.label}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
