import { PageHeader } from '../../components';
import { Footer } from '../../components/Footer';

const TechnicalSupport = () => {
  const SUPPORT_EMAIL = 'support@penperformances.com';
  return (
    <div className="container max-w-lg h-screen min-w-lg mx-auto">
      <PageHeader position="absolute" />
      <div className="container max-w-lg min-w-lg mx-auto h-screen pt-24 flex flex-col justify-between">
        <div>
          <div className="text-4xl my-4">Technical Support</div>
          <div className="text-xl text-gray-600">
            Is there any issue? Please tell us about it and we would be happy to support you as soon as possible
          </div>
          <div className="bg-gray-300 h-1 w-full" />
          <a className="flex justify-end text-xl mt-4" href={`mailto:${SUPPORT_EMAIL}`}>
            <span className=" border-b-2 border-blue-500 text-blue-500 hover:text-blue-700 hover:border-blue-700">
              {SUPPORT_EMAIL}
            </span>
          </a>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export { TechnicalSupport };
