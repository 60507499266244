import { useEffect } from 'react';

export function useChangePageColorByCategory(categoryId: string) {
  useEffect(() => {
    document.body.classList.add(`bg-${categoryId}`);
    return () => {
      document.body.classList.remove(`bg-${categoryId}`);
    };
  }, [categoryId]);
}
