import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { categories } from '../../config';

import { RosterHeader } from './RosterHeader';
import { RosterCategorySection } from './RosterCategorySection';
import { PageHeader } from '../../components';
import { ModalFinder } from './ModalFinder';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { artistSelector, artistFinderSelector, resetFinder } from '../../store/artists/artistsSlice';
import { getFinderArtists } from '../../store/artists/artistsActions';
import { FinderArtists } from '../../components/FinderArtists';
import { Footer } from '../../components/Footer';
import { SearchAgain } from '../../components/buttons/SearchAgain';
import FindPerfectPerformance from '../../components/buttons/FindPerfectPerformance';
import HowPenWorks from '../../components/buttons/HowPenWorks/HowPenWorks';

export function Roster() {
  const dispatch = useAppDispatch();
  const { hash } = useLocation();

  const finderArtists = useAppSelector(artistFinderSelector);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [finderSettings, setFinderSettings] = useState<{
    finderCategories: string[];
    finderSubCategories: string[];
    finderStartDate: string;
    finderEndDate: string;
    finderBudget: { from: number; to: number };
  }>({
    finderCategories: [],
    finderSubCategories: [],
    finderStartDate: '',
    finderEndDate: '',
    finderBudget: { from: 0, to: 10000 },
  });

  const artistState = useAppSelector(artistSelector);

  useEffect(() => {
    dispatch(resetFinder());
  }, [dispatch]);

  useEffect(() => {
    if (hash === '#finder') {
      setIsModalOpen(true);
    }
  }, [hash]);

  const hasResults = finderArtists !== null;

  const showModalHandler = () => {
    setIsModalOpen(!isModalOpen);
  };

  const modalChangeHandler = (
    finderCategories: string[],
    finderSubCategories: string[],
    finderStartDate: string,
    finderEndDate: string,
    finderBudget: { from: number; to: number },
  ) => {
    setFinderSettings({ finderCategories, finderSubCategories, finderStartDate, finderEndDate, finderBudget });
  };

  const finderSubmitHandler = async () => {
    const categoriesToFind = finderSettings.finderCategories.toString();
    const subCategoriesToFind = finderSettings.finderSubCategories.toString();
    const { finderStartDate, finderEndDate } = finderSettings;
    const startPrice = finderSettings.finderBudget.from;
    const endPrice = finderSettings.finderBudget.to;
    const filter = `/filter?categories=${categoriesToFind}&tags=${subCategoriesToFind}&startDate=${finderStartDate}&endDate=${finderEndDate}&startPrice=${startPrice}&endPrice=${endPrice}`;
    dispatch(getFinderArtists(filter));
    setIsModalOpen(false);
    setFinderSettings({
      finderCategories: [],
      finderSubCategories: [],
      finderStartDate: '',
      finderEndDate: '',
      finderBudget: { from: 0, to: 10000 },
    });
  };

  const cleanFinderResults = () => {
    dispatch(resetFinder());
  };

  return (
    <>
      <div className="container max-w-lg min-w-lg mx-auto">
        <PageHeader publicMenuCallback={cleanFinderResults} />
        <ModalFinder
          onChange={modalChangeHandler}
          isModalOpen={isModalOpen}
          onClose={isClose => setIsModalOpen(isClose)}
          onRequest={finderSubmitHandler}
        />
        <RosterHeader categories={categories} />
        <div className="mx-20">
          {!hasResults && (
            <div className="mx-6 display flex justify-between">
              <HowPenWorks type="color" width={70} />
              <button
                type="button"
                className="mt-3 font-semibold text-center text-default text-shadow-default text-2xl"
                onClick={showModalHandler}
              >
                <FindPerfectPerformance width={350} />
              </button>
            </div>
          )}
          {hasResults && (
            <>
              <FinderArtists artists={finderArtists} displayFooter displayInfo />
              <button
                type="button"
                className="select-none outline-none w-64 hover:bg-gray-100 flex mx-auto my-10"
                onClick={showModalHandler}
              >
                <SearchAgain color="white" />
              </button>
            </>
          )}
          {!hasResults &&
            Object.keys(artistState.allIdsByCategory).map(categoryId => {
              const artists = artistState.allIdsByCategory[categoryId].map(id => artistState.byId[id]);
              const categoryInfo = categories.find(category => category.id === categoryId);

              if (!categoryInfo) return null;

              const categoryWithArtist = {
                categoryId: categoryInfo?.id || '',
                categoryName: categoryInfo?.displayName || '',
                tags: categoryInfo?.subcategories || [],
                artists,
              };
              return <RosterCategorySection key={categoryId} category={categoryWithArtist} />;
            })}
        </div>
      </div>
      <div className="container max-w-lg min-w-lg mx-auto bg-gray-200 mt-6">
        <Footer />
      </div>
    </>
  );
}
