import Modal from 'react-modal';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { List } from '../../components/List';
import { ListItem } from '../../components/ListItem';
import { categories } from '../../config';
import { DoubleSlider } from '../../components/Slider';
import './roster.css';

interface IModalFinder {
  onChange(
    finderCategories: string[],
    finderSubCategories: string[],
    finderStartDate: string,
    finderEndDate: string,
    finderBudget: { from: number; to: number },
  ): void;
  isModalOpen: boolean;
  onClose(isModalOpen: boolean): void;
  onRequest(): void;
}

const ModalFinder = ({ onChange, isModalOpen, onClose, onRequest }: IModalFinder) => {
  const [isSubModalOpen, setSubModalOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>([]);
  const [startSelectedDate, setStartSelectedDate] = useState<string>('');
  const [endSelectedDate, setEndSelectedDate] = useState<string>('');
  const [budget, setBudget] = useState({ from: 1, to: 100000 });
  const [isValidData, setIsValidData] = useState<boolean>(false);

  useEffect(() => {
    if (isModalOpen === false) {
      setSubModalOpen(false);
    }
  }, [isModalOpen]);

  const finderChangeHandler = (id: string, idItem: string, value: boolean) => {
    const selectedCategoriesCopy = [...selectedCategories];
    if (value === true) {
      selectedCategoriesCopy.push(idItem);
      const selectedSubCategory = categories.find(item => {
        return item.id === idItem;
      });
      setSubCategories(selectedSubCategory?.subcategories || []);
      setSubModalOpen(true);
    } else {
      const index = selectedCategoriesCopy.indexOf(idItem);
      selectedCategoriesCopy.splice(index, 1);
      setSubModalOpen(false);
    }
    setSelectedCategories(selectedCategoriesCopy);
    onChange(selectedCategoriesCopy, selectedSubCategories, startSelectedDate, endSelectedDate, budget);
  };

  const subcategoryChangeHandler = (idItem: string, checked: boolean) => {
    const selectedSubCategoriesCopy = [...selectedSubCategories];
    if (checked === true) {
      selectedSubCategoriesCopy.push(idItem);
    } else {
      const index = selectedSubCategoriesCopy.indexOf(idItem);
      selectedSubCategoriesCopy.splice(index, 1);
    }
    setSelectedSubCategories(selectedSubCategoriesCopy);
    onChange(selectedCategories, selectedSubCategoriesCopy, startSelectedDate, endSelectedDate, budget);
  };

  const sliderChangeHandler = (values: readonly number[]) => {
    setBudget({ from: values[0] === 0 ? 1 : values[0], to: values[1] === 0 ? 1 : values[1] });
    onChange(selectedCategories, selectedSubCategories, startSelectedDate, endSelectedDate, {
      from: values[0],
      to: values[1],
    });
  };

  const handleOnChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const utcTime = moment(value).utc().format('YYYY-MM-DD');
    setStartSelectedDate(utcTime);
    setEndSelectedDate(utcTime);
    const currentDate = moment(moment().format('YYYY-MM-DD'));
    const userDate = moment(moment(value).format('YYYY-MM-DD'));
    const maxDate = moment().add(300, 'days');
    if (userDate.isSameOrAfter(currentDate) && userDate.isBefore(maxDate)) {
      setIsValidData(true);
    } else {
      setIsValidData(false);
    }
    onChange(selectedCategories, selectedSubCategories, utcTime, utcTime, budget);
  };

  const handleRequest = () => {
    onRequest();
    setSelectedCategories([]);
    setSelectedSubCategories([]);
    setStartSelectedDate('');
    setEndSelectedDate('');
    setBudget({ from: 1, to: 100000 });
  };

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={() => onClose(false)}
      isOpen={isModalOpen}
      overlayClassName="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      className="musicians relative w-full min-h-md max-w-xl flex focus:outline-none "
    >
      <div className="flex flex-1 flex-col gap-5 justify-center items-center bg-comedians opacity-90">
        <div className="text-white text-3xl">
          <span className="font-semibold">PEN </span>
          <span>ARTIST FINDER</span>
        </div>
        <List list={categories} id="list1" onChange={finderChangeHandler} />
        {isSubModalOpen && (
          <div className="absolute bg-chefs top-48 left-72 ml-16 p-5 z-10">
            <button
              type="button"
              className="text-white text-xl absolute top-1 right-4"
              onClick={() => setSubModalOpen(false)}
            >
              X
            </button>
            {subCategories.map(subCategory => {
              return (
                <div key={subCategory}>
                  <ListItem
                    id={subCategory}
                    value={subCategory}
                    label={subCategory}
                    onChange={subcategoryChangeHandler}
                  />
                </div>
              );
            })}
          </div>
        )}
        <div>
          <div className="text-white text-2xl mb-4">What is your preferred performance date?</div>
          <div className="flex flex-col justify-center items-center relative">
            <input
              type="date"
              required
              name="startDate"
              className="text-black w-44"
              min={moment().format('YYYY-MM-DD')}
              max={moment().add(300, 'days').format('YYYY-MM-DD')}
              onChange={handleOnChangeDate}
            />
            {!isValidData && (
              <div className="text-white absolute top-11 opacity-80">
                The date must be between today and {moment().add(300, 'days').format('MMM DD YYYY')}
              </div>
            )}
          </div>
        </div>
        <DoubleSlider key="slider" onChange={sliderChangeHandler} />
        <button
          type="button"
          onClick={() => onClose(false)}
          className="absolute top-2 right-2 text-3xl px-4 py-2 text-white"
        >
          X
        </button>
        <button
          type="button"
          className="text-2xl px-4 py-2 text-white mt-28 border-2 disabled:opacity-30"
          disabled={!isValidData}
          onClick={handleRequest}
        >
          here&apos;s is your perfect performance
        </button>
      </div>
    </Modal>
  );
};

export { ModalFinder };
