/* eslint-disable react/jsx-props-no-spreading */
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import 'moment-timezone';
import { useState, useEffect } from 'react';
import { toNumber } from 'lodash';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { currentConfig } from '../../config';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { userSelector } from '../../store/user/userSlice';
import Availability from './Availability/Availability';
import ProfileArtistShowInfo from './ProfileArtistShowInfo';
import ProfileBasicInfo from './ProfileBasicInfo';
import ProfilePreview from './ProfilePreview';
import ProfileManagerInfo from './ProfileManagerInfo';
import { IArtist } from '../../types';
import s3upload from '../../utils/s3upload';
import { userSignout } from '../../store/user/userActions';
import { getAvailability } from '../../store/profile/profileActions';
import { availabilitySelector } from '../../store/profile/profileSlice';

interface IArtistFormData extends IArtist {
  subcategories: string[];
  profileAccess: 'private' | 'public';
  profileImageSrc?: string;
  profileVideoUrl?: string;
}

enum IUploadStatus {
  'initial' = '',
  'upload_image' = 'uploading image...',
  'upload_video' = 'uploading video...',
  'saving_data' = 'saving data...',
  'finished' = 'data saved',
}

export default function Profile() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const { byId, allIds } = useAppSelector(availabilitySelector);
  const history = useHistory();
  const [availability, setAvailability] = useState<Date[]>([]);
  const [uploadStatus, setUploadStatus] = useState(IUploadStatus.initial);

  useEffect(() => {
    const getArtistAvailability = async () => {
      await dispatch(getAvailability());
    };
    getArtistAvailability();
  }, [dispatch]);

  const initialValues = {
    id: user.artist?.id,
    firstName: user.artist?.firstName,
    lastName: user.artist?.lastName,
    paypalEmail: user.artist?.paypalEmail,
    headline: user.artist?.headline,
    profileImageSrc: '',
    profileVideoUrl: '',
    artistBio: user.artist?.artistBio,
    productDescription: user.artist?.productDescription,
    performancePrice: toNumber(user.artist?.performancePrice),
    performanceDuration: user.artist?.performanceDuration || '1 hour',
    timezone: user.artist?.timezone || moment.tz.guess(),
    categoryId: user.artist?.categoryId,
    subcategories: user.artist?.categories,
    profileAccess: user.artist?.privateProfile ? 'private' : 'public',
    email: user.artist?.email,
    adminFacebookEmail: user.artist?.adminFacebookEmail,
  };

  const methods = useForm({ defaultValues: initialValues });

  const onSubmit = async (data: IArtistFormData) => {
    const artist: Partial<IArtistFormData> = {
      firstName: data.firstName,
      lastName: data.lastName,
      paypalEmail: data.paypalEmail,
      headline: data.headline,
      artistBio: data.artistBio,
      productDescription: data.productDescription,
      performancePrice: data.performancePrice,
      performanceDuration: data.performanceDuration,
      timezone: data.timezone,
      categoryId: data.categoryId,
      categories: data?.subcategories ? data?.subcategories?.filter(Boolean) : [],
      privateProfile: data?.profileAccess.toLowerCase() === 'private',
      email: data.email,
      adminFacebookEmail: data.adminFacebookEmail,
      nextPerformance: moment(availability[0]).format('YYYY-MM-DD'),
    };
    try {
      setUploadStatus(IUploadStatus.upload_image);
      const imageS3Url = await s3upload({
        file: data.profileImageSrc?.[0] as unknown as File,
        userId: user.artist?.id || '',
        tag: 'profile-image',
      });
      if (imageS3Url) {
        artist.profileImageSrc = imageS3Url;
      }

      setUploadStatus(IUploadStatus.upload_video);
      const videoS3Url = await s3upload({
        file: data.profileVideoUrl?.[0] as unknown as File,
        userId: user.artist?.id || '',
        tag: 'profile-video',
      });
      if (videoS3Url) {
        artist.profileVideoUrl = videoS3Url;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('=== ERROR ON UPLOADING MEDIA ===', error);
    }

    try {
      setUploadStatus(IUploadStatus.saving_data);
      await axios({
        method: 'post',
        url: `${currentConfig.API_URL}/performers/artist/edit`,
        headers: {
          Authorization: user?.accessToken ? `Bearer ${user.accessToken}` : `Bearer ${user?.artist?.token}`,
        },
        data: { ...artist },
      });
      setUploadStatus(IUploadStatus.finished);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    const setArtistLocalTimezone = (date: Date) => {
      const res = moment.tz(data.timezone);
      res.year(date.getFullYear());
      res.month(date.getMonth());
      res.date(date.getDate());
      res.hour(date.getHours());
      res.minute(0);
      res.second(0);
      res.millisecond(0);
      return res;
    };
    const availabilityPayload = availability.map(date => {
      return { date: setArtistLocalTimezone(date).utc().format(), booked: false };
    });

    try {
      await axios({
        method: 'post',
        url: `${currentConfig.API_URL}/performers/artist/availability`,
        headers: {
          Authorization: user?.accessToken ? `Bearer ${user.accessToken}` : `Bearer ${user?.artist?.token}`,
        },
        data: { availability: availabilityPayload, cardsAvailability: JSON.stringify({ byId, allIds }) },
      });
      history.push(`/artist/${user.artist?.urlFriendlyId}`);
      dispatch(userSignout());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="mb-5">
          <span className="block w-full">Facebook login account: {user.email} </span>
        </div>
        <ProfileBasicInfo />
        <hr className="my-8" />
        <ProfileManagerInfo disabled={user.role === 'admin'} />
        <hr className="my-8" />
        <ProfileArtistShowInfo />
        <hr className="my-8" />
        <Availability categoryId={methods.getValues().categoryId || ''} setAvailability={setAvailability} />
        <hr className="my-8" />
        <ProfilePreview />
        <div className="py-16">
          <button
            type="submit"
            className="block mx-auto border border-white px-5 py-2 text-2xl uppercase"
            disabled={uploadStatus !== IUploadStatus.initial}
          >
            {uploadStatus === IUploadStatus.initial && `Save and go to your profile`}
            {uploadStatus !== IUploadStatus.initial && uploadStatus}
          </button>
        </div>
      </form>
    </FormProvider>
  );
}
