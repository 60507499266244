import { getHeadshot } from '../mock/utils';
import { ICategory } from '../types';

export const categories: ICategory[] = [
  {
    id: 'musicians',
    displayName: 'Musicians',
    imageSrc: getHeadshot(5),
    subcategories: [
      'rock',
      'pop',
      'country',
      'jazz/standards',
      'blues/soul',
      'classical',
      'opera',
      'r&b/hip-hop',
      'latin',
      'christian',
    ],
  },
  {
    id: 'actors',
    displayName: 'Actors',
    imageSrc: getHeadshot(13),
    subcategories: ['Dramatic Readings', 'Poetry Readings', 'Musical Theater'],
  },
  {
    id: 'comedians',
    displayName: 'Comedians',
    imageSrc: getHeadshot(17),
    subcategories: ['Stand-up', 'Improv'],
  },
  {
    id: 'magicians',
    displayName: 'Magicians',
    imageSrc: getHeadshot(24),
    subcategories: ['Illusionist', 'Mentalist', 'Escape Artist', 'Comedy Magician', 'Pickpocket'],
  },
  // {
  //   id: 'dancers',
  //   displayName: 'Dancers',
  //   imageSrc: getHeadshot(16),
  //   subcategories: ['Ballet', 'Modern Dance', 'Jazz/Swing', 'Tap', 'Hip-Hop', 'Ethnic'],
  // },

  // {
  //   id: 'luminaries',
  //   displayName: 'Luminaries',
  //   imageSrc: getHeadshot(27),
  //   subcategories: [
  //     'Directors',
  //     'Producers',
  //     'Business Leaders',
  //     'Athletes',
  //     'Spiritual Leaders',
  //     'Social Media',
  //     'Reality Stars',
  //   ],
  // },
  // {
  //   id: 'chefs',
  //   displayName: 'Chefs',
  //   imageSrc: getHeadshot(31),
  //   subcategories: ['Executive', 'Pastry', 'Molecular', 'Sous', 'Cultural', 'Nutrition'],
  // },
];

const headerCategoryIds = ['musicians', 'actors', 'comedians', 'magicians'];
export const roosterHeaderCategories = categories.filter(cat => headerCategoryIds.includes(cat.id));
