import { createSlice, createSelector } from '@reduxjs/toolkit';
import { IMessage, IRootState } from '../../types';
import { storePaypalReceive } from './paypalActions';

const initialState: IMessage = {
  message: 'Waiting for response...',
};

export const paypalSlice = createSlice({
  name: 'paypal',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(storePaypalReceive.fulfilled, (state, { payload }) => {
      state.message = payload;
    });
    builder.addCase(storePaypalReceive.rejected, (state, { error }) => {
      state.message = error.message;
    });
  },
});

const paypalState = (state: IRootState) => state.paypal;

export const messageSelector = createSelector(paypalState, state => state.message);
