import { PageHeader } from '../../components';
import { Footer } from '../../components/Footer';

const ContactUs = () => {
  const ADMIN_EMAIL = 'admin@penperformances.com';
  return (
    <div className="container max-w-lg h-screen min-w-lg mx-auto">
      <PageHeader position="absolute" />
      <div className="container mx-auto h-screen pt-24 flex flex-col justify-between">
        <div>
          <div className="text-4xl my-4">Contact Us</div>
          <div className="text-xl text-gray-600">
            Do you want to get in touch with us? We would be happy to hear from you.
          </div>
          <div className="bg-gray-300 h-1 w-full" />
          <a className="flex justify-end text-xl mt-4" href={`mailto:${ADMIN_EMAIL}`}>
            <span className=" border-b-2 border-blue-500 text-blue-500 hover:text-blue-700 hover:border-blue-700">
              {ADMIN_EMAIL}
            </span>
          </a>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export { ContactUs };
