import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import Artist from './Artist';
import { Home } from './Home/Home';
import Profile from './Profile';
import { Roster } from './Roster/Roster';
import { PrivacyPolicy } from './PrivacyPolicy';
import { ScrollToTop } from '../components';
import { Vonage } from '../components/Vonage';
import { ArtistOnlyRoute } from '../hoc/withRoleAccess';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getFullArtistList, getFeaturedArtists } from '../store/artists/artistsActions';
import { userSelector } from '../store/user/userSlice';
import { PenLoader } from '../components/PenLoader';
import { TermsOfService } from './TermsOfService';
import { Faqs } from './Faqs';
import { TechnicalSupport } from './TechnicalSupport';
import { ContactUs } from './ContactUs';

// TODO: Ask for not found route, temporary added home
const DummyNotFound = Home;
const DummyPage = ({ title }: { title: string }) => {
  const history = useHistory();

  return (
    <div className="flex flex-col w-full h-screen items-center justify-center">
      <h2 className="text-4xl font-bold text-center">{title}</h2>
      <button type="button" onClick={() => history.goBack()} className="my-8 text-2xl">
        Go Back
      </button>
    </div>
  );
};

function PublicRoot() {
  // To call only first time user reach page or refresh the page
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getFullArtistList());
    dispatch(getFeaturedArtists());
  }, [dispatch]);
  // TODO: enum with routes
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/roster" component={Roster} />
        <Route path="/privacyPolicy" component={PrivacyPolicy} />
        <Route path="/logins" component={() => <DummyPage title="Logins" />} />
        <Route path="/artists" component={() => <DummyPage title="Artists" />} />
        <Route path="/dealers" component={() => <DummyPage title="Dealers" />} />
        <Route path="/guidelines" component={() => <DummyPage title="Guidelines" />} />
        <Route path="/technicalSupport" component={TechnicalSupport} />
        <Route path="/contact" component={ContactUs} />
        <Route path="/TermOfService" component={TermsOfService} />
        <Route path="/faq" component={Faqs} />
        <Route path="/artist/:artistId" component={Artist} />
        <Route path="/performance/:display/:roomToken" component={Vonage} />
        <ArtistOnlyRoute path="/profile" component={Profile} />
        <Route exact path="/no-authorized" component={() => <DummyPage title="No Authorized" />} />
        <Route component={DummyNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

function PrivateRoot() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <ArtistOnlyRoute path="/" component={Profile} />
        <Route exact path="/no-authorized" component={() => <DummyPage title="No Authorized" />} />
      </Switch>
    </BrowserRouter>
  );
}

export default function Root() {
  const { isLogged, isSignInProgress } = useAppSelector(userSelector);

  if (isSignInProgress) {
    return (
      <div className="flex justify-center w-screen h-screen items-center">
        <PenLoader loadingText="Loading Performer Profile..." />
      </div>
    );
  }

  if (isLogged) {
    return <PrivateRoot />;
  }

  return <PublicRoot />;
}
