import { ICategory } from '../../types';

interface IRosterHeaderCardProps {
  category: ICategory;
}

const cardImageStyle: React.CSSProperties = {
  height: 310,
};

export function RosterHeaderCard({ category }: IRosterHeaderCardProps) {
  const { imageSrc, displayName } = category;
  return (
    <a href={`#${category.id}`} className="flex-1 bg-red-">
      <div className="relative transform hover:scale-115 hover:z-10">
        <div className="absolute inset-0 z-10 bg-black opacity-10" />
        <img
          className="object-cover object-top w-full"
          alt={category.displayName}
          src={imageSrc}
          style={cardImageStyle}
        />
        <span className="absolute bottom-3 w-full text-2xl text-center uppercase text-white filter drop-shadow py-1 select-none">
          {displayName}
        </span>
      </div>
      <div className="bg-gray-200 h-2 w-full" />
    </a>
  );
}
