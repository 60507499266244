import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Twitter, Facebook, Instagram } from './Icons';
import { VipAccessFooterButton } from './buttons/VipAccess/VipAccess';
import { categories, footerSocialUrls } from '../config';
import { FacebookLoginButton } from './buttons/FacebookLogin';
import './footer.css';

const FOOTER_ICON_SIZE = 35;
const styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '7px',
  marginLeft: '2px',
  color: 'rgba(107, 114, 128)',
  height: '14px',
  width: '14px',
  border: '1px solid',
  borderRadius: '50%',
  fontSize: '8px',
  lineHeight: '12px',
};

const Footer = () => {
  return (
    <div className="bg-gray-200 h-72 py-10 relative w-full">
      <div className="flex justify-center">
        <div className="flex flex-col w-52 text-gray-500">
          <span className="font-semibold text-lg h-5">Artist Roster</span>
          {categories.map(category => {
            return (
              <HashLink smooth key={category.id} to={`/roster#${category.id}`} className="text-lg h-5 hover:text-black">
                {category.displayName}
              </HashLink>
            );
          })}
        </div>
        <div className="flex flex-col w-48 text-gray-500">
          <span className="font-semibold text-lg h-5">Logins</span>
          <VipAccessFooterButton />
          <div className="text-xl font-light footer">
            <FacebookLoginButton label="Artists" />
          </div>
          <Link to={{ pathname: '/dealers' }} className="text-lg h-5 my-1 hover:text-black hidden">
            Dealers
          </Link>
        </div>
        <div className="flex flex-col w-52 text-gray-500">
          <Link to={{ pathname: '/faq' }} className="text-lg h-5 hover:text-black">
            FAQ
          </Link>
          <Link to={{ pathname: '/TermOfService' }} className="text-lg h-5 hover:text-black">
            Terms
          </Link>
          <Link to={{ pathname: '/privacyPolicy' }} className="text-lg h-5 hover:text-black">
            Privacy Policy
          </Link>
          <Link to={{ pathname: '/technicalSupport' }} className="text-lg h-5 hover:text-black">
            Technical Support
          </Link>
          <Link to={{ pathname: '/contact' }} className="text-lg h-5 hover:text-black">
            Contact Us
          </Link>
        </div>
      </div>
      <div className="text-center mx-56 my-6 text-xs text-transparent">
        any boilerplate terms and conditions or disclaimers would do here any boilerplate terms and conditions or
        disclaimers would do here
      </div>
      <div className="flex justify-center relative">
        <div className="text-lg font-medium text-gray-500 text-center ">2021 PEN Performances Inc. ©</div>
        <span className="text-xs font-medium" style={styles}>
          TM
        </span>
      </div>
      <div className="flex flex-col absolute top-6 right-16 h-44 justify-evenly">
        <a href={footerSocialUrls.twitter} target="_blank" rel="noreferrer">
          <Twitter fillColor="#fff" size={FOOTER_ICON_SIZE} />
        </a>
        <a href={footerSocialUrls.facebook} target="_blank" rel="noreferrer">
          <Facebook fillColor="#fff" size={FOOTER_ICON_SIZE} />
        </a>
        <a href={footerSocialUrls.instagram} target="_blank" rel="noreferrer">
          <Instagram fillColor="#fff" size={FOOTER_ICON_SIZE} />
        </a>
      </div>
    </div>
  );
};

export { Footer };
