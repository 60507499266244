import ReactModal from 'react-modal';
import { useState } from 'react';
import { ReactComponent as HowPenWorksWhiteSVG } from './how-pen-works-white.svg';
import { ReactComponent as HowPenWorksColoredSVG } from './how-pen-works-color.svg';

import { ReactComponent as ModalIcon1 } from './modal-icon-1.svg';
import { ReactComponent as ModalIcon2 } from './modal-icon-2.svg';
import { ReactComponent as ModalIcon3 } from './modal-icon-3.svg';
import { ReactComponent as ModalIcon4 } from './modal-icon-4.svg';
import { Close } from '../../Icons';

// TODO: change color on hover or click

const DEFAULT_WIDTH = 500;
const DEFAULT_COLOR = '#d4596a';

interface SVGButtonProps {
  type: 'white' | 'color';
  width: number | string;
  overlayClassColor?: string;
}

const ICONS = [
  {
    id: 'modal-icon-1',
    Icon: ModalIcon1,
    label: 'Search for a favorite artist by name, style, availability or cost',
  },
  {
    id: 'modal-icon-2',
    Icon: ModalIcon2,
    label: 'Book your event, add special requests and receive your private link',
  },
  {
    id: 'modal-icon-3',
    Icon: ModalIcon3,
    label: 'Setup your AV for the best two-way interactive experience ever',
  },
  {
    id: 'modal-icon-4',
    Icon: ModalIcon4,
    label: 'Connect at event time and enjoy your private PEN Performance!',
  },
];

export default function HowPenWorks({
  type = 'white',
  width = DEFAULT_WIDTH,
  overlayClassColor = 'bg-overlay-default',
}: SVGButtonProps) {
  const HowPenWorksIcon = type === 'white' ? HowPenWorksWhiteSVG : HowPenWorksColoredSVG;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleClose}
        overlayClassName="fixed inset-0 flex items-center justify-center z-30"
        className={`${overlayClassColor} bg-opacity-90 max-w-lg text-white outline-none`}
      >
        <div className="relative">
          <div className="absolute top-5 right-5 opacity-50">
            <button type="button" onClick={handleClose}>
              <Close size={40} />
            </button>
          </div>
        </div>
        <div className="py-6 px-4">
          <HowPenWorksWhiteSVG width="112px" className="mx-auto fill-current text-white" />
          <div className="text-xl px-10">
            <div>
              {ICONS.map(section => {
                const { Icon, label, id } = section;
                return (
                  <div key={id} className="flex flex-row my-10 items-center">
                    <Icon color="white" width="80px" className="mr-10" />
                    <div>{label}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ReactModal>
      <button type="button">
        <HowPenWorksIcon
          width={width}
          onClick={handleOpen}
          color={type === 'white' ? 'white' : DEFAULT_COLOR}
          className="fill-current hover:text-yellow-300"
        />
      </button>
    </>
  );
}
