import { Slider, Handles, Tracks, Rail, Ticks } from 'react-compound-slider';

interface ISlider {
  onChange(values: readonly number[]): void;
}

interface ITrack {
  source: { percent: number };
  target: { percent: number };
  getTrackProps(): void;
}

const DoubleSlider = ({ onChange }: ISlider) => {
  const sliderStyle = {
    position: 'relative',
    height: '40px',
  };

  const railStyle: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '2px',
    marginTop: '22px',
    borderRadius: '5px',
    backgroundColor: 'white',
  };

  const ChangeHandler = (values: readonly number[]) => {
    onChange(values);
  };

  function Track({ source, target, getTrackProps }: ITrack) {
    return (
      <div
        style={{
          position: 'absolute',
          height: 4,
          zIndex: 1,
          marginTop: 21,
          backgroundColor: 'white',
          borderRadius: 5,
          cursor: 'pointer',
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getTrackProps()}
      />
    );
  }

  interface ITick {
    tick: {
      id: string;
      percent: number;
      value: number;
    };
    count: number;
  }

  function Tick({ tick, count }: ITick) {
    return (
      <div>
        <div
          style={{
            position: 'absolute',
            marginTop: 23,
            marginLeft: -0.5,
            width: 1,
            height: 8,
            backgroundColor: 'silver',
            left: `${tick.percent}%`,
          }}
        />
        <div
          style={{
            color: 'white',
            position: 'absolute',
            marginTop: 28,
            fontSize: 10,
            textAlign: 'center',
            marginLeft: `${-(100 / count) / 2}%`,
            width: `${100 / count}%`,
            left: `${tick.percent}%`,
          }}
        >
          {tick.value}
        </div>
      </div>
    );
  }

  interface IHandle {
    handle: { id: string; value: number; percent: number };
    getHandleProps(id: string): void;
  }
  function Handle({ handle: { id, value, percent }, getHandleProps }: IHandle) {
    return (
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          marginLeft: -8,
          marginTop: 15,
          zIndex: 2,
          width: 16,
          height: 16,
          border: 0,
          textAlign: 'center',
          cursor: 'pointer',
          borderRadius: '50%',
          backgroundColor: 'white',
          color: 'transparent',
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getHandleProps(id)}
      >
        <div style={{ fontSize: 16, marginTop: 20 }}>${value}</div>
      </div>
    );
  }

  return (
    <Slider rootStyle={sliderStyle} domain={[0, 10000]} values={[0, 10000]} step={100} onChange={ChangeHandler}>
      <div className="text-white text-2xl mb-2 mt-4">How much would you like to spend?</div>
      <div style={railStyle} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Rail>{({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}</Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map((handle, index) => (
              <div key={handle.id}>
                <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
                {index === 0 ? (
                  <div className="absolute -left-4 top-24 text-white">$ {handle.value === 0 ? 1 : handle.value}</div>
                ) : (
                  <div className="absolute -right-4 top-24 text-white">$ {handle.value === 0 ? 1 : handle.value}</div>
                )}
              </div>
            ))}
          </div>
        )}
      </Handles>
      <Tracks right={false} left={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
            ))}
          </div>
        )}
      </Tracks>
      <Ticks count={9}>
        {({ ticks }) => (
          <div className="slider-ticks">
            {ticks.slice(0, -1).map(tick => (
              // eslint-disable-next-line react/prop-types
              <Tick key={tick.id} tick={tick} count={ticks.length} />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
};

export { DoubleSlider };
