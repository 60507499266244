import { useState } from 'react';
import ModalPicker from '../../../components/ModalPicker';
import { TSelectedHours, HoursOfDay, PickerProps } from '../../../types';

export default function TimePicker({ isOpen, initialState, handleClose }: PickerProps<TSelectedHours>) {
  const [selectedHours, setSelectedHours] = useState(initialState);
  const handleSelectedHour = (hour: keyof TSelectedHours) => {
    setSelectedHours(prev => ({ ...prev, [hour]: !prev?.[hour] }));
  };
  const handleCloseWithCallbackData = () => {
    handleClose(selectedHours);
  };
  return (
    <ModalPicker isOpen={isOpen} handleClose={handleCloseWithCallbackData}>
      <div className="grid grid-rows-6 grid-flow-col gap-4">
        {Object.values(HoursOfDay).map(hour => (
          <button
            key={hour}
            type="button"
            className={`w-20 outline-none text-center p-2 border ${
              selectedHours?.[hour] ? 'border-white' : 'border-transparent'
            }`}
            onClick={() => handleSelectedHour(hour)}
          >
            {hour}
          </button>
        ))}
      </div>
    </ModalPicker>
  );
}
