/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { IArtist, UserRoles } from '../../types';
import type { RootState } from '..';
import { currentConfig } from '../../config';

// TODO: make a generic function to make fetch calls
export interface IUserResponse {
  attributes: {
    email: string;
    email_verified: boolean;
    identities: string;
    sub: string;
    'custom:role': string;
  };
  username: string;
  userDataKey: string;
}

interface AuthResponseI {
  isLogged: boolean;
  email: string;
  username: string;
  role: UserRoles;
  isSignInProgress: boolean;
  artist: IArtist;
}

export const userArtistFacebookSignIn = createAsyncThunk(
  'user/artistFacebookSignIn',
  async (_, { rejectWithValue }) => {
    try {
      // This will make an url redirection
      // we are capturing the intent of login as an artist and update the role according to this intention
      Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
      return true;
    } catch (error) {
      console.log('Error on userArtistFacebookSignIn', error);
      return rejectWithValue(error);
    }
  },
);

export const userGetCurrentAuthenticatedUser = createAsyncThunk(
  'user/getCurrentAuthenticatedUser',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { user } = getState() as RootState;
      let cognitoUser: IUserResponse = await Auth.currentAuthenticatedUser();
      if (user.role !== cognitoUser.attributes['custom:role']) {
        await Auth.updateUserAttributes(cognitoUser, {
          'custom:role': user.role,
        });
        cognitoUser = await Auth.currentAuthenticatedUser();
      }
      return {
        username: cognitoUser?.username,
        attributes: cognitoUser?.attributes,
      };
    } catch (error) {
      console.log('Error on userGetCurrentAuthenticatedUser', error);
      return rejectWithValue(error);
    }
  },
);

export const setAuthenticatedUser = createAsyncThunk('user/setAuthenticatedUser', async (response: AuthResponseI) => {
  try {
    return response;
  } catch (error) {
    console.log('Error on set Authenticated User', error);
    return error;
  }
});

export const setVipCode = createAsyncThunk('user/setVipCode', async (vipCode: string) => {
  try {
    const response = await fetch(`${currentConfig.API_URL}/vip/checkCode?vipCode=${vipCode}`, {
      method: 'GET',
    });
    const { status } = response;
    return { vipCode, vipCodeStatus: status, vipCodeLoading: false };
  } catch (error) {
    console.log('Error on set Vip Code', error);
    return error;
  }
});

export const userSignout = createAsyncThunk('user/signout', async (_, { rejectWithValue }) => {
  try {
    Auth.signOut();
    return true;
  } catch (error) {
    console.log('Error on user/signout', error);
    rejectWithValue(error);
    return null;
  }
});
