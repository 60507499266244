import { useFormContext } from 'react-hook-form';
import { FormCheckboxGroup } from '../../components/Form/FormCheckboxGroup';
import { FormInput } from '../../components/Form/FormInput';
import FormSelect from '../../components/Form/FormSelect';
import { FormTextArea } from '../../components/Form/FormTextArea';
import { categories } from '../../config';
import { useChangePageColorByCategory } from '../../hoc/useChangePageColorByCategory';

export default function ProfileArtistShowInfo() {
  const { unregister, watch } = useFormContext();
  const categoryId = watch('categoryId');
  const currentSubcategories = categories.find(category => category.id === categoryId)?.subcategories;

  useChangePageColorByCategory(categoryId);

  return (
    <div className="flex flex-col text-white p-10">
      <h2 className="text-3xl text-center mb-4">Artist & Show Info</h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="mt-5">
          <FormSelect
            label="Pick your category"
            id="categoryId"
            name="categoryId"
            options={categories.map(category => ({ id: category.id, label: category.displayName }))}
            required
            onChange={() => {
              unregister('subcategories');
            }}
          />
        </div>
        <div className="mt-5" />
        <div className="col-span-2 mt-5">
          <FormCheckboxGroup
            label="Pick your subcategories (choose all that applies)"
            id="subcategories"
            name="subcategories"
            options={currentSubcategories?.map(subCategory => ({ id: subCategory, label: subCategory })) || []}
          />
        </div>
        <div className="mt-5">
          <FormInput id="headline" name="headline" label="Headline" maxLength={50} required />
        </div>
        <div className="mt-5" />

        <div className="mt-5">
          <FormTextArea id="artistBio" label="Artist Bio" name="artistBio" maxLength={200} required />
        </div>
        <div className="mt-5">
          <FormTextArea id="productDescription" label="Show Info" name="productDescription" maxLength={200} required />
        </div>
        <div className="mt-5">
          <FormInput
            type="number"
            id="performancePrice"
            label="Performance Price"
            min="1"
            max="10000"
            name="performancePrice"
            required
          />
          <div className="mt-2 text-sm">
            Performance price in US$ for a 60 minutes show. The min price is 1 and max price is 10k.
          </div>
        </div>
        <div className="mt-5" />

        <div className="mt-5">
          <FormInput
            id="profileImageSrc"
            name="profileImageSrc"
            label="Headshot Photo"
            className="px-3 py-2 border rounded text-white w-full"
            type="file"
            accept=".png,.jpg,.jpeg"
          />
          <div className="mt-2 text-sm">File must be jpg or png. Preferred size 510px × 750px.</div>
        </div>
        <div className="mt-5">
          <FormInput
            id="profileVideoUrl"
            label="Video Profile"
            name="profileVideoUrl"
            className="px-3 py-2 border rounded text-white w-full"
            type="file"
            accept=".mp4"
            required={false}
          />
          <div className="mt-2 text-sm">File must be mp4 (H.264 / AAC) Uses standard 16:9 aspect ratio.</div>
        </div>
      </div>
    </div>
  );
}
