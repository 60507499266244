import { ReactComponent as FindPerfectPerformanceSVG } from './find-perfect-performance-button.svg';

const DEFAULT_COLOR = '#D4596A';
const DEFAULT_WIDTH = 500;

interface FindPerfectPerformanceButtonProps {
  color?: string;
  width?: number;
}

export function FindPerfectPerformance({
  color = DEFAULT_COLOR,
  width = DEFAULT_WIDTH,
}: FindPerfectPerformanceButtonProps) {
  return <FindPerfectPerformanceSVG color={color} width={width} className="hover:text-button-hover" />;
}
