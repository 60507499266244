import { ICategory } from '../../types';
import { RosterHeaderCard } from './RosterHeaderCard';

export function RosterHeader({ categories }: { categories: ICategory[] }) {
  return (
    <div className="flex justify-center overflow-hidden py-5 ">
      <div className="absolute self-center select-none text-5xl font-medium z-10 text-white">PERFORMERS</div>
      <div className="flex flex-1">
        {categories.map(category => (
          <RosterHeaderCard key={category.id} category={category} />
        ))}
      </div>
    </div>
  );
}
