/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';

import { OTSession, OTPublisher, OTStreams, OTSubscriber, createSession } from 'opentok-react';
import axios from 'axios';
import { Session, Stream } from 'opentok-react/types/opentok';
import { get } from 'lodash';
import { IPenResponse } from '../types';
import { PenLoader } from './PenLoader';
import { currentConfig } from '../config';

interface VonageErrorI {
  code: number;
  message: string;
  name: string;
  stack: string;
}

interface VonageContainerI {
  roomToken: string;
  publishVideo: boolean;
  publishAudio: boolean;
  singleView: boolean;
}

const SessionInfo = ({ connection, error }: { connection: string; error: string }) => {
  return (
    <div>
      <div id="sessionStatus">Session Status: {connection}</div>
      {error ? (
        <div className="error">
          <strong>Error:</strong> {error}
        </div>
      ) : null}
    </div>
  );
};

export const VonageContainer = ({ roomToken, publishVideo, publishAudio, singleView }: VonageContainerI) => {
  const loadingMessage = 'Connection to your PEN Performance...';
  const errorMessage = 'The room does not exist or you have an invalid room token.';
  const homeButtonText = 'Take me back home';
  const getString = (value: string | undefined) => value || '';
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height: height * 0.8,
    };
  };

  const [connection, setConnection] = useState('Connecting');
  const [error, setError] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [vonageParams, setVonageParams] = useState({
    sessionId: '',
    apiKey: '',
    token: '',
    isLoading: true,
    success: false,
    message: '',
  });
  const [streams, setStreams] = useState<Stream[]>();
  const [session, setSession] = useState<Session | undefined>();
  const [artistStream, setArtistStream] = useState<Stream>();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sessionEventHandlers = {
    sessionConnected: () => {
      setConnection('Connected');
    },
    sessionDisconnected: () => {
      setConnection('Disconnected');
    },
    sessionReconnected: () => {
      setConnection('Reconnected');
    },
    sessionReconnecting: () => {
      setConnection('Reconnecting');
    },
  };

  const publisherEventHandlers = {
    accessDenied: () => {
      console.log('User denied access to media source');
    },
    streamCreated: () => {
      console.log('Publisher stream created');
    },
    streamDestroyed: ({ reason }: { reason: unknown }) => {
      console.log(`Publisher stream destroyed because: ${reason}`);
    },
  };

  const subscriberEventHandlers = {
    videoEnabled: () => {
      console.log('Subscriber video enabled');
    },
    videoDisabled: () => {
      console.log('Subscriber video disabled');
    },
  };

  const onSessionError = (errorValue: VonageErrorI) => {
    setError(errorValue.message);
  };

  const onPublish = () => {
    console.log('Publish Success');
  };

  const onPublishError = (errorValue: VonageErrorI) => {
    setError(errorValue.message);
  };

  const onSubscribe = () => {
    if (!artistStream) {
      const select = streams?.find(entry => entry.name === 'performer');
      if (select) {
        setArtistStream(select);
      }
    }
  };

  const onSubscribeError = (errorValue: VonageErrorI) => {
    setError(errorValue.message);
  };

  useEffect(() => {
    const failedToConnectState = (message: string) => {
      setVonageParams({
        sessionId: '',
        apiKey: '',
        token: '',
        isLoading: false,
        success: false,
        message: getString(message),
      });
    };

    const consumeAPIResponse = (result: IPenResponse) => {
      if (result.success) {
        const { token, sessionId, apiKey } = result.data;
        setVonageParams({
          sessionId,
          apiKey,
          token,
          isLoading: false,
          success: true,
          message: getString(result.message),
        });
        const vonageSession = createSession({
          apiKey,
          sessionId,
          token,
          onStreamsUpdated: connectedStreams => {
            setStreams(connectedStreams);
          },
        });
        setSession(vonageSession.session);
        return result.message;
      }
      failedToConnectState(result.message || '');
      return result.message;
    };

    const fetchToken = async (roomId: string) => {
      try {
        const result: IPenResponse = await axios.get(`${currentConfig.API_URL}/vonage/token?roomToken=${roomId}`);
        consumeAPIResponse(result.data);
      } catch (err) {
        failedToConnectState(errorMessage);
      }
    };

    setTimeout(() => {
      fetchToken(roomToken);
    }, 100);
  }, [roomToken]);

  const drawSingleView = () => {
    return (
      <>
        <div className="hidden">
          <OTPublisher
            onPublish={onPublish}
            onError={event => onPublishError(event as unknown as VonageErrorI)}
            eventHandlers={publisherEventHandlers}
          />
          <OTStreams>
            <OTSubscriber
              properties={{
                showControls: false,
              }}
              onSubscribe={onSubscribe}
              onError={event => onSubscribeError(event as unknown as VonageErrorI)}
              eventHandlers={subscriberEventHandlers}
            />
          </OTStreams>
        </div>
        <div className="performer-stream">
          <OTSubscriber
            key={artistStream?.name}
            session={session}
            stream={artistStream}
            properties={{
              showControls: false,
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </>
    );
  };

  const drawMultiView = () => {
    const properties = {
      publishVideo,
      publishAudio,
      showControls: false,
      audioBitrate: 256000,
      resolution: '1280x720',
      height: '500px',
      width: '100%',
      name: 'performer',
    };
    return (
      <div className="full">
        <div className="left">
          <div className="bg-gray-500 border-2 border-gray-500 rounded">
            <div className="font-black text-center text-white"> Me </div>
            <OTPublisher
              properties={properties}
              onPublish={onPublish}
              onError={event => onPublishError(event as unknown as VonageErrorI)}
              eventHandlers={publisherEventHandlers}
            />
          </div>
        </div>
        <div className="right">
          <div className="bg-gray-500 border-2 border-gray-500 rounded">
            <div className="font-black text-center text-white"> Audience </div>
            <OTStreams>
              <OTSubscriber
                properties={{
                  showControls: false,
                  width: '100%',
                  height: '400px',
                  insertMode: 'append',
                }}
                onSubscribe={onSubscribe}
                onError={event => onSubscribeError(event as unknown as VonageErrorI)}
                eventHandlers={subscriberEventHandlers}
              />
            </OTStreams>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {vonageParams.isLoading ? (
        <PenLoader />
      ) : (
        <>
          {vonageParams.success ? (
            <div>
              <SessionInfo connection={connection} error={error} />
              <OTSession
                apiKey={vonageParams.apiKey}
                sessionId={vonageParams.sessionId}
                token={vonageParams.token}
                onError={event => onSessionError(event as unknown as VonageErrorI)}
                eventHandlers={sessionEventHandlers}
              >
                {singleView ? drawSingleView() : drawMultiView()}
              </OTSession>
            </div>
          ) : (
            <div>
              {' '}
              <SessionInfo connection={connection} error={error} />{' '}
            </div>
          )}
        </>
      )}
    </div>
  );
};
