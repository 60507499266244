/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { currentConfig } from '../config';
import { useAppDispatch, useAppSelector } from '../hooks';
import { storePaypalReceive } from '../store/paypal/paypalActions';
import { messageSelector } from '../store/paypal/paypalSlice';
import { IEmailOption, IProduct } from '../types';
import { userVipSelector } from '../store/user/userSlice';

interface IPaypal {
  paidFor: boolean;
  emailOptions?: IEmailOption;
  artistId?: string;
  product?: IProduct;
  setPaidFor: (flag: boolean) => void;
}

export const PayPalButton = ({ artistId, paidFor, product, emailOptions, setPaidFor }: IPaypal) => {
  const paypalKey = currentConfig.PAYPAL_SDK_URL;

  const paypalMessage: string | undefined = useAppSelector(messageSelector);
  const dispatch = useAppDispatch();
  const vipCode = useAppSelector(userVipSelector);
  const [loaded, setLoaded] = useState(false);
  const truncate = (value: string, length: number) =>
    value.length > length ? `${value.substring(0, length)}...` : value;

  let paypalRef = useRef<HTMLDivElement>() as unknown as HTMLDivElement | null;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = paypalKey;
    script.addEventListener('load', () => setLoaded(true));
    document.body.appendChild(script);
    if (loaded) {
      setTimeout(() => {
        window.paypal
          .Buttons({
            fundingSource: window.paypal.FUNDING.PAYPAL,
            createOrder: (data: any, actions: any) =>
              actions.order.create({
                purchase_units: [
                  {
                    description: truncate(product?.description || 'not available', 100),
                    amount: {
                      currency_code: 'USD',
                      value: product?.price || 1,
                    },
                  },
                ],
              }),
            onApprove: async (data: any, actions: any) => {
              const order = await actions.order.capture();
              order.emailOptions = emailOptions;
              order.artist = artistId;
              order.vipCode = vipCode;
              dispatch(storePaypalReceive({ receive: order }));
              setPaidFor(true);
            },
          })
          .render(paypalRef);
      });
    }
  });

  return (
    <div>
      {paidFor ? (
        <div>
          <h1>
            Congratulations, you bought a <strong>PEN</strong> Performance!
          </h1>
          <h3> {paypalMessage} </h3>
        </div>
      ) : (
        <div className="mt-1 grid">
          <h1>Show description: {product?.description}</h1>
          <h3>Show duration: {product?.duration}</h3>
          <h3>Show price: ${product?.price}</h3>
          <h3>Show time: {emailOptions?.clientTime}</h3>
          <div className="mt-8 w-full max-h-96 overflow-y-auto" ref={v => (paypalRef = v)} />
        </div>
      )}
    </div>
  );
};

declare global {
  interface Window {
    paypal: any;
  }
}
