import { FormInput } from '../../components/Form/FormInput';

export default function ProfileManagerInfo({ disabled = false }: { disabled: boolean }) {
  return (
    <div className="flex flex-col text-white p-10">
      <h2 className="text-3xl text-center mb-4">Manager Info</h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="mt-5">
          <FormInput
            label="Admin Facebook Email"
            name="adminFacebookEmail"
            id="adminFacebookEmail"
            maxLength={244}
            disabled={disabled}
            type="email"
          />
        </div>
      </div>
    </div>
  );
}
