import { ReactComponent as ReturnRosterSVG } from './return-roster.svg';

const DEFAULT_COLOR = '#d4596a';
const DEFAULT_WIDTH = 240;

interface FindPerfectPerformanceButtonProps {
  color?: string;
  width?: number;
  className?: string;
}

// TODO: change color on hover or click

export default function ReturnToRooter({
  color = DEFAULT_COLOR,
  width = DEFAULT_WIDTH,
  className,
}: FindPerfectPerformanceButtonProps) {
  return <ReturnRosterSVG color={color} width={width} className={className} />;
}
